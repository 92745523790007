<template>
    <div>
        <!-- Modal add activite -->
        <modal name="modal-add-activite" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{$tt("Demander une nouvelle activité")}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-add-activite')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="bg-slate-100 p-4 rounded mb-4 text-sm">
                        <i class="fas fa-info-circle mr-0.5"></i> {{ $tt('Merci de nous envoyer une demande de nouvelle activité via la formulaire ci-dessous') }}
                    </div>
                    <input-rule v-model="newActivite.nom" type="text" :label="$tt('Nom de l\'activité')" :label-inline="false" class="mb-2" id="inp-nom" />
                    <input-rule v-model="newActivite.description" type="textarea" :options="{rows: 5}" :label="$tt('Description de l\'activité')" :tooltip="$tt('Quels types d\'appareils réparez-vous dans cette activité ?')" :label-inline="false" id="inp-description" />
                    <button @click="askActivite" :disabled="loadingAskActivite" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-sm font-bold text-white rounded mt-4">
                        <spinner v-if="loadingAskActivite" :size="10" />
                        <template v-if="!loadingAskActivite">
                            <i class="fas fa-check mr-1"></i> {{ $tt('Envoyer la demande') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal edit address -->
        <modal name="modal-edit-address" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{$tt("Modifier l'adresse postale")}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-edit-address')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="newAddress" type="address2" :label="$tt('Nouvelle adresse')" :label-inline="false" />
                    <button @click="saveNewAddress" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-sm font-bold text-white rounded mt-4"><i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}</button>
                </div>
            </div>
        </modal>

        <div class="lg:grid grid-cols-3 gap-5">
            <div :class="station && !staIsInit ? 'col-span-3' : ''">
                <div :class="station && !staIsInit ? 'flex items-center justify-between gap-4' : ''">
                    <h2 v-if="station" class="font-bold text-lg mb-2">{{ $tt('Votre station') }}</h2>
                    <div v-if="station && !staIsInit" class="bg-orange-400 p-4 text-xs rounded mb-4 text-white font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Configurer la station pour continuer') }}</div>
                </div>
                <div class="p-4" v-if="loadingGetSta">
                    <spinner :size="10" />
                </div>
                <div v-if="station">
                    <div class="bg-slate-50 border rounded p-4">
                        <div :class="station && !staIsInit ? 'grid grid-cols-3 gap-10' : ''">
                            <div>
                                <input-rule v-model="station.nom" type="text" :label="$tt('Nom')" :label-inline="false" class="mb-4" custom="bg-white" id="inp-nom" />
                                <input-rule v-model="station.tel" type="tel" :label="$tt('Téléphone')" :label-inline="false" class="mb-4" custom="bg-white" id="inp-tel" />
                                <input-rule v-model="station.mail" type="text" :label="$tt('Adresse email')" :label-inline="false" class="mb-4" custom="bg-white" id="inp-mail" />
                            </div>
                            <div>
                                <div class="mb-4">
                                    <label class="mb-1 text-xs font-bold text-gray-900">{{ $tt('Adresse postale') }}</label>
                                    <div @click="newAddress = null; $modal.show('modal-edit-address')" class="px-2 py-1 bg-white hover:bg-slate-50 duration-200 cursor-pointer border rounded-lg">{{ adresse }}</div>
                                </div>

                                <input-rule type="text" :label="$tt('Nom de votre logiciel SAV')" :label-inline="false" class="mb-4" custom="bg-white" />
                                <input-rule type="select" v-model="station.type_societe" :label="$tt('Type de société')" :label-inline="false" class="mb-4" :options="{values: typeSocietes}" id="inp-type_societe" custom="bg-white" />
                                <input-rule type="text" v-model="station.tva_sta" :label="$tt('Numéro TVA intracommunautaire')" :label-inline="false" class="mb-4" :options="{values: typeSocietes}" id="inp-tva_sta" custom="bg-white" :disabled="staIsInit" />
                                <div class="flex items-center gap-6 mt-5 mb-4">
                                    <input-rule v-model="station.qualirepar" type="pcheck" :label="$tt('Labellisé QualiRépar')" :label-inline="false" custom="bg-white" />
                                    <img class="h-[40px] mr-1" src="https://www.label-qualirepar.fr/wp-content/uploads/2022/06/logo-label-quali-repar-300x169-1.png" alt="">
                                </div>
                                <input-rule v-model="station.iad" type="pcheck" :label="$tt('Gestion des interventions à domicile')" :label-inline="false" custom="bg-white" class="mb-4" />
                            </div>
                            <div>
                                <div class="mb-4">
                                    <label class="mb-1 text-xs font-bold text-gray-900">{{ $tt("Type d'activité") }}</label>
                                    <div class="grid grid-cols-2 gap-10 mt-2 items-center">
                                        <div>
                                            <button @click="$modal.show('modal-add-activite')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 text-slate-600 rounded text-xs font-bold"><i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter une activité') }}</button>
                                        </div>
                                        <div v-for="(activite, activiteIndex) in station.type_activites" :key="'activite-inp-' + activiteIndex" class="flex items-center gap-2">
                                            <input-rule v-model="activite.actif" type="pcheck" :label-inline="false" />
                                            <div :class="activite.actif ? '' : 'opacity-70'">
                                                <div class="text-sm">{{ $tt(activite.libelle) }}</div>
                                                <div class="text-xs font-light">{{ $tt(activite.description) }}</div>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :class="station && !staIsInit ? 'border-t-2 border-slate-300 pt-4 mt-4 text-right' : ''">
                            <button @click="editStation" :disabled="loadingEditStation" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white text-sm rounded">
                                <spinner :size="10" v-if="loadingEditStation" />
                                <template v-if="!loadingEditStation">
                                    <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="staIsInit" class="mt-10 lg:mt-0">
                <h2 class="font-bold text-lg mb-2">{{ $tt('Dates de fermeture') }}</h2>
                
                <div>
                    <ol class="relative border-s border-gray-200 mt-4 py-2">                  
                        <!-- <li class="mb-7 ms-4">
                            <div class="absolute w-3 h-3 bg-green-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                            <div>
                                <span class="bg-green-100 border-2 border-green-400 text-green-800 rounded p-3 text-xs"><i class="fas fa-check mr-1"></i> {{ $tt("Actuellement en cours d'activité") }}</span>
                            </div>
                        </li> -->
                        <li class="mb-8 ms-4 items-center">
                            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                            <time class="mb-1 text-sm font-normal leading-none text-gray-400"></time>
                            <div>
                                <span class="bg-slate-200 rounded p-1 text-xs font-light">{{ $tt('Ajouter une fermeture') }}</span>
                            </div>
                            <div class="border bg-slate-100 p-4 rounded mt-2">
                                <div class="grid grid-cols-2 gap-4">
                                    <input-rule v-model="dateFermeture.start" type="datetime" :label="$tt('Date de fermeture')" :label-inline="false" />
                                    <input-rule v-model="dateFermeture.end" type="datetime" :label="$tt('Date de reprise')" :label-inline="false" />
                                </div>
                                <button @click="addDateFermeture" :disabled="loadingAddDateFermeture" class="px-4 py-2 bg-slate-300 hover:bg-green-300 duration-200 rounded text-xs mt-2">
                                    <spinner v-if="loadingAddDateFermeture" :size="10" />
                                    <template v-if="!loadingAddDateFermeture">
                                        <i class="fas fa-plus mr-1"></i> {{ $tt('Enregistrer') }}
                                    </template>
                                </button>
                            </div>
                        </li>
                        <li v-if="loadingGetDateFermeture" class="ms-4 mb-8">
                            <div class="absolute w-3 h-3 bg-slate-400 rounded-full mt-1.5 -start-1.5 border border-white"></div>
                            <div class="mt-2 p-4">
                                <spinner :size="10" />
                            </div>
                        </li>
                        <li v-for="(dateF, dateFIndex) in dateFermetures" :key="'date-fermeture-' + dateFIndex" class="ms-4" :class="dateFIndex < dateFermetures.length - 1 ? 'mb-4 pb-4 border-b' : ''">
                            <div class="absolute w-3 h-3 bg-red-400 rounded-full mt-4 -start-1.5 border border-white"></div>
                            <div class="flex items-center gap-2 justify-between">
                                <div>
                                    <span class="bg-red-400 text-white rounded p-1 text-xs font-light">{{ $tt('Fermé') }}</span>
                                </div>
                                <div class="mt-2 flex items-center gap-4">
                                    <span class="text-xs text-gray-900">{{$tt('Du')}} <b>{{ dateF.date_debut | momentDateTime }}</b><br />{{$tt('Au')}} <b>{{ dateF.date_fin | momentDateTime }}</b></span>
                                    <button @click="deleteDateFermeture(dateF)" v-if="!isPast(dateF.date_debut)" class="text-slate-500 hover:text-red-500 hover:opacity-100 duration-200 opacity-60">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>

                <div class="mt-10">
                    <h2 class="font-bold text-lg mb-2">{{ $tt('Gestion technique') }}</h2>

                    <div class="bg-slate-50 border rounded p-4">
                        <input-rule v-model="formGestion.duree_garantie" type="number" :label="$tt('Durée de la garantie SAV (en mois)')" :label-inline="false" class="mb-2" />
                        <input-rule v-model="formGestion.coeff_min" type="number" :label="$tt('Coefficient de re-facturation minimum des pièces')" :label-inline="false" class="mb-2" />
                    </div>
                </div>
            </div>
            <div v-if="staIsInit">
                <h2 class="font-bold text-lg mb-2">{{ $tt('Configuration devis') }}</h2>
                <div class="bg-slate-50 border rounded p-4">
                    <div class="mb-2">
                        <input-rule v-model="formDevis.logo" type="file" :label-inline="false" :label="$tt('Logo de la société')" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.montant_penalite" type="price" :label-inline="false" :label="$tt('Montant des pénalités')" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.duree_validite_devis" type="number" :label="$tt('Durée de la validité du devis (en mois)')" :label-inline="false" class="mb-2" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.capital_social" type="price" :label-inline="false" :label="$tt('Capital social')" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.site_web" type="text" :label-inline="false" :label="$tt('Site web')" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.pied_devis" type="richtext" :label-inline="false" :label="$tt('Devis - Pied de page')" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.pied_facture" type="richtext" :label-inline="false" :label="$tt('Facture - Pied de page')" />
                    </div>
                    <div class="mb-2">
                        <input-rule v-model="formDevis.pied_bl" type="richtext" :label-inline="false" :label="$tt('Bon de livraison - Pied de page')" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    watch: {
        formDevis: {
            deep: true,
            handler() {
                this.editDevisForm()
            }
        },
        formGestion: {
            deep: true,
            handler() {
                this.editGestionConfig()
            }
        },
    },
    data() {
        return {
            station: null,
            defaultDataStation: null,
            newAddress: null,
            stationLoaded: false,

            dateFermetures: [],
            dateFermeture: {
                start: null,
                end: null
            },

            newActivite: {},

            timerEditDevis: null,
            formDevis: {},

            timerEditGestion: null,
            formGestion: {},

            loadingGetSta: false,
            loadingEditStation: false,
            loadingAddDateFermeture: false,
            loadingGetDateFermeture: false,
            loadingAskActivite: false,
        }
    },

    computed: {
        adresse() {
            return this.station.adresse + ', ' + this.station.cp + ' ' + this.station.ville
        },
        typeSocietes() {
            return [
                "Entrepreneur individuel (EI) (y compris micro-entrepreneur)",
                "Entreprise unipersonnelle à responsabilité limitée (EURL)",
                "Société à responsabilité limitée (SARL)",
                "Société par actions simplifiée unipersonnelle (SASU)",
                "Société par actions simplifiée (SAS)",
                "Société anonyme (SA)",
                "Société en nom collectif (SNC)",
                "Société en commandite simple (SCS)",
                "Société en commandite par actions (SCA)"
            ]
        },
        staIsInit() {
            if (this.defaultDataStation) {
                if (!this.defaultDataStation.nom || !this.defaultDataStation.tel || !this.defaultDataStation.mail || !this.defaultDataStation.type_societe || !this.defaultDataStation.tva_sta) {
                    return false
                }
                return true
            }
            return null
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },

    methods: {
        async getStation() {
            this.loadingGetSta = true
            let response = await this.$request.post('reparateur.get-sta')
            this.station = response.data.posts.post
            this.defaultDataStation = JSON.parse(JSON.stringify(this.station))
            this.$emit('refresh-sta', this.station)
            this.formDevis = {
                logo: this.station.logo,
                montant_penalite: this.station.montant_penalite,
                capital_social: this.station.capital_social,
                site_web: this.station.site_web,
                pied_devis: this.station.pied_devis,
                pied_facture: this.station.pied_facture,
                pied_bl: this.station.pied_bl,
                duree_validite_devis: this.station.duree_validite_devis
            }
            this.formGestion = {
                coeff_min: this.station.coeff_min,
                duree_garantie: this.station.duree_garantie,
            }
            this.loadingGetSta = false
            setTimeout(() => {
                this.stationLoaded = true
            }, 600)
        },

        saveNewAddress() {
            if (!this.newAddress) {
                this.$toast.error(this.$tt('Adresse incorrecte'))
            }

            this.station.adresse = this.newAddress.adresse1
            this.station.cp = this.newAddress.cp
            this.station.ville = this.newAddress.ville
            this.station.lon = this.newAddress.longitude
            this.station.lat = this.newAddress.latitude

            this.$modal.hide('modal-edit-address')
        },

        async editStation() {
            if (!this.stationLoaded) {
                return
            }
            if (!this.$validation.check([
                {value: this.station.nom, rules: 'required', id: 'inp-nom'},
                {value: this.station.tel, rules: 'required', id: 'inp-tel'},
                {value: this.station.mail, rules: 'required', id: 'inp-mail'},
                {value: this.station.type_societe, rules: 'required', id: 'inp-type_societe'},
                {value: this.station.tva_sta, rules: 'required|tvaintra', id: 'inp-tva_sta'}
            ])) {
                this.$toast.error(this.$tt('Formulaire incomplet'))
                return
            }

            if (!this.station.type_activites.find((item) => item.actif)) {
                this.$toast.error(this.$tt('Vous devez séléctionner au moins une activité'))
                return
            }

            this.loadingEditStation = true
            let endData = {...this.station}
            endData.line_id = endData.id
            delete endData.id
            endData.type_activites = JSON.stringify(endData.type_activites)
            let response = await this.$request.post('reparateur.edit-sta', endData)

            if (!this.staIsInit) {
                await this.getStation()
            } else {
                this.$emit('refresh-sta', this.station)
            }
            this.loadingEditStation = false

            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Station modifiée'))
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async addDateFermeture() {
            if (!this.dateFermeture.start || !this.dateFermeture.end) {
                this.$toast.error(this.$tt('Dates incorrectes'))
                return
            }

            if (moment(this.dateFermeture.end).isBefore(this.dateFermeture.start)) {
                this.$toast.error(this.$tt('Les dates ne correspondent pas'))
                return
            }

            this.loadingAddDateFermeture = true
            let response = await this.$request.post('reparateur.add-date-fermeture', this.dateFermeture)
            this.loadingAddDateFermeture = false

            if (response.data.posts.post == 1) {
                this.dateFermeture = {
                    start: null,
                    end: null
                }
                await this.getDateFermetures()
                this.$toast.success(this.$tt('Dates enregistrées'))
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async getDateFermetures() {
            this.loadingGetDateFermeture = true
            let response = await this.$request.post('reparateur.get-date-fermeture')
            this.dateFermetures = response.data.posts.post
            this.loadingGetDateFermeture = false
        },

        isPast(date) {
            return moment(date).isBefore()
        },

        async deleteDateFermeture(dateFemerture) {
            let response = await this.$request.post('reparateur.delete-date-fermeture', {
                line_id: dateFemerture.id
            })
            if (response.data.posts.post == 1) {
                await this.getDateFermetures()
                this.$toast.success(this.$tt('Date supprimée'))
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async askActivite() {
            if (!this.$validation.check([
                {value: this.newActivite.nom, rules: 'required', id: 'inp-nom'},
                {value: this.newActivite.description, rules: 'required', id: 'inp-description'},
            ])) {return}

            this.loadingAskActivite = true
            let response = await this.$request.post('reparateur.ask-activite', {
                nom: this.newActivite.nom,
                description: this.newActivite.description,
            })
            this.loadingAskActivite = false
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Demande d\'activité en cours de validation..'))
                this.$modal.hide('modal-add-activite')
                this.newActivite = {}
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
        },

        async editDevisForm() {
            if (!this.stationLoaded) {
                return
            }

            clearTimeout(this.timerEditDevis)
            this.timerEditDevis = setTimeout(async () => {
                let endData = {}
                if (this.formDevis.logo) {   
                    endData.logo = this.formDevis.logo.url
                }
                if (this.formDevis.montant_penalite) {   
                    endData.montant_penalite = this.formDevis.montant_penalite
                }
                if (this.formDevis.montant_penalite) {   
                    endData.duree_validite_devis = this.formDevis.duree_validite_devis
                }
                if (this.formDevis.capital_social) {   
                    endData.capital_social = this.formDevis.capital_social
                }
                if (this.formDevis.site_web) {   
                    endData.site_web = this.formDevis.site_web
                }
                if (this.formDevis.pied_devis) {   
                    endData.pied_devis = this.formDevis.pied_devis
                }
                if (this.formDevis.pied_facture) {   
                    endData.pied_facture = this.formDevis.pied_facture
                }
                if (this.formDevis.pied_bl) {   
                    endData.pied_bl = this.formDevis.pied_bl
                }
                await this.$request.post('reparateur.edit-devis-config', endData)
            }, 1000)
        },

        async editGestionConfig() {
            if (!this.stationLoaded) {
                return
            }

            clearTimeout(this.timerEditGestion)
            this.timerEditGestion = setTimeout(async () => {
                let endData = {}
                if (this.formGestion.coeff_min) {   
                    endData.coeff_min = this.formGestion.coeff_min
                }
                if (this.formGestion.duree_garantie) {   
                    endData.duree_garantie = this.formGestion.duree_garantie
                }
                if (this.formGestion.duree_validite_devis) {   
                    endData.duree_validite_devis = this.formGestion.duree_validite_devis
                }
                await this.$request.post('reparateur.edit-gestion-config', endData)
            }, 1000)
        }
    },
    mounted () {
        this.getStation()
        this.getDateFermetures()
    },
}
</script>

<style lang="scss" scoped>

</style>