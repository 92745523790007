<template>
    <div>
        <modal name="modal-add-codes" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter des codes')}}</h2>
                    <button @click="$modal.hide('modal-add-codes')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <div class="font-bold mb-1">{{ $tt('Créer un nouveau code') }}</div>
                    <div class="mb-3 p-3 border border-slate-200 rounded grid grid-cols-3 gap-2 items-end">
                        <input-rule type="text" v-model="newCode.code" id="inp-new-code-code" :label="$tt('Code')" :label-inline="false" />
                        <input-rule type="text" v-model="newCode.fr" id="inp-new-code-fr" :label="$tt('Libellé')" :label-inline="false" />
                        <div class="text-right">
                            <button @click="createCode" class="px-3 py-1.5 bg-slate-600 hover:bg-slate-700 duration-200 text-white text-sm rounded">
                                <i class="fas fa-plus mr-1"></i> {{ $tt('Créer un code') }}
                            </button>
                        </div>
                    </div>

                    <div class="font-bold mt-10">{{ $tt('Séléctionner des codes') }}</div>
                    <div class="font-light text-sm mb-2 -mt-0.5">{{ $tt('A attacher à votre catégorie') }}</div>
                    <div class="flex items-center mb-1">
                        <input v-model="searchAddInp" :placeholder="$tt('Rechercher un code')" class="p-1.5 border grow text-sm focus:outline-none mb-1 rounded-l" />
                        <button v-if="searchAddInp" @click="searchAddInp = null" class="bg-red-200 border border-red-200 hover:bg-red-200 text-red-800 duration-200 rounded-r p-1.5 px-3 text-sm -mt-[4px]"><i class="fas fa-times"></i></button>
                    </div>

                    <!-- <div class="mb-2 italic text-sm font-light">{{ $tt('Cliquez sur les codes que vous souhaitez séléctionner') }} :</div> -->
                    <div class="max-h-[30vh] overflow-y-auto">
                        <div
                        @click="toggleCode(code.code)"
                        class="border-b py-1 px-2 duration-200 cursor-pointer"
                        :class="selectedAddCode.includes(code.code) ? 'bg-green-600 text-white' : 'bg-slate-100 hover:bg-slate-300'"
                        v-for="(code, codeIndex) in filteredAddCodes"
                        :key="'not-selected-code-' + codeIndex"
                        >
                            <div class="flex items-center gap-2">
                                <span class="bg-slate-200 rounded text-xs font-bold rounded px-2 py-1 text-slate-900">{{ code.code || '-' }}</span>
                                {{ code.fr || '-'  }}
                            </div>
                        </div>
                    </div>
                    <button :disabled="loadingAddCode" @click="addCodes" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white text-sm font-bold rounded mt-4">
                        <spinner v-if="loadingAddCode" :size="10" />
                        <template v-if="!loadingAddCode">
                            <i class="fas fa-check mr-1"></i> {{ $tt('Ajouter ces codes à la catégorie') }} ({{ selectedAddCode.length }})
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <spinner v-if="loadingGetData" :size="10" />
        <div v-if="!loadingGetData && (!selected || selected.length <= 0)">
            <button @click="$modal.show('modal-add-codes')" class="px-4 py-1.5 -mt-0.5 bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded text-sm"><i class="fas fa-plus mr-1 text-xs"></i> {{ $tt('Ajouter des codes') }}</button>
        </div>
        <div v-if="!loadingGetData && selected && selected.length > 0" class="p-2 rounded bg-slate-100">
            <div class="flex items-center gap-2">
                <button @click="$modal.show('modal-add-codes')" class="px-4 py-1.5 -mt-0.5 bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded text-sm"><i class="fas fa-plus mr-1 text-xs"></i> {{ $tt('Ajouter des codes') }}</button>
                <input v-model="searchInp" :placeholder="$tt('Rechercher un code')" class="p-1.5 border grow text-sm focus:outline-none mb-1 rounded font-bold" />
            </div>
            
            <div class="max-h-[50vh] overflow-y-auto border rounded-b">
                <div v-for="(codePanne, codePanneIndex) in filteredCodes" :key="'codePanne-table-' + codePanneIndex" class="border-b p-1 px-3 bg-white font-bold">
                    <div class="flex items-center justify-between gap-2">
                        <div class="flex items-center gap-2">
                            <span class="bg-slate-100 rounded text-xs font-bold rounded px-2 py-1">{{ codePanne.code || '-' }}</span>
                            {{ codePanne.fr || '-'  }}
                        </div>
                        <button :disabled="loadingDeleteCode" @click="deleteCode(codePanne)" class="text-sm text-red-500 hover:bg-red-500 hover:text-white px-2 rounded">
                            <spinner :size="5" v-if="loadingDeleteCode == codePanne.code" />
                            <template v-if="loadingDeleteCode != codePanne.code">
                                <i class="fas fa-times"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        typeCode: {
            required: true
        },
        categId: {
            required: true
        }
    },
    watch: {
        typeCode: {
            deep: true,
            handler() {
                this.getCodes();
            }
        }
    },
    data() {
        return {
            selected: [],
            notSelected: [],

            searchInp: null,

            selectedAddCode: [],
            searchAddInp: null,

            newCode: {
                code: null,
                fr: null
            },

            loadingGetData: false,
            loadingAddCode: false,
            loadingDeleteCode: false,
        }
    },
    computed: {
        filteredCodes() {
            let result = this.selected
            const key = 'code'; // Clé sur laquelle on cherche
            if (this.searchInp) {
                result = this.selected.filter(code =>
                    code[key].toLowerCase().includes(this.searchInp.toLowerCase())
                );
            }
            return result.sort((a, b) =>
                String(a[key]).localeCompare(String(b[key]))
            );
        },

        filteredAddCodes() {
            let result = this.notSelected
            const key = 'fr'; // Clé sur laquelle on cherche
            if (this.searchAddInp) {
                result = this.notSelected.filter(code =>
                    code[key].toLowerCase().includes(this.searchAddInp.toLowerCase())
                );
            }
            return result.sort((a, b) =>
                String(a[key]).localeCompare(String(b[key]))
            );
        }
    },
    methods: {
        async getCodes() {
            this.selectedAddCode = []
            this.loadingGetData = true
            let response = await this.$request.send('code-panne.get-list', {
                type_code: this.typeCode,
                categ_id: this.categId
            })
            this.loadingGetData = false
            this.selected = response.selected
            this.notSelected = response.not_selected
        },

        toggleCode(code) {
            if (this.selectedAddCode.includes(code)) {
                this.selectedAddCode = this.selectedAddCode.filter(c => c !== code)
            } else {
                this.selectedAddCode.push(code)
            }
        },

        async addCodes() {
            this.loadingAddCode = true
            await this.$request.send('code-panne.add-to-categ', {
                type_code: this.typeCode,
                categ_id: this.categId,
                codes: JSON.stringify(this.selectedAddCode)
            })
            this.loadingAddCode = false
            
            this.getCodes()
            this.$modal.hide('modal-add-codes')
        },

        async deleteCode(codePanne) {
            this.loadingDeleteCode = codePanne.code
            await this.$request.send('code-panne.delete-from-categ', {
                type_code: this.typeCode,
                categ_id: this.categId,
                code: codePanne.code
            })
            this.loadingDeleteCode = false
            
            this.getCodes()
        },

        async createCode() {
            if (!this.$validation.check([
                {value: this.newCode.code, rules: 'required', id: 'inp-new-code-code'},
                {value: this.newCode.fr, rules: 'required', id: 'inp-new-code-fr'},
            ])) {return}

            let response = await this.$request.send('code-panne.create-code', {
                code: this.newCode.code,
                fr: this.newCode.fr,
                type_code: this.typeCode
            })
            this.notSelected.push(response)
            this.$toast.success(this.$tt('Code créé'))

            this.selectedAddCode.push(response.code)

            this.newCode = {
                code: null,
                fr: null
            }
        }
    },
    mounted() {
        this.getCodes();
    }
}
</script>