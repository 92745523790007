<template>
    <div>
        <modal :name="modalName" height="auto" width="60%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2>
                        <div class="flex items-center gap-3">
                            <i class="fas fa-folder mr-1 text-2xl"></i>
                            <div>
                                <div class="font-bold">
                                    <template v-if="!edit">{{ $tt("Initialiser le dossier") }}</template>
                                    <template v-if="edit">{{ $tt("Modifier le dossier") }}</template>
                                </div>
                                <div class="font-light text-sm -mt-0.5 text-slate-500">{{$tt('Dossier')}} : {{ line.ref_dossier }}</div>
                            </div>
                        </div>
                    </h2>
                    <button @click="$modal.hide(modalName)">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="flex justify-center mt-10" v-if="loadingGetData">
                    <div class="text-center">
                        <div class="flex justify-center">
                            <spinner :size="10" />
                        </div>
                        <div class="text-xs font-light mt-4">{{ $tt('Chargement des données') }}</div>
                    </div>
                </div>
                <div v-if="data && !loadingGetData" class="min-h-[500px]">
                    <table class="w-full">
                        <thead class="text-left">
                            <tr>
                                <th class="border border-b-2 bg-slate-200 border-slate-300 text-sm px-2 py-2 w-[180px]">{{ $tt('Champ') }}</th>
                                <th class="border border-b-2 bg-slate-100 border-slate-300 text-sm px-2 py-2 w-[180px]">{{ $tt('Opérateur') }}</th>
                                <th class="border border-b-2 border-slate-300 text-sm px-2 py-2">{{ $tt('Correction') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!edit">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Marque') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.device['Marque'] }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">-</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Modèle') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.device['Modele'] }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <!-- <div class="grid grid-cols-2 gap-4"> -->
                                        <input-rule type="vselect" @input="(data) => form.modele_design = data" v-model="form.modele" :options="{values: modeles}" :label-inline="false" :placeholder="$tt('Nouveau modèle')" id="modele" />
                                        <!-- <input-rule type="text" v-model="form.modele_design" :label-inline="false" :placeholder="$tt('Désignation')" id="modele_design" /> -->
                                    <!-- </div> -->
                                </td>
                            </tr>
                            <tr v-if="!edit">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Garantie') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ garantieLabels[data.garantie] }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5"><input-rule type="vselect" v-model="form.garantie" id="garantie" :options="{values: garanties, label: 'libelle'}" :label-inline="false" class="text-sm" @input="$eventHub.$emit('clear-input-error')" /></td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Etat de l\'appareil') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">
                                    -
                                </td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <input-rule type="select" v-model="etatAppareil" id="etat-appareil" :options="{values: appEtats, label: 'libelle', key: 'key'}" :label-inline="false" class="text-sm" @input="$eventHub.$emit('clear-input-error')" />
                                </td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Catégorie') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.device.categ_distri?.nom }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <input-rule v-model="categStaId" type="select" :options="{values: staCategFiltered, label: 'libelle', key: 'id'}" :label-inline="false" id="categ_sta" />
                                </td>
                            </tr>
                            <tr v-if="!edit">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Date d\'achat') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.date_ha | momentDate }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <template v-if="dateHaVerif">
                                        <div class="grid grid-cols-2 gap-4">
                                            <input-rule v-model="newDateHa" :options="{min: minDateToday, max: maxDateToday}" type="date" :label="$tt('Nouvelle date d\'achat')" :label-inline="false" custom="text-xs" id="new-date-ha" />
                                            <input-rule v-model="newDateHaFile" type="file" :label="$tt('Justificatif d\'achat')" :label-inline="false" custom="text-xs" id="new-date-ha-file" />
                                        </div>
                                    </template>
                                    <template v-else>-</template>
                                </td>
                            </tr>
                            <tr v-if="!edit">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Date de dépot') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.date_create | momentDateTime }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">{{ $tt('Durée de prise en charge') }}: {{ dureePriseCharge + ' ' + (dureePriseCharge > 1 ? 'jours' : 'jour') }}</td>
                            </tr>
                            <tr >
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Référence comm') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">{{ data.ref_commerciale || '-' }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5"><input-rule type="text" v-model="form.refcomm" id="categorie" :label-inline="false" /></td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Bonus QualiRepar') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">
                                    <template v-if="ecosystemAmount == -1">{{ $tt('Non applicable') }} </template>
                                    <template v-if="ecosystemAmount >= 0">{{ ecosystemAmount || '0.00' }} €</template>
                                </td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">-</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Numéro de série') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">-</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <div class="grid grid-cols-2 gap-4">
                                        <div>
                                            <input-rule @input="checkNumSerie" :type="confirmNewNum ? 'password' : 'text'" v-model="form.numSerie" :label-inline="false" id="num_serie" />
                                            <div v-if="numSerieValid && isGarantie" class="text-xs text-green-600 mt-1">{{ $tt('Numéro de série valide') }}</div>
                                            <div v-if="numSerieValid === false && isGarantie" class="text-xs text-orange-600 mt-1.5"><i class="fas fa-exclamation-triangle mr-0.5"></i> {{ $tt('Numéro de série invalide') }}</div>
                                            <div style="font-size: 10px" class="font-light text-slate-500" v-if="edit">{{ $tt('Laissez vide pour ne pas modifier') }}</div>
                                        </div>
                                        <div class="text-right text-sm flex flex-col items-end justify-end">
                                            <template v-if="numSerieValid !== null">
                                                <button v-if="!confirmNewNum && isGarantie" @click="confirmPhotoSerie = false; confirmNewNum = true" class="px-2 py-1.5 bg-slate-200 hover:bg-slate-300 duration-200 mt-1 text-xs rounded"><i class="fas fa-pen mr-0.5"></i> {{ $tt(('Corriger')) }}</button>
                                                <button v-if="confirmNewNum" @click="confirmNewNum = false" class="px-2 py-1.5 bg-red-600 hover:bg-red-700 duration-200 mt-1 text-xs rounded text-white"><i class="fas fa-times mr-0.5"></i> {{ $tt(('Annuler')) }}</button>
                                            </template>
                                            <button v-if="isGarantie" @click="confirmPhotoSerie = !confirmPhotoSerie; confirmNewNum = false; form.newNumSerie = null" class="px-2 py-1.5 duration-200 mt-1 text-xs rounded" :class="confirmPhotoSerie ? 'bg-orange-200 hover:bg-orange-300' : 'bg-slate-200 hover:bg-slate-300'"><i class="fas fa-eye-slash mr-1"></i> {{ $tt('Numéro de série illisible') }}</button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="confirmNewNum">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Confirmer le numéro de série') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">-</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <div class="flex items-center gap-2">
                                        <input-rule type="text" v-model="form.newNumSerie" :label-inline="false" class="grow" />
                                        <i v-if="form.numSerie != form.newNumSerie" class="fas fa-times-circle text-red-500 text-lg"></i>
                                        <i v-if="form.numSerie == form.newNumSerie" class="fas fa-check-circle text-green-500 text-lg"></i>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="confirmPhotoSerie">
                                <td class="border border-slate-300 font-bold text-gray-800 text-sm px-2 py-1.5 bg-slate-200">{{ $tt('Photo de la plaque signalétique') }}</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5 bg-slate-100">-</td>
                                <td class="border border-slate-300 font-light text-sm px-2 py-1.5">
                                    <div class="flex items-center gap-2">
                                        <input-rule type="file" v-model="form.photoNumSerie" :label-inline="false" class="grow" />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="mt-4">
                        <div class="grid grid-cols-2 gap-10">
                            <div>
                                <h2 class="mb-2 font-bold text-slate-500 text-sm">{{$tt('Photos du produit')}} :</h2>
                                <input id="fileUpload" type="file" hidden @change="uploadPhoto">
                                <div class="grid grid-cols-4 gap-2">
                                    <div class="rounded h-[50px] bg-slate-100 hover:bg-slate-200 duration-200 justify-center items-center flex cursor-pointer" @click="openInpPhoto()">
                                        <div class="text-center">
                                            <i class="fas fa-plus text-sm"></i>
                                            <div class="-mt-1 font-light" style="font-size: 10px">Ajouter</div>
                                        </div>
                                    </div>
                                    <div v-for="(img, imgIndex) in data.app_imgs" :key="'app-image-' + imgIndex">
                                        <div class="group relative">
                                            <a :href="img.url" target="_blank">
                                                <div class="group-hover:bg-slate-800/10 cursor-pointer duration-200 absolute top-0 left-0 right-0 bottom-0 rounded"></div>
                                                <img :src="img.url" class="w-full border rounded-sm">
                                            </a>
                                            <div @click.stop="deleteImg(img)" class="cursor-pointer absolute top-0 left-0 px-2 bg-red-400 rounded-br rounded-tl"><i class="fas fa-trash text-white" style="font-size: 10px"></i></div>
                                        </div>
                                    </div>
                                    <div v-if="loadingAddPhoto">
                                        <spinner :size="10" />
                                    </div>
                                </div>
                            </div>
                            <div class="text-right">
                                <template v-if="accessoires.length > 0">
                                    <h2 class="mb-2 font-bold text-slate-500 text-sm">{{$tt('Accessoires présents')}} :</h2>
                                    <div>
                                        <div v-for="(accessoire, accessoireIndex) in accessoires" :key="'accessoire-start-' + accessoireIndex" class="rounded text-sm duration-200 mb-1">
                                            <div class="flex items-center justify-end gap-2">
                                                <div class="ml-1 mt-1 font-bold" :class="accessoire.verif_sta ? '' : 'opacity-50'">{{ accessoire.accessoire.designation }}</div>
                                                <input-rule type="pcheck" v-model="accessoire.verif_sta" :label-inline="false" />
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="text-right mt-4 pt-3 border-t-2">
                        <button :disabled="loadingStartFolder" @click="goToFolder('form')" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 mt-1 text-sm rounded text-white">
                            <spinner :size="10" v-if="loadingStartFolder" />
                            <template v-if="!loadingStartFolder">
                                <i class="fas fa-check mr-0.5"></i> {{ $tt(('Enregistrer le dossier')) }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-send-mail" height="auto" width="60%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt("Accessoires manquants") }}
                    </h2>
                    <button @click="$modal.hide('modal-send-mail')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-3 items-start gap-4 mb-4 border-b pb-4">
                        <div class="col-span-2 p-2">
                            <div class="font-bold">{{ $tt('Notifier le magasin des accessoires manquants') }} ?</div>
                            <div class="flex items-center gap-2 mt-0.5">
                                <input-rule v-model="sendAlertAccessoire" type="pcheck" :label-inline="false" />
                                <div class="text-sm mt-0.5">{{$tt('Je souhaite envoyer une alerte au magasin')}}</div>
                            </div>
                        </div>
                        <div class="bg-slate-100 border p-4 rounded">
                            <div class="text-sm">{{ $tt('Liste des accessoires manquants') }}:</div>
                            <ul v-if="accessoires" class="ml-4 mt-1 list-disc text-sm font-light">
                                <li v-for="(accessoire, accessoireIndex) in accessoires.filter((item) => !item.verif_sta)" :key="'empty-accessoire-' + accessoireIndex">{{ $tt(accessoire.accessoire.designation) }}</li>
                            </ul>
                        </div>
                    </div>
                    <button :disabled="loadingStartFolder" @click="goToFolder('modal')" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-sm rounded text-white">
                        <spinner :size="10" v-if="loadingStartFolder" />
                        <template v-if="!loadingStartFolder">
                            <i class="fas fa-check mr-0.5"></i> {{ $tt(('Enregistrer le dossier')) }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import moment from 'moment'
import apiUploadService from '@/services/apiUpload.service'

export default {
    props: {
        line: {
            required: true
        },
        staCategs: {
            required: true,
        },
        modalName: {
            default: 'modal-start-pc'
        },
        edit: {
            default: false
        }
    },
    watch: {
        line() {
            this.init()
        },
        staCategFiltered(newItems) {
            if (newItems.length === 1) {
                this.categStaId = newItems[0].id;
            }
        }
    },
    data() {
        return {
            data: null,
            modeles: [],
            references: [],
            form: {},
            garanties: [],
            
            numSerieValid: null,
            confirmNewNum: false,
            
            confirmPhotoSerie: false,
            
            newDateHa: null,
            newDateHaFile: null,
            
            ecosystemAmount: 0,
            
            categStaId: null,
            
            accessoires: [],
            sendAlertAccessoire: false,
            
            etatAppareil: 'Bon état',

            loadingStartFolder: false,
            loadingGetData: false,
            loadingAddPhoto: false,

            appEtats: [
                {libelle: 'Bon état', key: 'Bon état'},
                {libelle: 'Choc', key: 'Choc'},
                {libelle: 'Cassé', key: 'Cassé'},
                {libelle: 'Rayé', key: 'Rayé'},
                {libelle: 'Usagé', key: 'Usagé'},
            ]
        }
    },
    computed: {
        isGarantie() {            
            if (this.form.garantie) {
                return this.form.garantie.garantie == 'hors_garantie' ? 0 : 1
            }
            return 0
        },
        garantieLabels() {
            return {
                'garantie_marque': 'Garantie Marque',
                'garantie_distributeur': 'Garantie Distributeur',
                'garantie_pms': 'Garantie PMS',
                'garantie_comp': 'Garantie Complémentaire',
                'hors_garantie': 'Hors garantie',
                '0': '-'
            }
        },
        dureePriseCharge() {
            let now = moment()
            return now.diff(this.data.date_create, 'days')
        },
        dateHaVerif() {
            if (this.data.garantie == 'hors_garantie' && this.form.garantie && this.form.garantie.garantie != 'hors_garantie') {
                return true
            }
            return false
        },
        minDateToday() {
            return moment().subtract(this.data.duree_mois_garantie, 'months').format('YYYY-MM-DD')
        },
        maxDateToday() {
            return moment().format('YYYY-MM-DD')
        },
        staCategFiltered() {
            let items = []
            
            if (this.data) {
                if (this.data.device.id_categ_distri) {
                    items = this.staCategs[this.data.device.id_categ_distri].filter((item) => item.id_fournisseur == this.form.garantie?.id_fournisseur)
                } else {       
                    if (this.staCategs) {
                        items = Object.values(this.staCategs).flat().filter((item) => item.id_fournisseur == this.form.garantie?.id_fournisseur)
                    }
                }
            }
            return items
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async init() {            
            this.form = {
                modele: null,
                refcomm: this.line.device.ref_commerciale,
                garantie: null,
            }
            this.numSerieValid = null
            this.confirmNewNum = false
            this.confirmPhotoSerie = false
            this.ecosystemAmount = 0
            this.accessoires = []
            this.data = this.line        
            this.categStaId = this.line.id_categ_sta

            this.loadingGetData = true
            await this.getModeles()
            await this.getGaranties()
            await this.getEcosystemAmount()
            await this.getAccessoires()
            this.loadingGetData = false
        },

        async getModeles() {
            let response = await this.$request.post('technicien.get-modeles-in-marque', {
                marque: this.data.device['Marque']
            })
            this.modeles = response.data.posts.post
            
            let selectedModel = this.modeles.find((item) => item == this.line.device['Modele'])
            
            if (selectedModel) {
                this.form.modele = selectedModel
                this.form.modele_design = selectedModel
            }
        },

        async getGaranties() {
            let response = await this.$request.post('technicien.get-dossier-garantie', {
                id_dossier: this.data.id_dossiersav
            })
            this.garanties = response.data.posts.post
            this.form.garantie = this.garanties.find((item) => item.garantie == this.data.garantie)
        },

        checkNumSerie() {
            if (!this.form.numSerie) {
                this.numSerieValid = null
            } else {
                this.numSerieValid = this.form.numSerie == this.data.num_serie
            }
        },

        async goToFolder(from) {
            let verifFields = [
                {value: this.categStaId, rules: 'required', id: 'categ_sta'},
                {value: this.form.modele, rules: 'required', id: 'modele'},
                {value: this.form.modele_design, rules: 'required', id: 'modele_design'}
            ]
            if (this.isGarantie) {
                verifFields.push({value: this.form.numSerie, rules: 'required', id: 'num_serie'})
                verifFields.push({value: this.form.garantie, rules: 'required', id: 'garantie'})
            } else {
                verifFields.push({value: this.form.garantie, rules: 'required', id: 'garantie'})
            }

            if (this.dateHaVerif && this.dateHaVerif) {
                verifFields.push({value: this.newDateHa, rules: 'required', id: 'new-date-ha'})
                verifFields.push({value: this.newDateHaFile, rules: 'required', id: 'new-date-ha-file'})
            }

            if (!this.$validation.check(verifFields)) {
                return false
            }

            if (this.isGarantie && this.form.numSerie != this.data.num_serie && !this.confirmNewNum && !this.confirmPhotoSerie) {
                this.$toast.error(this.$tt('Numéro de série invalide'))
                return false
            }

            if (!this.edit && this.confirmNewNum) {
                if (this.form.numSerie != this.form.newNumSerie) {
                    this.$toast.error(this.$tt('Les numéros de série ne correspondent pas'))
                    return false
                }
            }
            if (this.confirmPhotoSerie && !this.form.photoNumSerie) {
                this.$toast.error(this.$tt('Photo plaque signalétique obligatoire'))
                return false
            }

            if (!this.edit && from == 'form' && this.accessoires.find((item) => item.verif_sta == 0)) {
                this.$modal.show('modal-send-mail')
                return false
            }

            let endData = {
                id_dossier: this.data.id_dossiersav,
                accessoires: JSON.stringify(this.accessoires.filter((item) => item.verif_sta).map((item) => item.id_accessoire)),
                send_mail: this.sendAlertAccessoire,
                id_categ_sta: this.categStaId,
                etat_appareil: this.etatAppareil
            }
            if ((!this.edit && this.data.num_serie != this.form.numSerie) || (this.edit && this.form.numSerie)) {
                endData.num_serie = this.form.numSerie
            }
            if (this.form.modele && this.line.device['Modele'] != this.form.modele) {
                endData.modele = this.form.modele
            }
            if (this.data.garantie != this.form.garantie && !this.edit) {
                endData.id_garantie = this.form.garantie.id
                endData.garantie = this.form.garantie.garantie
            }
            if (this.form.refcomm && this.line.device.ref_commerciale != this.form.refcomm) {
                endData.ref_commerciale = this.form.refcomm
            }
            if (this.ecosystemAmount && this.ecosystemAmount > 0) {
                endData.montant_ecosystem = this.ecosystemAmount
            }
            if (this.confirmPhotoSerie && this.form.photoNumSerie) {
                endData.num_serie_file_id = this.form.photoNumSerie.id
            }
            if (this.dateHaVerif) {
                endData.date_ha = this.newDateHa
                endData.justif_achat_id = this.newDateHaFile.id
            }
            if (this.form.modele_design) {
                endData.modele_design = this.form.modele_design

            }
            
            this.loadingStartFolder = true
            
            let response = await this.$request.post('technicien.start-dossier', endData)
            this.loadingStartFolder = false
            if (response.data.posts.post == 1) {
                this.init()
                this.$emit('started-folder')
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.numSerieValid = true
            this.newNumSerie = false

            if (from == 'modal') {
                this.$modal.hide('modal-send-mail')
            }
        },

        async getEcosystemAmount() {
            let response = await this.$request.post('technicien.get-ecosystem-amount', {
                id_dossier: this.data.id_dossiersav
            })
            this.ecosystemAmount = response.data.posts.post
        },

        async getAccessoires() {
            let response = await this.$request.post('technicien.dossier-get-accessoires', {
                id_dossier: this.data.id_dossiersav
            })
            this.accessoires = response.data.posts.post
        },

        openInpPhoto() {
            document.getElementById("fileUpload").click()
        },

        async uploadPhoto(event) {
            let fileInput = event.target.files.length > 0 ? event.target.files[0] : null

            if (fileInput) {
                this.fileUrl = null
                this.loadingAddPhoto = true
                let responseFile = await apiUploadService.post({
                    files: fileInput
                })
                let url = responseFile.data.files[0].url
                let response = await this.$request.post('technicien.add-app-photo', {
                    id_dossier: this.data.id_dossiersav,
                    url: url
                })
                this.data.app_imgs.push(response.data.posts.post)
                this.loadingAddPhoto = false
                this.$forceUpdate()
            }
        },
        async deleteImg(img) {
            let response = await this.$request.post('technicien.delete-app-photo', {
                file_id: img.id
            })
            this.data.app_imgs = response.data.posts.post
        }
    },
    mounted () {
        this.init()
    },
}
</script>