<template>
    <div>
        <div class="fixed top-0 left-0 right-0 py-4 bg-gray-800 text-white">
            <div class="container mx-auto flex items-center justify-between max-w-[900px]">
                <div class="flex items-center gap-8">
                    <img class="h-[40px]" src="https://disticloudfiles.net/upload/files/fd04f650272ed213928bbc748d72d9f1.png" alt="">
                    <div class="text-lg text-white/70 font-light">Espace documentation</div>
                </div>
                {{ $request.user()?.account?.email }}
            </div>
        </div>
        <div class="pt-[100px] h-screen overflow-y-auto" v-if="page">
            <div class="container mx-auto max-w-[900px]">
                <nav class="flex mb-10" aria-label="Breadcrumb">
                    <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                        <li class="inline-flex items-center">
                            <button @click="$router.push({name: 'aides.home'})" class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600">
                                <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
                                </svg>
                                Accueil
                            </button>
                        </li>
                        <li>
                            <div class="flex items-center">
                                <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                                </svg>
                                <button @click="$router.push({name: 'aides.categorie', params: {categorie: page.categorie_url}})" class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2">{{ page.categorie }}</button>
                            </div>
                        </li>
                        <li aria-current="page">
                            <div class="flex items-center">
                                <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
                                </svg>
                                <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2">{{ page.titre }}</span>
                            </div>
                        </li>
                    </ol>
                </nav>

                <div>
                    <div class="text-3xl font-bold border-b mb-4 pb-4">{{ page.titre }}</div>
                    <div class="pb-[100px]" v-html="page.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            page: []
        }
    },
    methods: {
        async getPage() {
            this.page = await this.$request.send('aides.get-page', {
                page_id: this.$route.params.pageId,
                no_user: 1
            })
        }
    },
    mounted() {
        this.getPage()
    }
}
</script>