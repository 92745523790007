<template>
    <div>
        <modal name="modal-display-product" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedProduct">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{ selectedProduct['Modele'] }}</h2>
                    <button @click="$modal.hide('modal-display-product')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <product-form
                    :product="selectedProduct"
                    :all-categories="allCategories"
                    :marques="marques"
                    @submit="$modal.hide('modal-display-product')"
                    :display-title="false"
                    :display-proc="false"
                    />
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-delete-accessoire" height="auto" :scrollable="true">
            <div class="p-10" style="z-index: 999 !important;" v-if="selectedAccessoire">
                <h2 class="text-xl font-bold mb-2">{{ $tt('Supprimer un accessoire') }}</h2>
                <p class="font-light p-2 rounded bg-slate-100 my-4">{{ $tt('Souhaitez-vous réellement supprimer l\'accessoire') }} <b>{{ selectedAccessoire.designation }}</b> ?</p>
                <div class="flex items-center gap-2 mt-2">
                    <button @click="$modal.hide('modal-confirm-delete-accessoire')" class="bg-slate-200 hover:bg-slate-300 duration-200 px-4 py-2 rounded">{{ $tt('Annuler') }}</button>
                    <button @click="deleteAccessoire(selectedAccessoire)" class="bg-red-500 hover:bg-red-600 duration-200 text-white px-4 py-2 rounded">
                        <spinner v-if="loadingDeleteAccessoire" :size="10" />
                        <template v-if="!loadingDeleteAccessoire">
                            {{ $tt('Supprimer') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-edit-add-categ" height="auto" :scrollable="true">
            <div class="p-8" v-if="selectedEditCateg">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">
                        <template v-if="selectedEditCateg.id">
                            {{$tt('Modifier une catégorie')}}
                        </template>
                        <template v-else>
                            {{$tt('Ajouter une catégorie')}}
                        </template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-categ')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <input-rule type="text" v-model="selectedEditCateg.fr" :label="$tt('Nom de la catégorie (FR)')" :label-inline="false" id="new-categ-name" />

                    <input-rule v-model="selectedEditCateg.code_group" @input="getDistriCategs" type="select" :options="{values: groupsRef, label: 'fr', key: 'code'}" :label="$tt('Catégorie EICTA')" :label-inline="false" class="mt-4" id="id-distri-groups" />
                    <template v-if="selectedEditCateg.code_group">
                        <input-rule v-if="!loadingGetDistriCategs && categDistris && Object.keys(categDistris).length > 0" v-model="groupCategDistri" type="select" :options="{values: Object.keys(categDistris)}" :label="$tt('Catégorie')" :label-inline="false" class="mt-4" id="id-distri-group-categ" />
                        <input-rule v-if="!loadingGetDistriCategs && groupCategDistri && categDistris[groupCategDistri]" v-model="selectedEditCateg.id_distri_categ" type="select" :options="{values: categDistris[groupCategDistri], label: 'nom', key: 'id'}" :label="$tt('Précision de la catégorie')" :label-inline="false" class="mt-4" id="id-distri-categ" />
                        <div class="flex items-center gap-8">
                            <input-rule type="pcheck" v-model="selectedEditCateg.gaz" v-if="selectedEditCateg.id_distri_categ && groupCategDistri && categDistris[groupCategDistri] && categDistris[groupCategDistri].find((item) => item.id == selectedEditCateg.id_distri_categ)?.gaz == 1" :label="$tt('Gestion du gaz')" :label-inline="false" class="mt-4" />
                            <input-rule type="pcheck" v-model="selectedEditCateg.thermique" v-if="selectedEditCateg.id_distri_categ && groupCategDistri && categDistris[groupCategDistri] && categDistris[groupCategDistri].find((item) => item.id == selectedEditCateg.id_distri_categ)?.thermique == 1" :label="$tt('Moteur thermique')" :label-inline="false" class="mt-4" />
                        </div>
                    </template>

                    <button @click="editAddCateg(selectedEditCateg)" :disabled="loadingAddCateg" class="px-4 py-2 bg-blue-500 hover:bg-blue-600 duration-200 text-white text-sm rounded mt-4">
                        <spinner v-if="loadingAddCateg" :size="10" />
                        <template v-if="!loadingAddCateg">
                            <i class="fas fa-check mr-1"></i>
                            {{$tt('Enregistrer')}}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-add-accessoire" height="auto" :scrollable="true">
            <div class="p-8" v-if="formNewAccessoire">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter un accessoire à la catégorie')}}</h2>
                    <button @click="$modal.hide('modal-add-accessoire')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="formNewAccessoire.designation" type="text" :label-inline="false" :label="$tt('Nom de l\'accessoire')" class="mb-4" />
                    <input-rule v-model="formNewAccessoire.oblig" type="pcheck" :label-inline="false" :label="$tt('Saisie obligatoire')" class="mb-4" />
                    <button @click="editAddAccessoire()" :disabled="loadingEditAccessoire" class="px-4 py-2 bg-green-700 hover:bg-green-700 text-white duration-200 rounded text-sm">
                        <spinner v-if="loadingEditAccessoire" :size="10" />
                        <template v-if="!loadingEditAccessoire">
                            <i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-select-proc" height="auto" width="90%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Gestion des procédures')}}</h2>
                    <button @click="$modal.hide('modal-select-proc')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <button @click="$modal.show('modal-edit-add-proc-categ')" class="px-4 py-2 mb-4 rounded text-sm bg-slate-100 hover:bg-slate-200 duration-200">
                        <i class="fas fa-plus mr-0.5"></i> {{$tt('Ajouter une procédure')}}
                    </button>
                    <div class="border-t pt-4">
                        <table-multi-proc v-model="procsList" />
                    </div>
                    <div class="text-center mt-8">
                        <button @click="confirmSelectProc" class="px-4 py-2 rounded bg-green-500 hover:bg-green-600 text-white">{{$tt('Valider')}} <i class="fas fa-check ml-1"></i></button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-edit-add-proc-categ" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter une procédure')}}</h2>
                    <button @click="$modal.hide('modal-edit-add-proc-categ')">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <form-procedure @update="updatedPattern" />
                </div>
            </div>
        </modal>

        <div class="py-4" v-if="loadingGetCateg">
            <div class="flex justify-center">
                <spinner :size="12" />
            </div>
            <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
        </div>

        <div v-if="!loadingGetCateg" class="flex gap-8">
            <div v-if="queryConfig.displayMenu" class="border-r-2 p-6 pt-0 min-w-[350px] max-w-[350px]" id="categ-list">
                <div class="border-b pb-2 mb-2">
                    <h1 class="text-xl font-bold">{{$tt('Mes catégories')}}</h1>
                </div>
                <div class="my-2">
                    <button @click="selectedEditCateg = {fr: null}; $modal.show('modal-edit-add-categ')" class="px-4 py-2 rounded bg-slate-200 hover:bg-slate-300 duration-200 text-sm">
                        <i class="fas fa-plus mr-1"></i> {{$tt('Ajouter une catégorie')}}
                    </button>
                </div>
                <div class="max-h-[50vh] overflow-y-scroll">
                    <div @click="selectCateg(categ); selectedTab = categ.id_distri_categ ? 'appareils' : 'config'" v-for="(categ, categIndex) in categs" :key="'categ-' + categIndex" class="pb-2 cursor-pointer" :class="selectedCateg && selectedCateg.categ_id == categ.categ_id ? 'font-bold text-blue-600' : 'hover:font-bold duration-200'">
                        <!-- <button @click.stop="selectedEditCateg = {fr: categ.fr, id: categ.categ_id}; $modal.show('modal-edit-add-categ')" class="px-4 py-2 rounded text-sm opacity-40 hover:opacity-100 duration-200"><i class="fas fa-pen"></i></button> -->
                        <div class="flex items-center justify-between gap-2">
                            <div>{{ categ.fr }}</div>
                            <i v-if="!categ.id_distri_categ" class="fas fa-triangle-exclamation text-red-500"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grow overlow-x-auto relative" style="min-width: 0;">
                <div v-if="selectedCateg" class="overflow-x-auto">
                    <div class="flex items-center gap-3 font-semibold text-slate-500 text-sm mb-6">
                        <div>Mes catégories</div>
                        <div><i class="fas fa-chevron-right text-xs"></i></div>
                        <div>{{selectedCategName}}</div>
                    </div>
                    <div class="text-sm font-medium text-center text-gray-500 mt-4">
                        <ul class="flex flex-wrap items-end -mb-px">
                            <li @click="selectedTab = 'config'" class="me-2 cursor-pointer">
                                <span class="inline-block px-5 rounded-t" :class="!selectedCateg.id_distri_categ ? 'py-2.5 hover:py-3 bg-red-100 hover:bg-red-200 text-red-600' : (selectedTab == 'config' ? 'py-3 text-white bg-blue-600 font-bold' : 'py-2.5 hover:py-3 bg-slate-100 hover:bg-slate-200')">
                                    <i v-if="selectedCateg.id_distri_categ" class="fas fa-cog mr-1"></i>
                                    <i v-if="!selectedCateg.id_distri_categ" class="fas fa-triangle-exclamation text-red-500 mr-1"></i>
                                    {{ $tt('Catégorie') }}
                                </span>
                            </li>
                            <li @click="selectedTab = 'appareils'" class="me-2 cursor-pointer">
                                <span class="inline-block px-5 rounded-t"  :class="selectedTab == 'appareils' ? 'py-3 text-white bg-blue-600 font-bold' : 'py-2.5 hover:py-3 bg-slate-100 hover:bg-slate-200'"><i class="fas fa-mobile-screen mr-1"></i> {{ $tt('Appareils') }}</span>
                            </li>
                            <li @click="selectedTab = 'procedures'" class="me-2 cursor-pointer">
                                <span class="inline-block px-5 rounded-t" :class="selectedTab == 'procedures' ? 'py-3 text-white bg-blue-600 font-bold' : 'py-2.5 hover:py-3 bg-slate-100 hover:bg-slate-200'"><i class="fas fa-code-branch mr-1"></i> {{ $tt('Procédures') }}</span>
                            </li>
                            <li @click="getAccessoires(); selectedTab = 'accessoires'" class="me-2 cursor-pointer">
                                <span class="inline-block px-5 rounded-t" :class="selectedTab == 'accessoires' ? 'py-3 text-white bg-blue-600 font-bold' : 'py-2.5 hover:py-3 bg-slate-100 hover:bg-slate-200'"><i class="fas fa-box mr-1"></i> {{ $tt('Accessoires') }}</span>
                            </li>
                            <li @click="selectedTab = 'codes_panne'" class="me-2 cursor-pointer">
                                <span class="inline-block px-5 rounded-t" :class="selectedTab == 'codes_panne' ? 'py-3 text-white bg-blue-600 font-bold' : 'py-2.5 hover:py-3 bg-slate-100 hover:bg-slate-200'"><i class="fas fa-tags mr-1"></i> {{ $tt('Codes Panne') }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="border-2 border-t-2 rounded-b rounded-tr p-4">
                        <div v-if="selectedTab == 'procedures'">
                            <button class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded text-sm" @click="$modal.show('modal-select-proc')" id="btn-proc-categ"><i class="fas fa-cog mr-1"></i> {{$tt('Gestion des procédures')}}</button>
                            <div>
                                <div class="bg-slate-100 p-4 rounded mt-2" v-if="patterns.length <= 0">
                                    {{ $tt('Aucune procédure paramétrée') }}
                                </div>
                                <div v-if="patterns && patterns.length > 0" class="mt-2">
                                    <table-procedure
                                    :can-create="false"
                                    :read-only="true"
                                    :procedures="patterns"
                                    />
                                </div>
                            </div>
                        </div>
                        <div id="categ-app-list" v-if="selectedTab == 'appareils'">
                            <data-table
                            max-height="400px"
                            :filters-inline="false"
                            :action-at-start="true"
                            :filters="[
                                {key: 'Marque', label: $tt('Marque'), type: 'text', value: null},
                                {key: 'Modele', label: $tt('Modèle'), type: 'text', value: null},
                                {key: 'Ean', label: $tt('Ean'), type: 'text', value: null},
                            ]"
                            :columns="[
                                {label: $tt('Marque'), key: 'Marque', type: 'text'},
                                {label: $tt('Modèle'), key: 'Modele', type: 'text'},
                                {label: $tt('Libellé'), key: 'Libelle', type: 'text'},
                                {label: $tt('Ean'), key: 'Ean', type: 'text'},
                            ]"
                            :lines="selectedCateg.appareils"
                            >
                                <template v-slot:actions='{line}'>
                                    <button @click="selectProduct(line)" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded-xl text-white" type="submit">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                </template>
                            </data-table>
                        </div>
                        <div v-if="selectedTab == 'accessoires'">
                            <button class="px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded text-sm" @click="formNewAccessoire = {}; $modal.show('modal-add-accessoire')" id="btn-proc-categ"><i class="fas fa-plus mr-1"></i> {{$tt('Ajouter un accessoire')}}</button>
                            <div class="bg-slate-100 p-4 rounded mt-2" v-if="accessoires && accessoires.length <= 0">
                                <i class="fas fa-info-circle mr-1"></i> {{ $tt('Aucun accessoire pour cette catégorie') }}
                            </div>
                            <table v-if="accessoires && accessoires.length > 0" class="w-full border text-sm mt-2">
                                <thead class="bg-slate-50 font-bold">
                                    <tr>
                                        <td class="px-2 py-1">{{$tt('Nom')}}</td>
                                        <td class="px-2 py-1">{{$tt('Saisie obligatoire')}}</td>
                                        <td class="px-2 py-1">{{$tt('Actions')}}</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(accessoire, accessoireIndex) in accessoires" :key="'accessoire-table-' + accessoireIndex" class="border-b">
                                        <td class="px-2 py-1">
                                            <template v-if="!accessoire.edit">{{ accessoire.designation }}</template>
                                            <template v-if="accessoire.edit">
                                                <input-rule v-model="accessoire.designation_inp" type="text" :label="$tt('Désignation')" :label-inline="false" />
                                            </template>
                                        </td>
                                        <td class="px-2 py-1">
                                            <template v-if="!accessoire.edit">
                                                <template v-if="accessoire.oblig">
                                                    <i class="fas fa-check text-green-600"></i>
                                                </template>
                                                <template v-if="!accessoire.oblig">
                                                    -
                                                </template>
                                            </template>
                                            <template v-if="accessoire.edit">
                                                <input-rule v-model="accessoire.oblig_inp" type="pcheck" :label="$tt('Saisie obligatoire')" :label-inline="false" />
                                            </template>
                                        </td>
                                        <td class="px-2 py-1">
                                            <template v-if="!accessoire.edit">
                                                <button @click="accessoire.edit = true" class="px-3 py-1.5 bg-blue-500 hover:bg-blue-600 duration-200 text-white rounded text-xs mr-1">{{ $tt('Modifier') }}</button>
                                                <button @click="selectedAccessoire = accessoire; $modal.show('modal-confirm-delete-accessoire')" :disabled="loadingDeleteAccessoire" class="px-3 py-1.5 bg-red-500 hover:bg-red-600 duration-200 text-white rounded text-xs">
                                                    {{ $tt('Supprimer') }}
                                                </button>
                                            </template>
                                            <template v-if="accessoire.edit">
                                                <button @click="editAddAccessoire(accessoire); accessoire.designation_inp = accessoire.designation; accessoire.oblig = accessoire.oblig_inp" :disabled="loadingEditAccessoire" class="px-3 py-1.5 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-xs mr-1">
                                                    <spinner v-if="loadingEditAccessoire" :size="10" />
                                                    <template v-if="!loadingEditAccessoire">
                                                        {{ $tt('Enregistrer') }}
                                                    </template>
                                                </button>
                                                <button @click="accessoire.edit = false" class="px-3 py-1.5 bg-slate-500 hover:bg-slate-600 duration-200 text-white rounded text-xs">{{ $tt('Annuler') }}</button>
                                            </template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="selectedTab == 'codes_panne'">
                            <div class="flex gap-6">
                                <div class="min-w-[200px]">
                                    <div class="font-bold text-lg mb-2 pr-10">{{ $tt('Codes panne') }}</div>
                                    <div class="border">
                                        <div @click="selectedTypeCode = 1" :class="selectedTypeCode == 1 ? 'bg-blue-500 text-white font-bold' : 'hover:bg-blue-500 hover:text-white'" class="p-1 border-b cursor-pointer duration-200">{{ $tt('Codes condition') }}</div>
                                        <div @click="selectedTypeCode = 2" :class="selectedTypeCode == 2 ? 'bg-blue-500 text-white font-bold' : 'hover:bg-blue-500 hover:text-white'"  class="p-1 border-b cursor-pointer duration-200">{{ $tt('Codes symptome') }}</div>
                                        <div @click="selectedTypeCode = 3" :class="selectedTypeCode == 3 ? 'bg-blue-500 text-white font-bold' : 'hover:bg-blue-500 hover:text-white'"  class="p-1 border-b cursor-pointer duration-200">{{ $tt('Codes section') }}</div>
                                        <div @click="selectedTypeCode = 4" :class="selectedTypeCode == 4 ? 'bg-blue-500 text-white font-bold' : 'hover:bg-blue-500 hover:text-white'"  class="p-1 border-b cursor-pointer duration-200">{{ $tt('Codes défaut') }}</div>
                                        <div @click="selectedTypeCode = 5" :class="selectedTypeCode == 5 ? 'bg-blue-500 text-white font-bold' : 'hover:bg-blue-500 hover:text-white'"  class="p-1 border-b cursor-pointer duration-200">{{ $tt('Codes réparation') }}</div>
                                    </div>
                                </div>
                                <div class="grow">
                                    <div class="font-bold text-lg mb-2">{{ $tt('Gestion des codes') }}</div>
                                    <code-panne-table :type-code="selectedTypeCode" :categ-id="selectedCateg.categ_id" />
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedTab == 'config'">
                            <div v-if="!selectedCateg.id_distri_categ" class="p-4 rounded bg-red-100 text-red-700 mb-4">
                                <div class="text-lg font-bold mb-1"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Formulaire incomplet') }}</div>
                                <div>{{ $tt('Merci de saisir vos catégories') }}</div>
                            </div>

                            <input-rule type="text" v-model="selectedCateg.fr" :label="$tt('Nom de la catégorie (FR)')" :label-inline="false" id="new-categ-name" />

                            <input-rule v-model="selectedCateg.code_group" @input="getDistriCategs" type="select" :options="{values: groupsRef, label: 'fr', key: 'code'}" :label="$tt('Catégorie EICTA')" :label-inline="false" class="mt-4" id="id-distri-groups" />
                            <template v-if="selectedCateg.code_group">
                                <input-rule v-if="!loadingGetDistriCategs && categDistris && Object.keys(categDistris).length > 0" v-model="groupCategDistri" type="select" :options="{values: Object.keys(categDistris)}" :label="$tt('Catégorie')" :label-inline="false" class="mt-4" id="id-distri-group-categ" />
                                <input-rule v-if="!loadingGetDistriCategs && groupCategDistri && categDistris[groupCategDistri]" v-model="selectedCateg.id_distri_categ" type="select" :options="{values: categDistris[groupCategDistri], label: 'nom', key: 'id'}" :label="$tt('Précision de la catégorie')" :label-inline="false" class="mt-4" id="id-distri-categ" />
                            </template>
                            
                            <div class="flex items-center gap-8">
                                <input-rule type="pcheck" v-model="selectedCateg.gaz" v-if="selectedCateg.id_distri_categ && groupCategDistri && categDistris[groupCategDistri] && categDistris[groupCategDistri].find((item) => item.id == selectedCateg.id_distri_categ)?.gaz == 1" :label="$tt('Gestion du gaz')" :label-inline="false" class="mt-4" />
                                <input-rule type="pcheck" v-model="selectedCateg.thermique" v-if="selectedCateg.id_distri_categ && groupCategDistri && categDistris[groupCategDistri] && categDistris[groupCategDistri].find((item) => item.id == selectedCateg.id_distri_categ)?.thermique == 1" :label="$tt('Moteur thermique')" :label-inline="false" class="mt-4" />
                            </div>

                            <button @click="editAddCateg(selectedCateg)" :disabled="loadingAddCateg" class="px-4 py-2 bg-blue-500 hover:bg-blue-600 duration-200 text-white text-sm rounded mt-4">
                                <spinner v-if="loadingAddCateg" :size="10" />
                                <template v-if="!loadingAddCateg">
                                    <i class="fas fa-check mr-1"></i>
                                    {{$tt('Enregistrer')}}
                                </template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TableMultiProc from './../Components/TableMultiProc.vue'
import TableProcedure from './../../Procedure/Components/TableProcedure.vue';
import FormProcedure from './../../Procedure/Components/FormProcedure.vue';
import ProductForm from '../../Appareils/Components/ProductForm.vue';
import CodePanneTable from './../Components/CodePanneTable.vue';
import ApiService from '@/services/api.service';

export default {
    components: {
        TableMultiProc,
        TableProcedure,
        FormProcedure,
        ProductForm,
        CodePanneTable
    },
    data() {
        return {
            categs: [],
            selectedCateg: null,
            procsList: null,
            procs: null,
            allPatterns: [],
            patterns: [],

            selectedEditCateg: null,

            queryConfig: {
                displayMenu: true,
                defaultCategId: null,
            },

            // selectedTab: 'proc',

            selectedProduct: null,
            marques: null,
            allCategories: null,

            selectedCategName: null,
            selectedTab: 'appareils',
            
            loadingAddCateg: false,
            loadingGetCateg: false,
            loadingUpdate: false,

            formNewAccessoire: {},
            accessoires: [],
            timerEditAccessoire: null,
            loadingDeleteAccessoire: false,
            loadingEditAccessoire: false,
            selectedAccessoire: false,

            selectedTypeCode: 1,

            groupsRef: null,
            categDistris: [],
            groupCategDistri: null,
            loadingGetDistriCategs: false,
        }
    },
    methods: {
        async getCategories(selectFirst = false) {
            this.loadingGetCateg = true
            let response = await this.$request.post('categ.list')
            this.categs = response.data.posts.post
            this.loadingGetCateg = false

            if (selectFirst && this.categs.length > 0) {
                this.selectCateg(this.categs[0])
            }
            if (this.queryConfig.defaultCategId) {
                this.selectCateg(this.categs.find((categ) => categ.categ_id == this.queryConfig.defaultCategId))
            } else if (this.selectedCateg) {
                this.selectCateg(this.categs.find((categ) => categ.categ_id == this.selectedCateg.categ_id))
            }
            this.$eventHub.$emit('categ-form-loaded')
        },

        async getDistriCategs(groupCode) {
            let group = this.groupsRef.find((group) => group.code == groupCode)
            this.loadingGetDistriCategs = true
            let response = await this.$request.post('categ.get-distri-categs', {
                eicta_id: group?.id
            })
            this.loadingGetDistriCategs = false
            this.categDistris = response.data.posts.post

            const findKeyById = (data, targetId) => {
                for (const key in data) {
                    if (data[key].some(item => item.id === targetId)) {
                    return key;
                    }
                }
                return null; // si non trouvé
            };

            if (this.selectedCateg.id_distri_categ) {
                let key = findKeyById(this.categDistris, this.selectedCateg.id_distri_categ)
                
                if (key) {
                    this.groupCategDistri = key
                }
            }
        },

        async getGroupRef() {
            let response = await this.$request.send('procedure.group-codes')
            this.groupsRef = response
        },

        async editAddCateg(categ) {
            if (!this.$validation.check([
                {value: categ.fr, rules: 'required', id: 'new-categ-name'},
                {value: this.groupCategDistri, rules: 'required', id: 'id-distri-group-categ'},
                    {value: categ.id_distri_categ, rules: 'required', id: 'id-distri-categ'},
            ])) {return}

            this.loadingAddCateg = true
            let response = await this.$request.post('categ.edit-add', {
                line_id: categ.categ_id ? categ.categ_id : 0,
                code_group: categ.code_group,
                fr: categ.fr,
                id_distri_categ: categ.id_distri_categ,
                gaz: categ.gaz ? 1 : 0,
                thermique: categ.thermique ? 1 : 0,
            })
            await this.getCategories()
            this.selectCateg(this.categs.find((categ) => categ.categ_id == response.data.posts.post.id))
            this.selectedEditCateg = null
            this.$modal.hide('modal-edit-add-categ')
            this.loadingAddCateg = false
        },

        selectCateg(categ) {   
            this.selectedCateg = JSON.parse(JSON.stringify(categ))
            this.procs = {}
            // Get pattern in categ
            categ.proc_configs.forEach((config) => {
                let keyname = config.pattern.type_garantie + (config.pattern.selfcare_proc == 1 ? '-selfcare' : '-magasin')
                this.procs[keyname] = config.id
            })
            this.procsList = JSON.parse(JSON.stringify(this.procs))
    
            this.selectedCategName = JSON.parse(JSON.stringify(categ.fr))

            // Select only linked pattern with his single conf
            this.selectPatternInProcsVariable()
        },

        selectPatternInProcsVariable() {
            let configIds = Object.values(this.procs)
            this.patterns = []
            this.allPatterns.forEach((item) => {
                let patternConfigsIds = item.configs.map((config) => {return config.id})
                patternConfigsIds.forEach((patternConfigsId) => {
                    if (configIds.find((configId) => configId == patternConfigsId)) {
                        item.configs = [item.configs.find((config) => config.id == patternConfigsId)]
                        this.patterns.push(item)
                    }
                })
            })
            this.$forceUpdate()
        },

        async confirmSelectProc() {
            this.procs = this.procsList
            this.$modal.hide('modal-select-proc')
            await this.saveCateg()
            await this.getAllCategories()
            await this.getPatterns()
            this.selectPatternInProcsVariable()
            this.$toast.success(this.$tt('Procédures modifiées'))
            this.selectCateg(this.categs.find((item) => item.categ_id == this.selectedCateg.categ_id))
            this.selectedTab = 'procedures'
            this.selectPatternInProcsVariable()
        },

        async saveCateg() {
            if (!this.$validation.check([
                {value: this.selectedCateg.fr, rules: 'required', id: 'fr'},
            ])) {return}

            this.loadingUpdate = true
            let response = await this.$request.post('categ.edit', {
                categ_id: this.selectedCateg.categ_id,
                fr: this.selectedCateg.fr,
                en: this.selectedCateg.en || '',
                de: this.selectedCateg.de || '',
                it: this.selectedCateg.it || '',
                es: this.selectedCateg.es || '',
                configs: JSON.stringify(Object.values(this.procsList))
            })
            if (response.data.posts.post == 1) {
                await this.getCategories()
            }
            this.loadingUpdate = false
        },

        async getPatterns() {
            this.loadingGetPattern = true
            let response = await this.$request.post('procedures.get-patterns')
            this.loadingGetPattern = false
            this.allPatterns = response.data.posts.post
        },

        async updatedPattern() {
            await this.getPatterns()
            this.selectCateg(this.categs.find((item) => item.categ_id == this.selectedCateg.categ_id))
            this.$eventHub.$emit('refresh-multi-proc-patterns')
            this.$modal.hide('modal-edit-add-proc-categ')
            this.selectedTab = 'procedures'
        },

        async getMarques() {
            let response = await this.$request.post('A5141getmarquefromgrossiste', {
                grossiste: ApiService.user().account.id_entite
            }, 'districloud_no_action')
            this.marques = response.data.posts.post
        },

        async getAllCategories() {
            this.loadingGetCateg = true
            let response = await this.$request.post('procedures.get-categs')
            this.allCategories = response.data.posts.post
            
            this.loadingGetCateg = false
        },

        async selectProduct(product) {
            if (!product) {
                return
            }
            let response = await this.$request.post('backoffice', {
                operation: 'getinfoproduct',
                id_appareil: product.id
            }, 'districloud_no_action')
            this.selectedProduct = response.data.posts.post[0]
            this.selectedProduct.id_categorie = this.allCategories.find((item) => item.id_categorie == this.selectedProduct.id_categorie)
            this.selectedProduct.url_notice = this.selectedProduct.notice
            this.selectedProduct.url_photo = this.selectedProduct.img
            this.selectedProduct.url_vue = this.selectedProduct.vue
            
            this.$modal.show('modal-display-product')
        },

        async editAddAccessoire(line = null) {
            let endData = {}
            if (!line) {
                endData = this.formNewAccessoire
            } else {
                endData = {
                    line_id: line.id,
                    designation: line.designation_inp,
                    oblig: line.oblig_inp
                }
            }
            
            endData.id_categ_ref = this.selectedCateg.categ_id

            clearTimeout(this.timerEditAccessoire)
            this.loadingEditAccessoire = true
            await this.$request.post('technicien.edit-add-accessoire', endData)
            this.getAccessoires()
            this.loadingEditAccessoire = false
            this.$modal.hide('modal-add-accessoire')

            this.formNewAccessoire = {}
        },

        async getAccessoires() {
            let response = await this.$request.post('technicien.get-categ-accessoires', {
                id_categ_ref: this.selectedCateg.categ_id
            })
            
            this.accessoires = response.data.posts.post.map((item) => ({
                ...item,
                edit: false,
                designation_inp: item.designation,
                oblig_inp: item.oblig
            }))
        },

        async deleteAccessoire(accessoire) {
            this.loadingDeleteAccessoire = true
            await this.$request.post('technicien.delete-categ-accessoire', {
                line_id: accessoire.id
            })
            this.getAccessoires()
            this.loadingDeleteAccessoire = false
            this.$modal.hide('modal-confirm-delete-accessoire')
        },
    },
    async mounted () {
        let urlString = window.location.href
        let paramString = urlString.split('?')[1]
        let queryString = new URLSearchParams(paramString)
        for (let pair of queryString.entries()) {
            if (pair[0] == 'display-menu') {
                this.queryConfig.displayMenu = pair[1] == '0' ? false : true
            } else if (pair[0] == 'categ-id') {
                this.queryConfig.defaultCategId = pair[1]
            }
        }

        this.getPatterns()
        this.getMarques()
        await this.getAllCategories()
        this.getCategories(1)
        this.getGroupRef()
    },
}
</script>

<style lang="scss" scoped>

</style>