<template>
    <div>
        <!-- Modal start pc -->
        <form-tech-start-pc
        v-if="selectedLine"
        :line="selectedLine"
        @started-folder="goToStartedFolder"
        :sta-categs="staCategs"
        />

        <!-- Modal display dossier -->
        <form-tech-devis
        v-if="selectedLineDossier"
        :id-dossier="selectedLineDossier.id_dossiersav"
        @refresh-list="refreshList"
        :user-admin="userIsAdmin"
        :station="station"
        />

        <!-- Modal confirm delete -->
        <modal name="modal-encours-atelier" height="auto" :scrollable="true" width="70%">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">{{ $tt('Encours atelier') }}</h2>
                    <button @click="$modal.hide('modal-encours-atelier')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div v-for="(encoursType, encoursTypeIndex) in dossiersEncours" :key="'encours-type-' + encoursTypeIndex" class="mb-4">
                        <h2 class="font-bold text-xl mb-2">
                            <template v-if="encoursTypeIndex == '0C'">{{ $tt('Devis accordés') }}</template>
                            <template v-if="encoursTypeIndex == '0B'">{{ $tt('Devis refusés') }}</template>
                        </h2>

                        <div class="text-sm font-light" :class="encoursTypeIndex == '0C' ? 'border-b pb-4' : ''" v-if="encoursType.length <= 0">
                            Aucune donnée
                        </div>
                        
                        <div v-if="encoursType.length > 0" class="table-atelier">
                            <div class="relative overflow-x-auto">
                                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                    <thead class="text-xs text-gray-700 uppercase">
                                        <tr class="bg-white border-b">
                                            <th class="px-4 py-2">{{$tt('Date')}}</th>
                                            <th class="px-4 py-2">{{$tt('Num. Dossier')}}</th>
                                            <th class="px-4 py-2">{{$tt('Modèle')}}</th>
                                            <th class="px-4 py-2">{{$tt('Catégorie')}}</th>
                                            <th class="px-4 py-2">{{$tt("Donneur d'ordre")}}</th>
                                            <th class="px-4 py-2">{{$tt('Techinicien')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="bg-white border-b" v-for="(dossierEncours, dossierEncoursIndex) in encoursType" :key="'dossier_encour' + dossierEncoursIndex">
                                            <td class="px-4 py-2">{{dossierEncours.date_create}}</td>
                                            <td class="px-4 py-2">{{dossierEncours.ref_dossier}}</td>
                                            <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device['Modele']}}</span></td>
                                            <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device.categorie_str}}</span></td>
                                            <td class="px-4 py-2">{{dossierEncours.nom_do}}</td>
                                            <td class="px-4 py-2"><span v-if="dossierEncours.technicien">{{dossierEncours.technicien.nom}}</span></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <!-- <div class="relative overflow-x-auto mb-10">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 my-2">
                                <thead>
                                    <tr class="bg-white border-b">
                                        <th class="px-4 py-2">{{$tt('Date')}}</th>
                                        <th class="px-4 py-2">{{$tt('Num. Dossier')}}</th>
                                        <th class="px-4 py-2">{{$tt('Modèle')}}</th>
                                        <th class="px-4 py-2">{{$tt('Catégorie')}}</th>
                                        <th class="px-4 py-2">{{$tt("Donneur d'ordre")}}</th>
                                        <th class="px-4 py-2">{{$tt('Techinicien')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white border-b" v-for="(dossierEncours, dossierEncoursIndex) in encoursType" :key="'dossier_encour' + dossierEncoursIndex">
                                        <td class="px-4 py-2">{{dossierEncours.date_create}}</td>
                                        <td class="px-4 py-2">{{dossierEncours.ref_dossier}}</td>
                                        <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device['Modele']}}</span></td>
                                        <td class="px-4 py-2"><span v-if="dossierEncours.device">{{dossierEncours.device.categorie_str}}</span></td>
                                        <td class="px-4 py-2">{{dossierEncours.nom_do}}</td>
                                        <td class="px-4 py-2"><span v-if="dossierEncours.technicien">{{dossierEncours.technicien.nom}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> -->
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal change tech -->
        <modal name="modal-change-tech" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">{{ $tt('Changer de technicien') }}</h2>
                    <button @click="$modal.hide('modal-change-tech')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div v-if="selectedFolder && intervenants && intervenants.length > 0">
                    <div class="bg-orange-100 p-2 rounded border grid grid-cols-3 items-center gap-4">
                        <div>
                            <div class="text-sm font-light ">{{ $tt('Technicien actuel') }}</div>
                            <div class="font-bold text-lg">{{ intervenants.find((item) => item.id == selectedFolder.id_tech)?.matricule }}</div>
                        </div>
                        <div class="text-center">
                            <i class="fas fa-arrow-right text-xl"></i>
                        </div>
                        <div>
                            <div class="text-sm font-light ">{{ $tt('Nouveau technicien') }}</div>
                            <input-rule v-model="changeTechId" type="select" :options="{values: intervenants, label: 'matricule', key: 'id'}" :label-inline="false" class="mb-2 grow" />
                        </div>
                    </div>
                    <div class="text-center mt-4">
                        <button :disabled="loadingChangeTech" @click="changeTech" class="px-4 py-2 bg-orange-500 hover:bg-orange-600 duration-200 rounded text-white">
                            <spinner v-if="loadingChangeTech" :size="10" />
                            <template v-if="!loadingChangeTech">
                                <i class="fas fa-repeat mr-1"></i> {{ $tt('Changer de technicien') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Page content -->
        <div class="grid grid-cols-4 gap-4 my-2" v-if="admin">
            <div class="flex items-center">
                <input-rule v-model="filters.intervenant" type="select" :options="{values: intervenants, label: 'matricule', key: 'id'}" :label="$tt('Intervenant')" :label-inline="false" class="mb-2 grow" />
                <button v-if="filters.intervenant" @click="filters.intervenant = null" class="px-2 py-0.5 bg-red-100 hover:bg-red-200 mt-3 border-2 border-red-100 hover:border-red-200 ml-1 rounded"><i class="fas fa-times"></i></button>
            </div>
            <div class="flex items-center">
                <input-rule v-model="filters.etat_avancement" type="select" :options="{values: avancements, label: 'label', key: 'codeanos'}" :label="$tt('Etat')" :label-inline="false" class="mb-2 grow" />
                <button v-if="filters.etat_avancement" @click="filters.etat_avancement = null" class="px-2 py-0.5 bg-red-100 hover:bg-red-200 mt-3 border-2 border-red-100 hover:border-red-200 ml-1 rounded"><i class="fas fa-times"></i></button>
            </div>
            <input-rule
            v-model="filters.nom_do"
            type="api-select"
            :options="{
                api: {action: 'technicien.search-donneur-ordre', apiName: 'api2', options: {limit: 100, orderBy: 'nom_do'}},
                label: 'nom_do',
                keyTrack: 'nom_do',
                init: true,
            }"
            :label="$tt('Donneur d\'ordre')"
            :label-inline="false"
            class="mb-2"
            />
            <div class="pt-4 text-right">
                <button @click="getEncours(); $modal.show('modal-encours-atelier')" class="px-4 py-2 bg-green-500 text-white rounded text-sm">{{ $tt('Encours atelier') }}</button>
            </div>
        </div>
        <div class="relative overflow-x-auto">
            <div class="pb-4 pt-7" v-if="loadingGetDossier">
                <div class="flex justify-center">
                    <spinner :size="12" />
                </div>
                <div class="mt-5 text-sm font-extralight text-center">Chargement des données..</div>
            </div>
            <div class="mb-20" v-if="!loadingGetDossier && currentFolders">
                <div class="flex items-center gap-2 justify-between mb-2">
                    <h2 class="text-xl font-bold flex items-center gap-2">
                        <template v-if="!allInOne">{{ $tt('Dossiers en cours') }}</template>
                        <template v-if="allInOne">{{ $tt('Liste des dossiers') }}</template>
                        <div class="text-xs font-semibold bg-slate-200 rounded-full p-1 px-2">{{ currentFolders.length }}</div>
                    </h2>
                    <input-rule @input="filterDossier" v-model="filterTypeDossier" type="select" :options="{values: ['Tout les dossiers', 'Particuliers', 'Professionnels']}" />
                </div>
                <factu-atelier-table :lines="currentFolders" table-class="bg-slate-200" :admin="admin" :user-admin="userIsAdmin" type="en_cours" :table-height="tableHeight">
                    <template v-slot:action="{line}">
                        <div class="flex items-center gap-1">
                            <button @click="selectFolder(line)" class="bg-slate-600 hover:bg-slate-700 text-xs text-white px-2.5 py-1.5 rounded"><i class="fas fa-hammer"></i></button>
                            <button v-if="admin" @click="selectedFolder = line; $modal.show('modal-change-tech')" class="bg-yellow-600 hover:bg-yellow-700 text-xs text-white px-2.5 py-1.5 rounded"><i class="fas fa-user-gear"></i></button>
                        </div>
                    </template>
                </factu-atelier-table>
            </div>
            <div v-if="!admin && waitingFolders" class="mb-10">
                <h2 class="text-xl font-bold mb-2 flex items-center gap-2">
                    {{ $tt('Dossiers à prendre en charge') }}
                    <div class="text-xs font-semibold bg-slate-200 rounded-full p-1 px-2">{{ waitingFolders.length }}</div>
                </h2>
                <factu-atelier-table :lines="waitingFolders" table-class="bg-orange-300" :admin="admin" type="attente" :table-height="tableHeight">
                    <template v-slot:action="{line}">
                        <button @click="startFolder(line)" class="bg-green-600 hover:bg-green-700 text-xs text-white px-2.5 py-1.5 rounded"><i class="fas fa-briefcase"></i></button>
                    </template>
                </factu-atelier-table>
            </div>
        </div>
    </div>
</template>

<script>
import FactuAtelierTable from './FactuAtelierTable.vue'
import FormTechStartPc from './FormTechStartPC.vue'
import FormTechDevis from './FormTechDevis.vue'

export default {
    components: {
        FactuAtelierTable,
        FormTechStartPc,
        FormTechDevis
    },
    props: {
        admin: {
            default: false
        },
        userIsAdmin: {
            default: false
        },
        station: {},
        allInOne: {
            default: 0
        },
        tableHeight: {}
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.getLines()
            }
        },
        admin: {
            handler() {
                this.init()
            }
        }
    },
    data() {
        return {
            currentFoldersDefault: null,
            currentFolders: null,
            waitingFolders: null,
            loadingGetDossier: false,

            dossiersEncours: [],

            filters: {},
            intervenants: [],
            donneurOrdres: [],
            timerGetLines: null,

            selectedLine: null,
            selectedLineDossier: null,

            avancements: [],

            staCategs: [],

            nbDossierMax: null,

            selectedFolder: null,
            changeTechId: null,
            loadingChangeTech: false,

            filterTypeDossier: 'Tout les dossiers',
        }
    },
    methods: {
        async init() {
            this.loadingGetDossier = true
            await this.getNbDossiersMax()
            await this.getFolders(-1, true)
            await this.getStaCategs()

            if (this.admin) {
                this.getIntervenants()
                this.getAvancements()
                // this.getDonneurOrdres()
            }
        },

        async getLines() {
            clearTimeout(this.timerGetLines)
            this.timerGetLines = setTimeout(() => {
                this.getFolders(1)
                this.getFolders(0)
            }, 500)
        },

        async getAvancements() {
            let response = await this.$request.post('technicien.avancement.list', {
                etats: JSON.stringify(['en_attente', 'en_cours'])
            })
            this.avancements = response.data.posts.post
        },

        async getFolders(encours, loading = false) {            
            // Manage data
            let endData = {
                en_cours: encours,
                admin: this.admin ? 1 : 0,
            }
            if (this.filters.intervenant) {
                endData.intervenant_id = this.filters.intervenant
            }
            if (this.filters.etat_avancement) {
                endData.codeanos = JSON.stringify(this.filters.etat_avancement)
            }
            if (this.filters.nom_do && this.filters.nom_do !== undefined) {
                endData.nom_do = this.filters.nom_do
            }
            if (this.filters.date && this.filters.date !== undefined) {
                endData.date = this.filters.date
            }
            if (this.allInOne) {
                endData.all_in_one = 1
            }
            // Send request
            if (loading) {
                this.loadingGetDossier = true
            }
            this.loadingGetDossier = true
            let response = await this.$request.post('technicien.get-dossier-atelier', endData)
            this.loadingGetDossier = false

            // Overwrite response
            if (this.allInOne) {
                this.currentFolders = response.data.posts.post
                
                this.currentFoldersDefault = JSON.parse(JSON.stringify(this.currentFolders))
            } else {
                if (encours == 1 || encours == -1) {
                    this.currentFolders = Object.keys(response.data.posts.post.en_cours).map(
                                            function(k) {
                                                return response.data.posts.post.en_cours[k]
                                            })
                    this.currentFoldersDefault = JSON.parse(JSON.stringify(this.currentFolders))
                }
                if (encours == 0 || encours == -1) {
                    this.waitingFolders = Object.keys(response.data.posts.post.waiting).map(
                                            function(k) {
                                                return response.data.posts.post.waiting[k]
                                            }).sort(function(a, b) {
                                                var dateA = new Date(a.date_create).getTime();
                                                var dateB = new Date(b.date_create).getTime();
                                                return dateA < dateB ? -1 : 1;
                                            }
                                        )
                }
            }
        },

        async getNbDossiersMax() {
            this.nbDossierMax = await this.$request.send('technicien.get-nb-dossier-max')            
        },

        async getStaCategs() {
            this.staCategs = await this.$request.send('technicien.get-categ-sta-tech')
        },
        
        async getEncours() {
            let response = await this.$request.post('technicien.encours-atelier')
            this.dossiersEncours = response.data.posts.post
        },

        async getIntervenants() {
            let response = await this.$request.post('technicien.get-techniciens-list')
            this.intervenants = response.data.posts.post
        },

        // async getDonneurOrdres() {
        //     let response = await this.$request.post('technicien.get-donneur-ordre-list')
        //     this.donneurOrdres = response.data.posts.post
        // },

        selectFolder(line) {
            this.selectedLineDossier = line            
            
            this.$nextTick(() => {
                this.$modal.show('modal-tech-devis')
            })
        },

        startFolder(line) {
            this.selectedLine = line
            
            let techDossiers = this.currentFolders.filter((item) => item.id_tech == this.$request.user().account.id_entite)
            
            if (techDossiers && techDossiers.length > 0) {
                if (this.nbDossierMax && this.nbDossierMax !== null && this.nbDossierMax != 0 && techDossiers.length + 1 > this.nbDossierMax) {
                    this.$toast.error(this.$tt('Vous ne pouvez pas prendre plus de ' + this.nbDossierMax + ' dossiers'))
                    return
                }
            }
            

            this.$nextTick(() => {
                this.$modal.show('modal-start-pc')
            })
        },

        goToStartedFolder() {
            this.selectedLineDossier = this.selectedLine
            this.$nextTick(() => {
                this.$modal.hide('modal-start-pc')
                this.$modal.show('modal-tech-devis')
            })
            this.getFolders(1)
            this.getFolders(0)
        },

        refreshList() {            
            this.getFolders(1)
        },

        filterDossier() {
            if (this.filterTypeDossier == 'Tout les dossiers') {
                this.currentFolders = this.currentFoldersDefault
            }
            if (this.filterTypeDossier == 'Particuliers') {                
                this.currentFolders = this.currentFoldersDefault.filter((item) => item.garantie_four.garantie == 'hors_garantie')
            }
            if (this.filterTypeDossier == 'Professionnels') {                
                this.currentFolders = this.currentFoldersDefault.filter((item) => item.garantie_four.garantie != 'hors_garantie')
            }
        },

        async changeTech() {
            if (!this.changeTechId) {
                this.$toast.error(this.$tt('Veuillez sélectionner un technicien'))
                return
            }

            this.loadingChangeTech = true
            let response = await this.$request.send('technicien.change-tech', {
                id_dossier: this.selectedFolder.id_dossiersav,
                id_tech: this.changeTechId
            })
            this.loadingChangeTech = false

            if (response == 1) {
                this.selectedFolder = null
                this.$toast.success(this.$tt('Technicien changé avec succès'))
                this.init()
                this.$modal.hide('modal-change-tech')
            } else {
                this.$toast.error(this.$tt('Erreur lors du changement de technicien'))
            }
        }
    },
    mounted () {
        this.init()

        // Tracker
        if (location.hostname != "localhost" && location.hostname != "127.0.0.1") {
            window.LogRocket && window.LogRocket.init('omg4ub/districloud-1qc5x');
        }
    },
}
</script>

<style lang="scss" scoped>

</style>