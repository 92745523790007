<template>
    <div>
        <!-- Add liaison -->
        <modal name="modal-add-liaison" height="auto" :scrollable="true">
            <div v-if="selectedLineAdd" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center border-b pb-4 mb-2 justify-between">
                    <h2 class="text-xl font-bold">{{$tt('Liaison')}}</h2>
                    <button class="cursor-pointer" @click="$modal.hide('modal-add-liaison')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <template v-if="!liaisonIsMade">
                    <div class="p-4 my-4 text-sm text-yellow-800 rounded-lg bg-yellow-200" role="alert">
                        <span class="font-medium">{{$tt('Attention')}}:</span> {{$tt('Des frais s\'appliqueront sur votre prochaine facture selon votre abonnement')}}
                    </div>

                    <div class="bg-slate-100 rounded p-4">
                        <div>
                            <div class="text-sm font-light">{{$tt('Liaison')}}</div><div class="text-sm font-bold">{{ selectedLineAdd.label }}</div>
                        </div>
                        <div class="mb-2 border-t border-slate-300 pt-2 mt-4">
                            <div class="text-sm font-light">{{$tt('Prix')}}</div><div class="text-sm font-bold">{{$tt('Selon votre abonnement')}}</div>
                        </div>
                    </div>
                    <div class="flex items-center justify-between gap-2 mt-5">
                        <div class="text-sm font-light flex items-center">
                            <input-rule v-model="acceptCondition" type="checkbox" class="mt-1.5" id="accept-add-condition" />
                            {{$tt('J\'accepte les')}} <a class="text-blue-500 hover:underline ml-1" href="https://disticloudfiles.net/upload/files/CONDITIONS%20GENERALES%20SAVLOG.pdf">{{$tt('conditions générales de vente')}}</a>
                        </div>
                        <button @click="addLiaison" :disabled="loadingModal" class="bg-green-600 hover:bg-green-700 duration-200 text-white border-emerald-600 font-semibold cursor-pointer border rounded text-sm px-4 py-2">
                            <template v-if="!loadingModal">
                                <i class="fas fa-plus mr-0.5"></i> {{$tt('Créer la liaison')}}
                            </template>
                            <template v-if="loadingModal">
                                <spinner :size="10" />
                            </template>
                        </button>
                    </div>
                </template>
                <template v-if="liaisonIsMade">
                    <div class="p-4 my-4 text-sm text-green-800 rounded-lg bg-green-200" role="alert">
                        <div class="border-b border-green-600 pb-3 mb-3"><i class="fas fa-check mr-1"></i> {{$tt('Votre liaison est enregistrée')}}</div>
                        <span class="font-medium">{{$tt('Notes')}}:</span> {{$tt('Il est important de configurer vos procédures produits liées à cette société')}}
                    </div>
                    <div class="text-center">
                        <button @click="$modal.hide('modal-add-liaison')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 cursor-pointer rounded">{{$tt('Fermer')}} <i class="fas fa-times ml-1"></i></button>
                    </div>
                </template>
            </div>
        </modal>

        <!-- Edit liaison -->
        <modal name="modal-edit-liaison" height="auto" :scrollable="true">
            <div v-if="selectedLine" class="p-10" style="z-index: 999 !important;">
                <div class="flex items-center border-b pb-4 mb-2 justify-between">
                    <h2 class="text-xl font-bold">{{$tt('Liaison')}}</h2>
                    <button class="cursor-pointer" @click="$modal.hide('modal-edit-liaison')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <template v-if="type == 'fournisseur'">
                    <input-rule v-if="selectedLine.centrale" type="text" v-model="selectedLine.centrale.nom" :disabled="true" :label="$tt('Centrale d\'achat')" :label-inline="false" class="mb-4" />
                </template>
                <template v-if="type == 'distributeur'">
                    <input-rule type="text" v-model="selectedLine.grossiste_nom" :disabled="true" :label="$tt('Centrale d\'achat')" :label-inline="false" class="mb-4" />
                </template>
                <input-rule type="text" v-model="selectedLine.code_compta_ext" :label="$tt('Code compta')" :label-inline="false" class="mb-4" />
                <button :disabled="loadingModal" @click="updateLiaison" class="mx-auto block bg-blue-600 hover:bg-blue-700 duration-200 text-white border-emerald-600 font-semibold cursor-pointer border rounded text-sm mt-4 px-4 py-2">
                    <template v-if="!loadingModal">
                        <i class="fas fa-check mr-0.5"></i> {{$tt('Modifier')}}
                    </template>
                    <template v-if="loadingModal">
                        <spinner :size="10" />
                    </template>
                </button>
            </div>
        </modal>

        <!-- Table -->
        <data-table
        v-if="buildTable"
        id="table-liaisons"
        :server="true"
        :filters="[
        ]"
        :columns="buildTable.columns"
        :request="{
            name: buildTable.operation,
            params: {
                target_id: this.entiteId,
                page: 0,
                per_page: connected ? 50 : 200,
                connected: connected,
            },
            apiName: 'api2'
        }"
        >
            <template v-slot:actions='{line}'>
                <div class="flex items-center">
                    <template v-if="connected">
                        <button @click="selectedLine = {...line}; $modal.show('modal-edit-liaison')" class="mr-1 text-xs bg-blue-500 hover:bg-blue-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                            <i class="fas fa-pen"></i>
                        </button>
                        <btn-confirm-delete :label="type == 'fournisseur' ? line.centrale?.nom : line.grossiste?.nom" @click="deleteLiaison" :meta="line" />
                    </template>
                    <template v-if="!connected">
                        <button @click="liaisonIsMade = false; selectedLineAdd = {...line}; $modal.show('modal-add-liaison')" class="mr-1 text-xs bg-green-500 hover:bg-green-600 duration-200 cursor-pointer font-light px-3 py-1.5 rounded text-white">
                            <i class="fas fa-plus mr-0.5"></i> {{$tt('Se connecter')}}
                        </button>
                    </template>
                </div>
            </template>
        </data-table>
    </div>
</template>

<script>
export default {
    props: {
        type: {required: true},
        entiteId: {required: true},
        connected: {default: false}
    },
    watch: {
        entiteId() {
            this.$nextTick(() => {
                this.$eventHub.$emit('refresh-lines', 'table-liaisons')
            })
        },
        type() {
            this.$nextTick(() => {
                this.$eventHub.$emit('refresh-lines', 'table-liaisons')
            })
        }
    },
    data() {
        return {
            selectedLine: null,
            selectedLineAdd: null,
            loadingModal: false,
            acceptCondition: false,
            liaisonIsMade: false,
        }
    },
    computed: {
        buildTable() {
            let columns = []
            let operation = ''
            if (this.type == 'fournisseur') {
                operation = 'grossiste.get-liaisons-admin'
                if (this.connected) {
                    columns = [
                        {label: this.$tt('Nom'), key: 'label', type: 'text'},
                        {label: this.$tt('Code compta int'), key: 'code_compta_ext', type: 'text'},
                    ]
                } else {
                    columns = [
                        {label: this.$tt('Nom'), key: 'label', type: 'text'},
                    ]
                }
            }
            if (this.type == 'distributeur') {
                operation = 'centrale.get-liaisons-admin'
                if (this.connected) {
                    columns = [
                        {label: this.$tt('Nom'), key: 'label', type: 'text'},
                        {label: this.$tt('Code compta int'), key: 'code_compta_ext', type: 'text'},
                    ]
                } else {
                    columns = [
                        {label: this.$tt('Nom'), key: 'label', type: 'text'},
                    ]
                }
            }
            return {
                operation: operation,
                columns: columns
            }
        }
    },
    methods: {
        async addLiaison() {
            if (!this.acceptCondition) {
                this.$toast.error(this.$tt('Vous devez accepter les conditions générales de vente'))
                return
            }
            this.loadingModal = true
            await this.$request.post('liaison.add', {
                target_id: this.selectedLineAdd.id
            })
            this.$nextTick(() => {
                this.$eventHub.$emit('refresh-lines', 'table-liaisons')
            })
            this.liaisonIsMade = true
            this.loadingModal = false
        },

        async updateLiaison() {
            this.loadingModal = true
            await this.$request.post('liaison.update', {
                liaison_id: this.selectedLine.id,
                code_compta_ext: this.selectedLine.code_compta_ext || ''
            })
            this.$nextTick(() => {
                this.$eventHub.$emit('refresh-lines', 'table-liaisons')
            })
            this.$modal.hide('modal-edit-liaison')
            this.loadingModal = false
        },

        async deleteLiaison(line) {
            let response = await this.$request.post('liaison.delete', {
                line_id: line.id
            })
            if (response.data.posts.post == 1) {
                this.$toast.success(this.$tt('Supprimé'))
            }
            this.$eventHub.$emit('refresh-lines', 'table-liaisons')
        }
    },
}
</script>

<style lang="scss" scoped>

</style>