<template>
    <div>
        <modal :name="'modal-edit-add-proc-' + _uid" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">
                        <template v-if="selectedProcedureEdit && selectedProcedureEdit.id">{{$tt('Modifier une procédure')}}</template>
                        <template v-else>{{$tt('Ajouter une procédure')}}</template>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-proc-' + _uid)">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div>
                    <form-procedure
                    @update="updatedPattern"
                    :procedure="selectedProcedureEdit"
                    :space="space"
                    />
                </div>
            </div>
        </modal>

        <modal :name="'confirm-delete-pattern-' + _uid" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedProcedureEdit">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Confirmer la suppression')}}</h2>
                    <button @click="$modal.hide('confirm-delete-pattern-' + _uid)">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div class="bg-slate-100 rounded p-4 text-lg">{{$tt('Souhaitez-vous réellement supprimer la procédure')}} <b class="underline">{{ selectedProcedureEdit.type_garantie | typeGarantieLabel }}</b> ?</div>
                <div class="flex gap-2">
                    <button @click="$modal.hide('confirm-delete-pattern-' + _uid)" class="px-4 py-2 text-sm font-bold bg-slate-100 hover:bg-slate-200 duration-200 rounded mt-4">Annuler</button>
                    <button :disabled="loadingDelete" @click="deletePattern" class="px-4 py-2 text-sm font-bold bg-red-100 hover:bg-red-200 duration-200 text-red-900 rounded mt-4">
                        <spinner :size="10" v-if="loadingDelete" />
                        <template v-if="!loadingDelete">{{$tt('Supprimer')}}</template>
                    </button>
                </div>
            </div>
        </modal>

        <div class="relative overflow-x-auto">
            <button v-if="!readOnly && canCreate" @click="selectedProcedureEdit = {}; $modal.show('modal-edit-add-proc-' + _uid)" class="px-4 py-2 bg-slate-500 text-white hover:bg-slate-600 duration-200 rounded text-xs mb-4"><i class="fas fa-plus mr-0.5"></i> {{$tt('Créer une procédure')}}</button>

            <table class="border w-full text-sm text-left rtl:text-right text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                        <th v-if="activation"></th>
                        <th v-if="!readOnly"></th>
                        <th v-if="!readOnly" scope="col" class="px-4 py-2.5">{{$tt('Actions')}}</th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Espace')}}
                        </th>
                        <th v-if="displayCentrale" scope="col" class="px-4 py-2.5">
                            {{$tt('Centrale')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Garantie')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Durée')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5 min-w-[250px]">
                            {{$tt('Procédure')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Réparateur')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Transporteur Aller')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Transporteur Retour')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Créé le')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Modifié le')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Matricule')}}
                        </th>
                        <th scope="col" class="px-4 py-2.5">
                            {{$tt('Date fin')}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="listProcedures.length <= 0">
                        <td class="px-4 py-3 text-center" colspan="11">
                            {{$tt('Aucune procédure')}}
                        </td>
                    </tr>
                    <template v-for="(procedure, procedureIndex) in listProcedures">
                        <tr @click="openProcedure(procedure)" :key="'procedure-' + procedureIndex" class="cursor-pointer duration-200" :class="(selectedConfig && selectedConfig.pattern_id == procedure.id && !procedure.open ? 'bg-green-200' : '') + ' ' + (procedure.open ? 'border-2 border-emerald-300 bg-emerald-50' : 'border-b') + ' ' + (!readOnly && (!selectedConfig || selectedConfig.pattern_id != procedure.id) ? 'hover:bg-emerald-100 duration-200' : '') + ' ' + (procedure.actif || procedure.actif == undefined ? 'opacity-100' : 'opacity-50')">
                            <td @click.stop="" v-if="activation" class="px-4 py-2">
                                <input-rule v-model="procedure.actif" type="pcheck" />
                            </td>
                            <td v-if="!readOnly" class="px-4 py-2">
                                <!-- <button v-if="!selectedProcedure || selectedProcedure.id != procedure.id" class="rounded font-light" :class="selectedProcedure && selectedProcedure.id == procedure.id ? 'text-green-500 text-lg' : 'bg-blue-200 hover:bg-blue-300 duration-200 px-2 py-1'" @click="selectProcedure(procedure)">
                                    Sélectionner
                                </button> -->
                                <button
                                @mouseover="iconChecked = 'fa-minus text-red-500'"
                                @mouseleave="iconChecked = 'fa-check text-green-500'"
                                v-if="selectedProcedure && selectedProcedure.id == procedure.id"
                                class="rounded font-light text-lg"
                                @click="unselectProcedure">
                                    <i class="fas" :class="iconChecked"></i>
                                </button>
                            </td>
                            <td v-if="!readOnly" class="px-4 py-2">
                                <button class="opacity-70 hover:opacity-100 duration-200 cursor-pointer mr-2" @click.stop="selectedProcedureEdit = procedure; $modal.show('modal-edit-add-proc-' + _uid)"><i class="fas fa-pen text-blue-500"></i></button>
                                <button class="opacity-70 hover:opacity-100 duration-200 cursor-pointer mr-2" @click.stop="selectedProcedureEdit = procedure; $modal.show('confirm-delete-pattern-' + _uid)"><i class="fas fa-trash text-red-500"></i></button>
                            </td>
                            <td class="px-4 py-2">
                                <span v-if="!procedure.selfcare_proc" class="px-2 py-1 text-xs bg-slate-200 text-slate-800 rounded">Magasin</span>
                                <span v-if="procedure.selfcare_proc" class="px-2 py-1 text-xs bg-yellow-200 text-yellow-800 rounded">Selfcare</span>
                            </td>
                            <td v-if="displayCentrale" class="px-4 py-2">
                                {{ procedure.centrale?.centrale_ha || '-' }}
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.type_garantie | typeGarantieLabel }}
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.garantie_mois }}
                            </td>
                            <td class="px-4 py-2 min-w-[250px]">
                                <template v-if="procedure.workflow?.descriptif">
                                    <button @click.stop="selectedWorflowInfo = procedure.workflow; $modal.show('modal-descriptif-workflow-table-' + _uid)" class="text-slate-600 hover:text-slate-800 duration-200 cursor-pointer"><i class="fas fa-info-circle mr-1"></i></button>
                                    {{ procedure.workflow.descriptif }}
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.station?.nom || '-' }}
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.transporteur_aller?.transporteur || '-' }}
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.transporteur_retour?.transporteur || '-' }}
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.date_create | momentDate }}
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.date_modif | momentDate }}
                            </td>
                            <td class="px-4 py-2">
                                <template v-if="procedure && procedure.user && procedure.user.email">
                                    {{ procedure.user.email }}
                                </template>
                                <template v-else>-</template>
                            </td>
                            <td class="px-4 py-2">
                                {{ procedure.date_fin_prod | momentDate }}
                            </td>
                        </tr>
                        <!-- Configs -->
                        <tr v-if="procedure.open" :key="'procedure-config-' + procedureIndex">
                            <td colspan="11" class="px-4">
                                <div class="flex">
                                    <div class="flex">
                                        <div class="border-l-2 h-[50%] border-emerald-300"></div>
                                        <div class="border-b-2 h-[50%] border-emerald-300 w-[20px]"></div>
                                    </div>
                                    <div class="mt-4 mb-6 border-2 border-emerald-300 rounded relative overflow-x-auto overflow-y-auto max-h-[800px] w-full">
                                        <table v-if="procedure.configs && procedure.configs.length > 0" class="w-full text-sm text-left rtl:text-right text-gray-500">
                                            <thead class="text-xs text-emerald-700 uppercase">
                                                <tr>
                                                    <th class="px-4 py-1 bg-emerald-300"></th>
                                                    <th v-for="(configKey, configKeyIndex) in procedure.configs_keys" :key="'config-key-' + configKeyIndex" scope="col" class="px-4 py-1 bg-emerald-300 text-emerald-900">
                                                        {{ $tt(tableConfigKeys.find((item) => item.key == configKey)?.label) }}
                                                    </th>
                                                    <th v-if="!readOnly" class="px-4 py-1 bg-emerald-300">{{$tt('Supprimer')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(config, configIndex) in procedure.configs" :key="'proc-config-' + configIndex" class="bg-white border-b" :class="selectedConfig && selectedConfig.id == config.id ? 'bg-emerald-200 duration-200' : 'bg-emerald-50'" @click="toggleConfig(config)">
                                                    <td class="px-4 py-2">
                                                        <template v-if="!readOnly">
                                                            <div class="w-[15px] h-[15px] text-white duration-200 rounded-full text-xs border-2 border-slate-400" :class="selectedConfig && selectedConfig.id == config.id ? 'bg-emerald-400 hover:bg-emerald-500' : 'bg-white hover:bg-slate-100'"></div>
                                                        </template>
                                                    </td>
                                                    <td v-for="(configKey, configKeyIndex) in procedure.configs_keys" :key="'proc-config-cell-' + configKeyIndex" class="px-4 py-2 text-emerald-800">
                                                        <div v-if="config[configKey] == 2" class="text-green-600">
                                                            <i class="fas fa-check"></i>
                                                            <!-- <span class="text-xs ml-1">Obligatoire</span> -->
                                                        </div>
                                                        <div v-if="config[configKey] == 1" class="text-orange-500">
                                                            <i class="fas fa-asterisk"></i>
                                                            <!-- <span class="text-xs ml-1">Optionnel</span> -->
                                                        </div>
                                                        <span v-if="config[configKey] == 0">-</span>
                                                    </td>
                                                    <td v-if="!readOnly" class="px-4 py-2 text-center">
                                                        <button @click.stop="deleteConfig(procedure, config)" class="text-red-400 hover:text-red-500 duration-200 rounded text-sm"><i class="fas fa-trash"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="bg-slate-100 p-2 m-2 mb-0 roudned" v-if="!procedure.configs || procedure.configs.length <= 0">{{ $tt('Aucune configuration') }}</div>
                                        <div class="py-2 px-4 flex items-center gap-10 justify-between">
                                            <button v-if="!readOnly" @click="selectedProcConfig = procedure; initFormConfig(); $modal.show('modal-add-config-' + _uid)" class="px-3 py-1.5 bg-slate-300 hover:bg-slate-400 hover:text-white duration-200 rounded text-xs"><i class="fas fa-plus"></i> {{$tt('Nouvelle configuration')}}</button>
                                            <div v-if="procedure.configs && procedure.configs.length > 0" class="flex gap-10">
                                                <div class="text-green-600"><i class="fas fa-check mr-1"></i> {{$tt('Obligatoire')}}</div>
                                                <div class="text-orange-500"><i class="fas fa-asterisk mr-1"></i> {{$tt('Optionnel')}}</div>
                                                <div class="">- {{$tt('Ne pas demander')}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>

        <modal :name="'modal-descriptif-workflow-table-' + _uid" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-5 mb-5">
                    <h2 class="font-light">{{$tt('Description du workflow')}}</h2>
                </div>
                <div v-if="selectedWorflowInfo">
                    <h2 class="text-xl font-bold">{{ selectedWorflowInfo.descriptif }}</h2>
                    <p class="bg-slate-100 p-4 rounded mt-4 font-light">{{ selectedWorflowInfo.detail }}</p>
                </div>
                <div class="mt-4 text-center">
                    <button class="px-4 py-2 text-sm font-bold bg-red-500 hover:bg-red-600 duration-200 rounded text-white" @click="$modal.hide('modal-descriptif-workflow-table-' + _uid)">{{$tt('Fermer')}}</button>
                </div>
            </div>
        </modal>

        <modal :name="'modal-add-config-' + _uid" height="auto" width="700px" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between border-b pb-3 mb-3">
                    <h2 class="text-xl font-bold">{{$tt('Ajouter une configuration')}}</h2>
                    <button @click="$modal.hide('modal-add-config-' + _uid)">
                        <i class="fas fa-times text-red-500 text-lg"></i>
                    </button>
                </div>
                <div v-if="selectedProcConfig">
                    <div class="mt-4 mb-6 rounded relative overflow-x-auto overflow-y-auto max-h-[800px] w-full">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                            <thead>
                                <tr>
                                    <th class="px-4 py-2 font-semibold text-sm">{{$tt('Champ')}}</th>
                                    <th class="px-4 py-2 font-semibold text-sm text-center">{{$tt('Ne pas demander')}}</th>
                                    <th class="px-4 py-2 font-semibold text-sm text-center">{{$tt('Champ optionnel')}}</th>
                                    <th class="px-4 py-2 font-semibold text-sm text-center">{{$tt('Champ obligatoire')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(formField, formFieldIndex) in formFields" :key="'form-field-' + formFieldIndex" class="hover:bg-slate-100 duration-200">
                                    <td class="px-4 py-1">{{ formField.label }}</td>
                                    <td class="px-4 py-1 text-center"><input v-model="formConfig[formField.key]" type="radio" value="0" class="accent-emerald-600" /></td>
                                    <td class="px-4 py-1 text-center"><input v-model="formConfig[formField.key]" type="radio" value="1" class="accent-emerald-600" /></td>
                                    <td class="px-4 py-1 text-center"><input v-model="formConfig[formField.key]" type="radio" value="2" class="accent-emerald-600" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!-- <div v-for="(formField, formFieldIndex) in formFields" :key="'form-field-' + formFieldIndex" class="flex gap-4 items-center hover:bg-gray-200 duration-200 px-2 py-1 rounded">
                        <div class="text-xs font-bold text-gray-900">{{ formField.label }}</div>
                        <div class="grow border-b border-dashed"></div>
                        <input-rule :disabled="readOnly" v-model="formConfig[formField.key]" type="select" :options="{values: [{label: 'Ne pas demander', value: 0}, {label: 'Champ optionnel', value: 1}, {label: 'Champ obligatoire', value: 2}], label: 'label', key: 'value'}" custom="text-xs" />
                    </div> -->
                    <div class="text-center mt-4">
                        <button @click="addConfigOnProc" class="px-4 py-2 bg-green-500 text-white rounded text-sm"><i class="fas fa-check mr-0.5"></i> {{$tt('Ajouter')}}</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
import moment from 'moment'
import FormProcedure from './FormProcedure.vue'

export default {
    props: {
        procedures: {required: true},
        readOnly: {default: false},
        canCreate: {default: true},
        canCreateConfig: {default: true},
        selectedId: {},
        displayCentrale: {default: false},
        space: {},
        activation: {default: false}
    },
    components: {
        FormProcedure,
    },
    watch: {
        procedures() {
            this.initList()
        },
        selectedConfig: {
            deep: true,
            handler() {
                this.$emit('select', this.selectedConfig)
            }
        },
        selectedId() {
            this.selectDefaultConf()
        },
        listProcedures: {
            deep: true,
            handler(value) {
                this.$emit('on-change', value)
            }
        }
    },
    data() {
        return {
            listProcedures: [],
            selectedProcedure: null,
            selectedProcedureEdit: null,
            selectedWorflowInfo: null,
            loadingDelete: false,
            iconChecked: 'fa-check text-green-500',
            selectedProcConfig: null,
            formConfig: {},
            selectedConfig: null,
            formFields: [
                {label: 'N. de facture', key: 'facture_oblig'},
                {label: 'Client', key: 'client_oblig'},
                {label: 'Numéro de série', key: 'numserie_oblig'},
                {label: 'Date de fabrication', key: 'date_fab'},
                {label: 'Numéro accord', key: 'num_accord'},
                {label: 'Envoie plaque signalétique', key: 'plaque'},
                {label: 'Destruction', key: 'destruction'},
                {label: 'Justificatif d\'achat', key: 'justif_oblig'},
                {label: 'Code panne', key: 'codepanne_oblig'},
                {label: 'Photo de la panne', key: 'photo'},
                {label: 'IMEI', key: 'imei'},
                {label: 'Photo plaque signalétique', key: 'photo_plaque'},
                {label: 'Date de dépôt', key: 'date_demande'},
                {label: 'Gestion de l\'emballage', key: 'emballage'},
            ],
            tableConfigKeys: [
                {label: 'N. de facture', key: 'facture_oblig'},
                {label: 'Client', key: 'client_oblig'},
                {label: 'Numéro de série', key: 'num_serie_oblig'},
                {label: 'Date de fabrication', key: 'date_fab_oblig'},
                {label: 'Numéro accord', key: 'num_accord'},
                {label: 'Envoie plaque signalétique', key: 'plaque'},
                {label: 'Destruction', key: 'destruction'},
                {label: 'Numéro de facture', key: 'num_fact_oblig'},
                {label: 'Saisie client', key: 'saisie_client_oblig'},
                {label: 'Destruction', key: 'destruction'},
                {label: 'Justificatif d\'achat', key: 'justif_oblig'},
                {label: 'Code panne', key: 'code_panne_oblig'},
                {label: 'Photo de la panne', key: 'photo_oblig'},
                {label: 'IMEI', key: 'imei_oblig'},
                {label: 'Photo plaque signalétique', key: 'envoi_plaque'},
                {label: 'Photo plaque signalétique', key: 'photo_plaque'},
                {label: 'Date de dépôt', key: 'date_demande'},
                {label: 'Gestion de l\'emballage', key: 'emballage'}
            ],
        }
    },
    filters: {
        momentDate(date) {
            if (date && moment(date).isValid()) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date && moment(date).isValid()) {
                return moment(date).lang('fr').format('DD/MM/YYYY HH:mm:ss')
            }
            return '-'
        },
        typeGarantieLabel(label) {
            let labels = {
                'garantie_marque': 'Garantie Marque',
                'garantie_distributeur': 'Garantie Distributeur',
                'garantie_pms': 'Garantie PMS',
                'garantie_comp': 'Garantie Complémentaire',
                'hors_garantie': 'Hors garantie',
            }
            return labels[label]
        }
    },
    methods: {
        initList() {
            let hasProcOpened = this.listProcedures.find((proc) => proc.open)
            this.listProcedures = this.procedures.map((item) => ({...item, open: false}))
            if (hasProcOpened) {
                this.listProcedures.find((item) => item.id == hasProcOpened.id).open = true
            }
        },
        openProcedure(procedure) {
            let state = !procedure.open
            this.listProcedures.forEach((item) => item.open = false)
            procedure.open = state
        },
        selectProcedure(procedure) {
            this.selectedProcedure = procedure
            // this.$emit('select', procedure)
        },
        unselectProcedure() {
            this.selectedProcedure = null
            // this.$emit('select', null)
        },
        updatedPattern() {
            this.$modal.hide('modal-edit-add-proc-' + this._uid)
            this.$emit('update-pattern')
        },
        async deletePattern() {
            this.loadingDelete = true
            let response = await this.$request.post('procedures.delete-pattern', {
                pattern_id: this.selectedProcedureEdit.id
            })
            this.loadingDelete = false
            if (response.data.posts.post == 1) {
                this.$modal.hide('confirm-delete-pattern-' + this._uid)
                this.$emit('update-pattern')
            }
        },

        initFormConfig() {
            this.formConfig = {
                facture_oblig: 0,
                client_oblig: 0,
                numserie_oblig: 0,
                date_fab: 0,
                num_accord: 0,
                plaque: 0,
                destruction: 0,
                justif_oblig: 0,
                codepanne_oblig: 0,
                photo: 0,
                imei: 0,
                photo_plaque: 0,
                date_demande: 0,
                emballage: 0,
            }
        },

        async addConfigOnProc() {
            for (let key in this.formConfig) {
                if (!this.formConfig[key] == undefined) {
                    this.formConfig[key] = 0
                }
            }
            this.formConfig.id_proc = this.selectedProcConfig.id
            let response = await this.$request.post('procedure.add-proc-config', this.formConfig)
            if (response.data.posts.post == 1) {
                this.$emit('refresh-proc')
                this.initFormConfig()
                this.$modal.hide('modal-add-config-' + this._uid)
            }
        },

        async deleteConfig(procedure, config) {
            let response = await this.$request.post('procedure.delete-config', {
                line_id: config.id
            })
            if (response.data.posts.post == 1) {
                let configIndex = procedure.configs.findIndex((item) => item.id == config.id)
                if (configIndex >= 0) {
                    procedure.configs.splice(configIndex, 1)
                }
            }
        },

        toggleConfig(config) {
            if (this.selectedConfig && this.selectedConfig.id == config.id) {
                this.selectedConfig = null
            } else {
                this.selectedConfig = config
            }
        },

        selectDefaultConf() {
            if (this.selectedId) {
                this.procedures.forEach((proc) => {
                    proc.configs.forEach((config) => {
                        if (this.selectedId == config.id) {
                            this.selectedConfig = config
                        }
                    })
                })
            } else {
                this.selectedConfig = null
            }
        },
    },
    mounted () {
        this.initList()
        this.initFormConfig()
        
        this.selectDefaultConf()
    },
}
</script>
