<template>
    <div>
        <!-- Supprimer un contact -->
        <modal name="modal-confirm-delete-contact" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedContact">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        {{ $tt('Confirmer la suppression') }}
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-contact')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    {{ "Confirmez-vous la suppression de " + selectedContact.nom + " ?" }}
                    <div class="flex items-center gap-2 mt-2">
                        <button @click="$modal.hide('modal-confirm-delete-contact')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 text-xs rounded-full">{{ $tt('Annuler') }}</button>
                        <button :disabled="loadingContact" @click="deleteContact" class="px-4 py-2 bg-red-400 hover:bg-red-500 duration-200 text-white text-xs rounded-full">
                            <spinner :size="10" v-if="loadingContact" />
                            <template v-if="!loadingContact">
                                {{ $tt('Supprimer') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modifier un contact -->
        <modal name="modal-edit-add-contact" height="auto" :scrollable="true">
            <div class="p-10" v-if="formContact">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <span v-if="!formContact.id">{{ $tt('Ajouter un contact') }}</span>
                        <span v-if="formContact.id">{{ $tt('Modifier un contact') }}</span>
                    </h2>
                    <button @click="$modal.hide('modal-edit-add-contact')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-if="!formContact.id" class="mb-2.5" v-model="formContact.poste" type="select" :options="{values: availableContactTypes}" :label="$tt('Poste')" :label-inline="false" id="inp-contact-poste" />
                    <input-rule class="mb-2.5" v-model="formContact.nom" type="text" :label="$tt('Nom')" :label-inline="false" id="inp-contact-nom" />
                    <input-rule class="mb-2.5" v-model="formContact.tel" type="text" :label="$tt('Téléphone')" :label-inline="false" id="inp-contact-tel" />
                    <input-rule class="mb-2.5" v-model="formContact.email" type="text" :label="$tt('Email')" :label-inline="false" id="inp-contact-email" />
                    <input-rule class="mb-2.5" v-model="formContact.info" type="textarea" :options="{rows: 3}" :label="$tt('Informations')" :label-inline="false" />
                    <div class="text-right">
                        <button :disabled="loadingContact" @click="editAddContact" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded duration-200 text-xs">
                            <spinner v-if="loadingContact" :size="10" />
                            <template v-if="!loadingContact">
                                <i class="fas fa-save mr-1"></i> {{ $tt('Enregistrer') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Edit agrement -->
        <modal name="modal-edit-add-agrements" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold"><i class="fas fa-plus mr-1"></i> {{$tt(' Ajouter un agrément de marque')}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-edit-add-agrements')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div class="min-h-[200px]" v-if="agrementForm">
                    <div class="mt-4">
                        <table class="w-full text-sm text-left rtl:text-right text-gray-500 mb-4 border">
                            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th class="px-6 py-3">
                                        {{$tt('Activité')}}
                                    </th>
                                    <th class="px-6 py-3">
                                        {{$tt('Agréé')}}
                                    </th>
                                    <th class="px-6 py-3" v-if="station.iad">
                                        {{$tt('Zone intervention à domicile')}}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(activite, activiteIndex) in station.activites" :key="'activite-' + activiteIndex" class="bg-white border-b hover:bg-slate-100 duration-200">
                                    <td class="px-6 py-2 font-semibold">
                                        {{ activite.libelle }}
                                    </td>
                                    <td class="px-6 py-2">
                                        <div class="bg-red-500 text-white rounded p-1 text-xs font-light" v-if="agrementForm.activites && agrementForm.activites[activite.id].agree == 2">{{ $tt('Refusé') }}</div>
                                        <template v-else>
                                            <input-rule type="pcheck" v-model="agrementForm.activites[activite.id].agree" @input="(data) => {if (data == 0) { agrementForm.activites[activite.id].zone = null }; $forceUpdate()}" :label-inline="false"/>
                                        </template>
                                    </td>
                                    <td class="px-6 py-2" v-if="station.iad">
                                        <input-rule v-model="agrementForm.activites[activite.id].zone" type="vselect" :options="{values: zones, label: 'nom', key: 'id'}" :label-inline="false" class="mb-4 duration-200" :class="agrementForm.activites[activite.id].agree == 0 ? 'opacity-30' : ''" :disabled="agrementForm.activites[activite.id].agree == 0" @input="$forceUpdate()" :placeholder="$tt('Optionnel')" />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button @click="editAgrements" :disabled="loadingEditAgrement" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-sm mb-10">
                        <spinner v-if="loadingEditAgrement" :size="10" />
                        <template v-if="!loadingEditAgrement">
                            {{ $tt('Enregistrer') }} <i class="fas fa-check ml-1"></i>
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal edit employe -->
        <modal name="modal-edit-employe" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedEmploye && data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <span>{{ selectedEmploye.nom }}</span>
                    </h2>
                    <button @click="$modal.hide('modal-edit-employe')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="bg-orange-200 p-4 rounded" v-if="validAgrements.length <= 0">
                        Aucun agrément avec {{ data.grossiste.nom }}
                    </div>
                    <div v-else>
                        <input-rule v-model="intervenantAgrementNb" type="text" :label-inline="false" :label="$tt('Numéro agrément du technicien')" />
                        <div class="mt-4">
                            <div class="text-xs font-bold mb-2">{{ $tt('Liste des agréments') }}</div>
                            <div class="flex items-center gap-3" v-for="(agrement, agrementIndex) in validAgrements" :key="'modal-edit-employe-agrement-' + agrementIndex">
                                <input-rule type="pcheck" v-model="intervenantAgrements[agrement.id]" :label-inline="false" />
                                <div class="font-bold text-sm">{{ agrement.libelle }}</div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <button @click="editTechAgrement" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded text-xs"><i class="fas fa-check mr-1"></i>{{ $tt('Enregistrer') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Modal edit facturation -->
        <modal name="modal-edit-facturation" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedFacturationLine && data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <span>{{ $tt('Modifier') }}</span>
                    </h2>
                    <button @click="$modal.hide('modal-edit-facturation')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <input-rule v-model="selectedFacturationLine.garantie" type="text" :label="$tt('Code garantie')" :label-inline="false" class="mb-2" :disabled="selectedFacturationLine.id ? true : false" />
                    <input-rule v-model="selectedFacturationLine.libelle" type="text" :label="$tt('Libellé')" :label-inline="false" class="mb-2" />
                    <input-rule v-model="selectedFacturationLine.nb_jour" type="number" :label="$tt('Nb jours')" :label-inline="false" class="mb-2" />
                    <div class="font-bold mt-4">{{ $tt('Facturation à la marque') }}</div>
                    <div class="grid grid-cols-4 gap-2 mt-3">
                        <input-rule v-model="selectedFacturationLine.pieces" type="checkbox" :label="$tt('Pièces')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.mo_sp" type="checkbox" :label="$tt('MO sans pièce')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.mo" type="checkbox" :label="$tt('MO')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.mo_iris" type="checkbox" :label="$tt('MO Iris')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.mo_call" type="checkbox" :label="$tt('MO Call')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.peage" type="checkbox" :label="$tt('Péage')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.dep" type="checkbox" :label="$tt('Déplacement')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.supp_dep" type="checkbox" :label="$tt('Supp déplacement')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.frais" type="checkbox" :label="$tt('Frais de gestion')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.pret" type="checkbox" :label="$tt('Prêt')" :label-inline="false" />
                        <input-rule v-model="selectedFacturationLine.encastre" type="checkbox" :label="$tt('Encastré')" :label-inline="false" />
                    </div>
                    <button :disabled="loadingEditFacturation" @click="editFacturationLine" class="mt-2 px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded text-xs">
                        <spinner :size="10" v-if="loadingEditFacturation" />
                        <template v-if="!loadingEditFacturation">
                            <i class="fas fa-check mr-1"></i>{{ $tt('Enregistrer') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <!-- Modal edit facturation -->
        <modal name="modal-confirm-delete-facturation" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedFacturationLine && data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <span>{{ $tt('Confirmer la suppression') }}</span>
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-facturation')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="font-bold text-lg mb-4">{{ $tt('Confirmez-vous la suppresion de la garantie') }}: {{selectedFacturationLine.libelle}} ?</div>
                    <div class="flex items-center gap-2">
                        <button @click="$modal.hide('modal-confirm-delete-facturation')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded text-sm">
                            <i class="fas fa-times mr-1"></i> {{ $tt('Annuler') }}
                        </button>
                        <button @click="deleteFacturationLine" class="px-4 py-2 bg-red-600 hover:bg-red-700 duration-200 text-white rounded text-sm">
                            <i class="fas fa-trash mr-1"></i> {{ $tt('Confirmer la suppression') }}
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        <!-- Categ -->
        <modal name="modal-edit-categ" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedCategLine && data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <span v-if="!selectedCategLine.id">{{ $tt('Ajouter') }}</span>
                        <span v-if="selectedCategLine.id">{{ $tt('Modifier') }}</span>
                    </h2>
                    <button @click="$modal.hide('modal-edit-categ')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="min-h-[500px]">
                        <template v-if="station.id_fournisseur_sta == 0">
                            <input-rule v-model="categLibelle" class="mb-4" type="text" :label="$tt('Libellé de votre catégorie de produit')" :label-inline="false" id="new-categ-libelle" />
                            <input-rule v-model="selectedEicta" type="select" :options="{values: eictas, label: 'fr'}" :label="$tt('Groupe EICTA')" :label-inline="false" @input="getDistriCategs" class="mb-2" id="new-categ-eicta" />
                            <div v-if="selectedEicta" class="ml-10 rounded p-4 border-2 border-slate-300 bg-slate-100 mb-4">
                                <input-rule v-model="groupCategDistri" type="select" v-if="selectedEicta && categDistris && Object.keys(categDistris) && Object.keys(categDistris).length > 0" :options="{values: Object.keys(categDistris)}" :label="$tt('Catégorie Ecosysteme')" :label-inline="false" class="mb-4" id="id-distri-group-categ" />
                                <input-rule v-model="categDistri" v-if="groupCategDistri" type="select" :options="{values: categDistris[groupCategDistri], label: 'nom', key: 'id'}" :label="$tt('Précision de la catégorie')" :label-inline="false" class="mb-4" id="id-distri-categ" />
                                <input-rule type="pcheck" v-model="categThermique" v-if="selectedEicta && categDistris && groupCategDistri && categDistris[groupCategDistri].find((item) => item.id == categDistri)?.thermique == 1" :label="$tt('Moteur thermique')" :label-inline="false" />
                            </div>
                        </template>
                        <input-rule v-model="categGarantieId" class="mb-4" type="select" :options="{values: data.facturations, label: 'libelle', key: 'id'}" :label="$tt('Garantie')" :label-inline="false" id="new-categ-garantie" />
                        <button :disabled="loadingEditAddCateg" @click="saveEditAddCateg" class="px-4 py-2 rounded font-bold bg-green-600 hover:bg-green-700 duration-200 mt-4 text-white">
                            <spinner :size="10" v-if="loadingEditAddCateg" />
                            <template v-if="!loadingEditAddCateg">
                                <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <!-- Tarif Categ -->
        <modal name="modal-edit-categ-tarif" height="auto" :width="station.iad ? '80%' : '40%'" :scrollable="true">
            <div class="p-10" v-if="selectedCategLine && data">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        <span>{{ $tt('Tarifs') }}</span>
                    </h2>
                    <button @click="$modal.hide('modal-edit-categ-tarif')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="grid gap-4" :class="station.iad ? 'grid-cols-2' : ''">
                        <div v-if="station.iad">
                            <div class="font-bold border border-b-0 rounded-t bg-slate-800 text-white text-sm p-2"><i class="fas fa-home mr-1"></i> {{ $tt('Intervention à domicile') }}</div>
                            <div class="p-4 border rounded-b">
                                <div class="grid grid-cols-2 gap-2 mb-2">
                                    <div class="flex items-end gap-2">
                                        <input-rule v-model="formTarif.deplacement_base" type="price" :label="$tt('Tarif forfait')" :label-inline="false" class="grow" :tooltip="$tt('Tarif forfait de base')" />
                                        <i class="fas fa-arrow-right mx-3 mb-3"></i>
                                    </div>
                                    <input-rule v-model="formTarif.km_ref" type="number" :label="$tt('Pour un rayon kilomètrique de') + ' ?'" :label-inline="false" :tooltip="$tt('Zone d\'intervention')" />
                                </div>
                                <input-rule v-model="formTarif.km_supp" type="price" :label="$tt('Tarif Km Supplémentaire')" :label-inline="false" class="mb-2" :tooltip="$tt('En cas de dépassement du rayon de base')" />
                                <input-rule v-model="formTarif.mo_ext" type="price" :label="$tt('MO - Avec pièce')" :label-inline="false" class="mb-2" :tooltip="$tt('Main d\'oeuvre facturée en cas d\'intervention avec pièce')" />
                                <input-rule v-model="formTarif.mo_sans_piece_iad" type="price" :label="$tt('MO - Sans pièce')" :label-inline="false" class="mb-2" :tooltip="$tt('Main d\'oeuvre facturée en cas d\'intervention sans pièce')" />
                                <input-rule v-model="formTarif.supp_enc" type="price" :label="$tt('Supplément encastré')" :label-inline="false" class="mb-2" :tooltip="$tt('Coût supplémentaire dans le cas où le produit nécessite un démontage')" />
                            </div>
                        </div>
                        <div>
                            <div class="font-bold border border-b-0 rounded-t bg-slate-800 text-white text-sm p-2"><i class="fas fa-hammer mr-1"></i> {{ $tt('Atelier') }}</div>
                            <div class="p-4 border rounded-b">
                                <input-rule v-model="formTarif.mo_atelier" type="price" :label="$tt('MO - Avec pièce')" :label-inline="false" class="mb-2" :tooltip="$tt('Main d\'oeuvre facturée en cas d\'intervention avec pièce')" />
                                <input-rule v-model="formTarif.mo_sans_piece" type="price" :label="$tt('MO - Sans pièce')" :label-inline="false" class="mb-2" :tooltip="$tt('Main d\'oeuvre facturée en cas d\'intervention sans pièce')" />
                                <input-rule v-model="formTarif.port_aller" type="price" :label="$tt('Frais de port aller')" :label-inline="false" class="mb-2" :tooltip="$tt('Frais de port pris en charge par la marque pour l\'envoie du produit vers la station')" />
                                <input-rule v-model="formTarif.port_retour" type="price" :label="$tt('Frais de port retour')" :label-inline="false" class="mb-2" :tooltip="$tt('Frais de port pris en charge par la marque pour le retour du produit vers le client')" />
                            </div>
                        </div>
                    </div>
                    <div class="mt-4" v-if="codePannes && codePannes.reparations || codePannes.sections">
                        <div class="font-bold border border-b-0 rounded-t bg-slate-800 text-white text-sm p-2"><i class="fas fa-plus mr-1"></i> {{ $tt('Supplément selon le code panne') }}</div>
                        <div class="p-4 border rounded-b">
                            <div class="max-h-[300px] overflow-y-auto">
                                <div v-for="(tarifMoException, tarifMoExceptionIndex) in tarifMoExceptions" :key="'tarif-mo-exception-' + tarifMoExceptionIndex" class="grid grid-cols-7 items-end gap-2 mb-2">
                                    <input-rule class="col-span-2" v-model="tarifMoException.reparation" type="select" :options="{values: codePannes.reparations, label: 'fr', key: 'code'}" :label="$tt('Code réparation')" :label-inline="false" />
                                    <input-rule class="col-span-2" v-model="tarifMoException.section" type="select" :options="{values: codePannes.sections, label: 'fr', key: 'code'}" :label="$tt('Code section')" :label-inline="false" />
                                    <input-rule class="col-span-2" v-model="tarifMoException.amount" type="price" :label="$tt('Prix MO supplémentaire')" :label-inline="false" />
                                    <div class="text-right">
                                        <button @click="tarifMoExceptions.splice(tarifMoExceptionIndex, 1)" class="px-2 py-1 bg-red-600 hover:bg-red-700 duration-200 text-white text-xs rounded"><i class="fas fa-trash"></i></button>
                                    </div>
                                </div>
                            </div>
                            <button @click="tarifMoExceptions.push({reparation: null, section: null, amount: null})" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 text-xs rounded"><i class="fas fa-plus"></i> {{$tt('Nouveau tarif')}}</button>
                        </div>
                    </div>
                    <div class="mt-4 text-center">
                        <button :disabled="loadingEditAddTarifCateg" @click="editAddTarifCateg" class="px-4 py-2 rounded font-bold bg-green-600 hover:bg-green-700 duration-200 mt-4 text-white">
                            <spinner :size="10" v-if="loadingEditAddTarifCateg" />
                            <template v-if="!loadingEditAddTarifCateg">
                                <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <div v-if="fournisseurs && fournisseurs.length <= 0" class="bg-slate-100 rounded-xl p-10 text-center">
            <div class="text-2xl font-bold">{{ $tt('Aucun fournisseur configuré sur votre compte') }}</div>
            <div>Vous pouvez configurer la liste de vos fournisseurs dans le menu "Administration -> Gestion des fournisseurs"</div>
        </div>

        <div v-if="fournisseurs && fournisseurs.length > 0" class="grid grid-cols-6 gap-10">
            <div>
                <div class="font-bold mb-2">{{ $tt('Fournisseurs référencés') }}</div>
                <div class="border rounded">
                    <div
                    class="p-2 text-sm font-light duration-200"
                    :class="(selectedFournisseur && selectedFournisseur.id == fournisseur.id ? '' : 'cursor-pointer hover:bg-slate-100') + ' ' + (fournisseurIndex > 0 ? 'border-t' : '')"
                    v-for="(fournisseur, fournisseurIndex) in fournisseurs"
                    :key="'fournisseur-' + fournisseurIndex"
                    @click="selectedFournisseur = fournisseur"
                    >
                        <div class="flex justify-between items-center">
                            <div :class="selectedFournisseur && selectedFournisseur.id == fournisseur.id ? 'font-bold' : ''">{{ fournisseur.nom }}</div>
                            <i v-if="!selectedFournisseur || selectedFournisseur.id != fournisseur.id" class="fas fa-chevron-right"></i>
                            <i v-if="selectedFournisseur && selectedFournisseur.id == fournisseur.id" class="fas fa-chevron-down"></i>
                        </div>
                        <div v-if="selectedFournisseur && selectedFournisseur.id == fournisseur.id" class="p-2">
                            <ul class="list-disc ml-6">
                                <li @click="selectedTab = 'reference'" class="hover:underline cursor-pointer" :class="selectedTab == 'reference' ? 'font-bold text-blue-600' : ''">{{$tt('Référence')}}</li>
                                <li @click="selectedTab = 'compta'" class="hover:underline cursor-pointer" :class="selectedTab == 'compta' ? 'font-bold text-blue-600' : ''">{{$tt('Comptabilité')}}</li>
                                <li @click="selectedTab = 'commande'" class="hover:underline cursor-pointer" :class="selectedTab == 'commande' ? 'font-bold text-blue-600' : ''">{{$tt('Commande')}}</li>
                                <li @click="selectedTab = 'garanties'" class="hover:underline cursor-pointer" :class="selectedTab == 'garanties' ? 'font-bold text-blue-600' : ''">{{$tt('Garanties')}}</li>
                                <li @click="selectedTab = 'categories'" class="hover:underline cursor-pointer" :class="selectedTab == 'categories' ? 'font-bold text-blue-600' : ''">{{$tt('Catégories')}}</li>
                                <li @click="selectedTab = 'intervenant'" class="hover:underline cursor-pointer" :class="selectedTab == 'intervenant' ? 'font-bold text-blue-600' : ''">{{$tt('Intervenants')}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-span-5">
                <div class="p-4" v-if="loadingGetData">
                    <spinner :size="10" />
                </div>
                <div v-if="data">
                    <div v-if="selectedTab == 'reference'">
                        <div class="grid grid-cols-3 gap-4">
                            <div>
                                <div class="mb-2">
                                    <div class="font-semibold bg-slate-700 text-white p-2.5 text-xs rounded-t"><i class="fa-solid fa-boxes-packing mr-0.5"></i> {{ $tt('Fournisseur') }}</div>
                                    <div class="rounded-b border p-3">
                                        <div class="font-bold">{{ data.grossiste.nom }}</div>
                                        <div>{{ data.grossiste.adresse1 + (data.grossiste.adresse2 ? ' ' + data.grossiste.adresse2 : '') + ', ' + data.grossiste.cp + ' ' + data.grossiste.ville + ', ' + data.grossiste.pays }}</div>
                                        <div class="text-sm font-light">
                                            <div v-if="data.grossiste.siret">SIRET: {{ data.grossiste.siret }}</div>
                                            <div v-if="data.grossiste.numintra">TVA: {{ data.grossiste.numintra }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <div class="font-semibold bg-slate-700 text-white p-2.5 text-xs rounded-t"><i class="fa-solid fa-tag mr-0.5"></i> {{ $tt('Agréments avec') + ' ' + data.grossiste.nom }}</div>
                                    <div class="rounded-b border p-3">
                                        <div class="text-right mb-3">
                                            <button @click="displayAgrementModal" class="px-4 py-2 rounded text-xs bg-slate-100 hover:bg-slate-200 duration-200"><i class="fas fa-pen mr-1"></i> {{ $tt('Modifier la liste') }}</button>
                                        </div>
                                        <table class="w-full">
                                            <tbody>
                                                <tr v-for="(activite, activiteIndex) in station.activites.filter((activiteFilter) => data.agrements.find((item) => item.id_activite == activiteFilter.id)?.agree)" :key="'activite-' + activiteIndex">
                                                    <td class="pb-2">
                                                        <div class="text-sm font-bold">{{ activite.libelle }}</div>
                                                        <div class="font-light" style="font-size: 11px">{{ activite.description }}</div>
                                                    </td>
                                                    <td class="pb-2">
                                                        <!-- Pas agréé -->
                                                        <template v-if="!data.agrements.find((item) => item.id_activite == activite.id).agree">-</template>
                                                        <!-- Agréé -->
                                                        <template v-if="data.agrements.find((item) => item.id_activite == activite.id).agree">
                                                            <div v-if="data.agrements.find((item) => item.id_activite == activite.id).agree == 1">
                                                                <!-- En cours de validation -->
                                                                <div v-if="data.agrements.find((item) => item.id_activite == activite.id).valid == 0" class="bg-slate-200 p-2 rounded">
                                                                    <div class="font-base text-xs">{{ $tt('Attente de validation par la marque') }}..</div>
                                                                    <div v-if="station.iad" class="text-xs font-light">{{ data.agrements.find((item) => item.id_activite == activite.id).zone_obj?.nom }}</div>
                                                                </div>
                                                                <!-- Validé -->
                                                                <div v-if="data.agrements.find((item) => item.id_activite == activite.id).valid == 1" class="bg-green-200 p-2 rounded">
                                                                    <div class="font-base text-xs"><i class="fas fa-check mr-0.5"></i> {{ $tt('Oui') }}</div>
                                                                    <div v-if="station.iad" class="text-xs font-light">{{ data.agrements.find((item) => item.id_activite == activite.id).zone_obj?.nom }}</div>
                                                                </div>
                                                            </div>
                                                            <!-- Refusé -->
                                                            <div v-if="data.agrements.find((item) => item.id_activite == activite.id).agree == 2" class="bg-red-200 p-2 rounded">
                                                                <div class="font-base text-xs"><i class="fas fa-times mr-0.5"></i> {{ $tt('Refusé par la marque') }}</div>
                                                                <div v-if="data.agrements.find((item) => item.id_activite == activite.id).motif_refus" class="text-xs font-light">{{ data.agrements.find((item) => item.id_activite == activite.id).motif_refus }}</div>
                                                            </div>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-span-2">
                                <div class="font-semibold bg-slate-700 text-white p-2.5 text-xs rounded-t"><i class="fas fa-users mr-0.5"></i> {{ $tt('Liste des contacts') }}</div>
                                <div class="border px-4 rounded-b">
                                    <div v-if="availableContactTypes.length > 0" class="py-2.5 text-right" :class="data.contacts.length > 0 ? 'border-b' : ''">
                                        <button class="text-xs px-4 py-2 bg-blue-200 hover:bg-blue-200 duration-200 rounded" @click="formContact = {}; $modal.show('modal-edit-add-contact')"><i class="fas fa-plus mr-1"></i> {{ $tt('Nouveau contact') }}</button>
                                    </div>
                                    <div v-for="(contact, contactIndex) in data.contacts" :key="'contact-' + contactIndex">
                                        <div class="flex items-center justify-between py-2.5" :class="contactIndex < data.contacts.length - 1 ? 'border-b' : ''">
                                            <div>
                                                <div class="flex items-center">
                                                    <i class="fas fa-user mr-2"></i>
                                                    <div class="flex items-center gap-2">
                                                        <div>
                                                            <div class="font-bold">{{ contact.nom }}</div>
                                                            <div v-if="contact.poste" class="text-xs -mt-0.5 text-blue-800">{{ contact.poste }}</div>
                                                        </div>
                                                        <div class="mt-1 flex gap-2 items-center">
                                                            <div v-if="contact.tel" class="text-xs bg-slate-100 rounded-full px-3 py-1">{{contact.tel}}</div>
                                                            <div v-if="contact.email" class="text-xs bg-slate-100 rounded-full px-3 py-1">{{ contact.email }}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="contact.info" class="font-italic text-xs font-light mt-1 bg-slate-100 rounded p-2"><i class="fas fa-comments mr-1"></i> {{ contact.info }}</div>
                                            </div>
                                            <div class="flex items-center gap-2">
                                                <button @click="formContact = contact; $modal.show('modal-edit-add-contact')" class="text-xs px-4 py-2 rounded-full bg-slate-200 hover:bg-slate-300 duration-200"><i class="fas fa-pen"></i></button>
                                                <button @click="selectedContact = contact; $modal.show('modal-confirm-delete-contact')" class="text-xs px-4 py-2 rounded-full bg-slate-200 hover:bg-slate-300 duration-200"><i class="fas fa-trash"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedTab == 'compta'">
                        <div class="font-semibold bg-slate-700 text-white p-2.5 text-xs rounded-t"><i class="fa-solid fa-calculator mr-0.5"></i> {{ $tt('Comptabilité') }}</div>
                        <div class="rounded-b border p-3 custom-inp">
                            <div class="grid grid-cols-4 gap-4">
                                <div class="border-r pr-4">
                                    <input-rule v-model="data.compte_client" class="mb-4" type="text" :label="$tt('Numéro client')" :label-inline="false" id="comptabilite-num_client" />
                                    <input-rule v-model="data.compte_comptable" type="text" :label="$tt('Code comptable')" :label-inline="false" />
                                </div>
                                <div class="border-r pr-4">
                                    <input-rule v-model="data.delai_paiement_facture" class="mb-4" type="number" :label="$tt('Délais de paiement des factures')" :tooltip="$tt('Nombre de jour fin de mois')" :label-inline="false" />
                                    <input-rule v-model="data.remise_piece" type="number" :label="$tt('Remise sur achat des pièces détachées') + ' (%)'" :label-inline="false" />
                                </div>
                                <div class="pr-4">
                                    <input-rule v-model="data.facture_bl" class="mb-4" type="select" :options="{values: [{label: 'Aucune', key: null}, {label: 'Facture', key: 'facture'}, {label: 'Bon de livraison', key: 'bl'}], key: 'key', label: 'label'}" :label="$tt('Remise déduite sur BL ou Factures')" :label-inline="false" id="comptabilite-facture_bl" />
                                    <input-rule v-model="data.livraison_bl" type="pcheck" :label="$tt('Livraison sur BL')" :label-inline="false" />
                                </div>
                                <div class="bg-slate-100 p-4">
                                    <input-rule v-model="data.coeff_sg" class="mb-4" type="number" :label="$tt('Coeff de refacturation des pièces SG')" :label-inline="false" />
                                    <!-- <input-rule v-model="data.coeff_hg" type="number" :label="$tt('Coeff min de refacturation des pièces HG')" :label-inline="false" :tooltip="$tt('Si vous appliquez un coeff sur la refacturation min, il sera prioritaire par rapport à la table des coefficients')" /> -->
                                </div>
                            </div>
                            <div class="border-t mt-2 pt-2">
                                <button @click="editCompta" class="px-4 py-2 text-xs font-bold bg-green-600 hover:bg-green-700 duration-200 rounded text-white">{{ $tt('Enregistrer') }}</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedTab == 'commande'">
                        <div class="font-semibold bg-slate-700 text-white p-2.5 text-xs rounded-t"><i class="fa-solid fa-truck mr-0.5"></i> {{ $tt('Commande') }}</div>
                        <table class="w-full">
                            <tbody>
                                <tr>
                                    <td class="px-4 py-2 border font-light text-sm w-[50%]">
                                        {{ $tt('Type de connexion') }}
                                    </td>
                                    <td class="px-4 py-2 border">
                                        <span class="bg-slate-200 rounded px-3 py-1 text-sm">
                                            <span v-if="data.grossiste.cmd_type == 'API'">API</span>
                                            <span v-else-if="data.grossiste.cmd_type == 'MAIL'">MAIL</span>
                                            <span v-else-if="data.grossiste.cmd_type == 'SMTP'">SMTP</span>
                                            <span v-else-if="data.grossiste.cmd_type == 'FICHIER'">FICHIER</span>
                                            <span v-else-if="data.grossiste.cmd_type == 'DISTRICLOUD'">DISTRICLOUD</span>
                                            <span v-else>Aucune</span>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-4 py-2 border font-light text-sm w-[50%]">
                                        {{ $tt('Commande Sous Garantie obligatoire chez le fournisseur') }}
                                    </td>
                                    <td class="px-4 py-2 border flex items-center gap-2">
                                        <input-rule @input="editCommande" type="pcheck" v-model="data.grossiste.cmd_oblig_sg" :label-inline="false" />
                                        <span v-if="data.grossiste.cmd_oblig_sg == 0" class="font-light text-sm">Possibilité de commander la pièce chez tout autres fournisseurs</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="px-4 py-2 border font-light text-sm w-[50%]">
                                        {{ $tt('Commande Hors Garantie en centrale d\'achat') }}
                                    </td>
                                    <td class="px-4 py-2 border flex items-center gap-2">
                                        <input-rule @input="editCommande" type="pcheck" v-model="data.cmd_centrale" :label-inline="false" />
                                        <span v-if="data.cmd_centrale == 0" class="font-light text-sm">Possibilité de commander la pièce chez tout autres fournisseurs</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="selectedTab == 'garanties'">
                        <table class="w-full text-left text-sm">
                            <thead class="bg-slate-700 border text-white">
                                <tr>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{ $tt('Actions') }}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{ $tt('Code garantie') }}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{ $tt('Libellé') }}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Nb jours')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Pièces')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('MO sans pièce')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('MO')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('MO Iris')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('MO Call')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Péage')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Déplacement')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Supp_dép')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Frais')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Prêt')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Encastré')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(facturation, facturationIndex) in data.facturations" :key="'facturation-line-' + facturationIndex">
                                    <td class="border px-2 py-1 flex gap-1">
                                        <button @click="selectedFacturationLine = facturation; $modal.show('modal-edit-facturation')" class="p-2 py-1 bg-blue-500 hover:bg-blue-600 rounded text-white text-xs"><i class="fas fa-pen"></i></button>
                                        <button @click="selectedFacturationLine = facturation; $modal.show('modal-confirm-delete-facturation')" class="p-2 py-1 bg-red-500 hover:bg-red-600 rounded text-white text-xs"><i class="fas fa-trash"></i></button>
                                    </td>
                                    <td class="border px-2 py-1">
                                        {{ facturation.garantie }}
                                    </td>
                                    <td class="border px-2 py-1">
                                        {{ facturation.libelle }}
                                    </td>
                                    <td class="border px-2 py-1">
                                        {{ facturation.nb_jour }}
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.pieces" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.mo_sp" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.mo" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.mo_iris" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.mo_call" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.peage" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.dep" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.supp_dep" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.frais" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.pret" class="fas fa-check text-green-600"></i>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <i v-if="facturation.encastre" class="fas fa-check text-green-600"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <button class="px-4 py-2 rounded bg-slate-100 hover:bg-slate-200 duration-200 text-sm mt-2" @click="displayAddFacturation">
                            <i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter une garantie') }}
                        </button>
                    </div>

                    <div v-if="selectedTab == 'categories'">
                        <button v-if="station.id_fournisseur_sta == 0" class="flex items-center text-left gap-2 px-4 py-2 rounded bg-slate-100 hover:bg-slate-200 duration-200 text-sm mb-2" @click="displayModalCateg(null)">
                            <i class="fas fa-plus mr-1 text-xl"></i>
                            <div>
                                <div class="font-bold">{{ $tt('Nouvelle catégorie fournisseur') }}</div>
                                <div class="font-light text-xs">{{ $tt('Sous garantie') }}</div>
                            </div>
                        </button>
                        <div v-for="(categ, categIndex) in data.categs" :key="'categ-' + categIndex" class="mb-2">
                            <div class="flex items-center justify-between gap-2 border rounded bg-slate-50 p-4">
                                <div>
                                    <div class="font-bold">{{ categ?.libelle }}</div>
                                    <div class="flex items-center gap-2 font-light">
                                        <div class="text-sm">{{$tt('Catégorie')}}: {{ categ.distri_categ?.nom }}</div>
                                        <div class="text-sm">|</div>
                                        <div class="text-sm">{{$tt('Garantie')}}: {{ categ.garantie_four?.libelle }}</div>
                                    </div>
                                </div>
                                <div class="flex items-center gap-2">
                                    <template v-if="station.id_fournisseur_sta == 0">
                                        <modal-reparateur-manage-codes :categ="categ" :iad="station.iad" />
                                    </template>
                                    <button @click="displayModalTarif(categ)" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-xs text-white"><i class="fas fa-euro mr-1"></i> {{ $tt('Tarifs') }}</button>
                                    <button @click="displayModalCateg(categ)" class="px-4 py-2 bg-orange-600 hover:bg-orange-700 duration-200 rounded text-xs text-white"><i class="fas fa-pen mr-1"></i> {{ $tt('Modifier') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="selectedTab == 'intervenant'">
                        <table class="w-full text-left text-sm">
                            <thead class="bg-slate-700 border text-white">
                                <tr>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{ $tt('Actions') }}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{ $tt('Technicien') }}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Agréments')}}</th>
                                    <th class="px-2 py-2 border-r font-semibold text-xs">{{$tt('Numéro agrément du technicien')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(employe, employeIndex) in data.station.employes" :key="'intervenants-line-' + employeIndex">
                                    <td class="border px-2 py-1">
                                        <button @click="displayModalEditEmploye(employe)" class="p-2 py-1 bg-blue-500 hover:bg-blue-600 rounded text-white text-xs"><i class="fas fa-pen"></i></button>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <div class="flex items-center gap-2">
                                            {{ employe.nom }}
                                        </div>
                                    </td>
                                    <td class="border px-2 py-1">
                                        <div v-for="(employeAgree, employeAgreeIndex) in data.employes_agrements.filter((item) => item.id_tech == employe.id)" :key="'employe-agree-table-' + employe.id + '-' + employeAgreeIndex">
                                            {{ employeAgree.activite.libelle }}
                                        </div>
                                    </td>
                                    <td class="border px-2 py-1">
                                        {{ data.employes_agrements.find((item) => item.id_tech == employe.id)?.num_agrement }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ModalReparateurManageCodes from './ModalReparateurManageCodes.vue'
export default {
    components: {
        ModalReparateurManageCodes
    },
    props: {
        station: {
            required: true
        },
    },
    watch: {
        selectedFournisseur: {
            deep: true,
            handler() {
                this.getFournisseur(true)
            }
        }
    },
    data() {
        return {
            loadingGetData: false,
            fournisseurs: null,
            selectedFournisseur: null,
            selectedTab: 'reference',
            data: null,

            formContact: {},
            selectedContact: null,
            loadingContact: false,

            selectedFacturationLine: null,
            loadingEditFacturation: false,

            intervenantAgrementNb: null,
            intervenantAgrements: {},
            selectedEmploye: null,

            selectedCategLine: null,
            loadingEditAddCateg: false,
            eictas: [],
            categDistris: [],
            categLibelle: null,
            categGarantieId: null,
            selectedEicta: null,
            groupCategDistri: null,
            categDistri: null,
            categThermique: null,
            formTarif: {},
            loadingEditAddTarifCateg: false,

            categs: [],
            codePannes: {},
            tarifMoExceptions: [],

            agrementForm: null,
            loadingEditAgrement: false,
        }
    },
    computed: {
        availableContactTypes() {
            if (this.data) {
                let defaults = ['Direction', 'Responsable Comptabilité', 'Responsable SAV', 'Responsable Litige', 'Responsable Pièce']
                return defaults.filter((item) => !this.data.contacts.find((contact) => contact.poste == item)) 
            }
            return []
        },
        validAgrements() {
            return this.station.activites.filter((activiteFilter) => {
                if (this.data) {
                    let agrement = this.data.agrements.find((allAgrement) => allAgrement.id_activite == activiteFilter.id)
                    if (agrement && agrement.valid && agrement.agree == 1) {
                        return true
                    }
                }
                return false
            }).map((item) => ({...item, agree: false}))
        }
    },
    methods: {
        async getFournisseurs() {
            this.fournisseurs = await this.$request.send('reparateur.get-fournisseurs')
            if (this.fournisseurs.length > 0) {
                this.selectedFournisseur = this.fournisseurs[0]
            }
        },

        async getFournisseur(init = false) {
            if (init) {
                this.loadingGetData = true
                this.data = null
                this.selectedTab = 'reference'
            }
            if (this.selectedFournisseur) {
                this.data = await this.$request.send('reparateur.get-fournisseur-conf', {
                    id_fournisseur: this.selectedFournisseur.id
                })
                this.loadingGetData = false
            }
        },

        // CONTACT

        async editAddContact() {
            if (!this.$validation.check([
                {value: this.formContact.poste, rules: 'required', id: 'inp-contact-poste'},
                {value: this.formContact.nom, rules: 'required', id: 'inp-contact-nom'},
                {value: this.formContact.email, rules: 'email', id: 'inp-contact-email'},
                {value: this.formContact.tel, rules: 'tel', id: 'inp-contact-tel'},
            ])) {return}
            if (!this.formContact.email && !this.formContact.tel) {
                this.$toast.error(this.$tt('Téléphone ou Email obligatoire'))
                return
            }
            this.loadingContact = true
            let endData = JSON.parse(JSON.stringify(this.formContact))
            endData.id_grossiste_station = this.data.id
            if (this.formContact.id) {
                endData.line_id = this.formContact.id
                delete endData.id
            }
            let response = await this.$request.send('reparateur.edit-add-fournisseur-contact', endData)
            if (response == 1) {
                this.formContact = {}
                await this.getFournisseur()
                this.$modal.hide('modal-edit-add-contact')
            }
            this.loadingContact = false
        },

        async deleteContact() {
            this.loadingContact = true
            let response = await this.$request.send('reparateur.delete-fournisseur-contact', {
                line_id: this.selectedContact.id
            })
            if (response == 1) {
                await this.getFournisseur()
                this.$modal.hide('modal-confirm-delete-contact')
            }
            this.loadingContact = false
        },

        // AGREMENTS

        displayAgrementModal() {            
            let activiteIds = this.station.activites.map((item) => item.id)
            let activites = {}

            activiteIds.forEach((activiteId) => {
                if (!activites[activiteId]) {
                    activites[activiteId] = {}
                }

                let agrement = this.data.agrements.find((item) => item.id_activite == activiteId)
                console.log(agrement);
                
                activites[activiteId].agree = agrement ? agrement.agree : 0
            })

            let endData = JSON.parse(JSON.stringify({
                marque: this.data.grossiste,
                activites: activites,
                id_connexion: this.data.id
            }))

            this.agrementForm = endData
            this.$modal.show('modal-edit-add-agrements')
        },

        async editAgrements() {
            this.loadingEditAgrement = true
            let response = await this.$request.post('reparateur.edit-agrements', {
                id_connexion: this.data.id,
                data: JSON.stringify(this.agrementForm.activites)
            })
            if (response.data.posts.post == 1) {
                await this.getFournisseur()
                this.$toast.success(this.$tt('Enregistré'))
                this.$modal.hide('modal-edit-add-agrements')
            }
            this.loadingEditAgrement = false
        },

        async editCompta() {
            let validations = [
                {value: this.data.compte_client, rules: 'required', id: 'comptabilite-num_client'},
            ]
            if (this.data.remise_piece > 0 && !this.data.facture_bl) {
                validations.push({value: this.data.facture_bl, rules: 'required', id: 'comptabilite-facture_bl'},)
            }
            if (!this.$validation.check(validations)) {return}
            let variables = ["compte_client", "compte_comptable", "delai_paiement_facture", "remise_piece", "coeff_hg", "coeff_sg", "facture_bl", "livraison_bl"]
            let endData = {
                id_connexion: this.data.id,
            }
            variables.forEach((item) => {
                if (!this.data[item]) {
                    endData[item] = ''
                } else {
                    endData[item] = this.data[item]
                }
            })
            let response = await this.$request.send('reparateur.edit-fournisseur-compta', endData)
            if (response == 1) {
                this.$toast.success(this.$tt('Enregistré'))
            }
        },

        // Facturation

        async editFacturationLine() {
            let endData = JSON.parse(JSON.stringify(this.selectedFacturationLine))
            if (endData.id) {
                endData.line_id = this.selectedFacturationLine.id
                delete endData.id
            }
            let transformKeys = ["pieces", "mo_sp", "mo", "mo_iris", "mo_call", "peage", "dep", "supp_dep", "frais", "pret", "encastre"]
            transformKeys.forEach((item) => {
                endData[item] = endData[item] ? 1 : 0
            })
            this.loadingEditFacturation = true
            let response = await this.$request.send('reparateur.edit-four-facturation', endData)

            if (response == 1) {
                await this.getFournisseur()
                this.$modal.hide('modal-edit-facturation')
                this.selectedEmploye = null
            }

            this.loadingEditFacturation = false

        },
        
        displayAddFacturation() {
            this.selectedFacturationLine = {
                garantie: null,
                libelle: null,
                nb_jour: null,
                pieces: 0,
                mo_sp: 0,
                mo: 0,
                mo_iris: 0,
                mo_call: 0,
                peage: 0,
                dep: 0,
                supp_dep: 0,
                frais: 0,
                pret: 0,
                encastre: 0,
                id_fournisseur: this.data.grossiste.id
            }
            this.$modal.show('modal-edit-facturation')
        },

        async deleteFacturationLine() {
            let response = await this.$request.send('reparateur.delete-four-facturation', {
                line_id: this.selectedFacturationLine.id
            })
            if (response == 1) {
                await this.getFournisseur()
            }
        },

        // Intervenants

        editEmployeAgrement(activite, employe, data) {            
            this.$request.send('reparateur.edit-employe-four-agrement', {
                id_connexion: this.data.id,
                id_tech: employe.id,
                id_type_activite: activite.id,
                delete: data ? 0 : 1,
                agrement: 'test'
            })
        },

        displayModalEditEmploye(employe) {
            this.intervenantAgrementNb = this.data.employes_agrements.find((item) => item.id_tech == employe.id)?.num_agrement
            this.intervenantAgrements = {}
            this.data.employes_agrements.filter((item) => item.id_tech == employe.id).forEach((item) => {
                this.intervenantAgrements[item.id_type_activite] = 1
            })
            this.selectedEmploye = employe;
            this.$modal.show('modal-edit-employe')
        },

        async editTechAgrement() {
            let agrements = {agree: [], not_agree: []}
            for (const [key, value] of Object.entries(this.intervenantAgrements)) {
                if (value == 1) {
                    agrements.agree.push(key)
                } else {
                    agrements.not_agree.push(key)
                }
            }

            let response = await this.$request.send('reparateur.edit-tech-four-agrement', {
                num_agrement: this.intervenantAgrementNb ? this.intervenantAgrementNb : '',
                agrements: JSON.stringify(agrements),
                id_connexion: this.data.id,
                tech_id: this.selectedEmploye.id
            })

            if (response == 1) {
                await this.getFournisseur()
                this.$modal.hide('modal-edit-employe')
                this.selectedEmploye = null
            }
        },

        // Categories

        async getEicta() {
            let formData = {}
            if (this.$request.user().account.type_entite == 3) {
                let responseStation = await this.$request.post('reparateur.get-sta')
                formData = {
                    station_id: responseStation.data.posts.post.id
                }
            }
            if (this.$request.user().account.type_entite == 6) {
                formData = {
                    station_id: this.$request.user().account.id_do
                }
            }
            if (this.$request.user().account.type_entite == 5) {
                formData = {
                    station_id: this.$request.user().account.id_entite
                }
            }
            let response = await this.$request.post('categ.get-eicta', formData)
            this.eictas = response.data.posts.post
            
            if (this.selectedCategLine?.id) {
                this.selectedEicta = this.eictas.find((item) => item.id == this.selectedCategLine.code_group_id)
            } else {
                this.selectedEicta = null
            }
        },

        async getDistriCategs() {
            if (this.selectedEicta) {
                this.groupCategDistri = null
                this.categDistri = null
                this.categThermique = null
                this.loadingGetDistriCategs = true
                let response = await this.$request.post('categ.get-distri-categs', {
                    eicta_id: this.selectedEicta.id
                })
                this.loadingGetDistriCategs = false
                this.categDistris = response.data.posts.post
                if (this.selectedCategLine?.id) {
                    for (const key in this.categDistris) {
                        if (this.categDistris[key].find((item) => item.id == this.selectedCategLine.id_distri_categ)) {
                            this.groupCategDistri = key
                        }
                    }
                    this.categDistri = this.selectedCategLine.id_distri_categ
                    this.categThermique = this.selectedCategLine.thermique
                }
            }
        },

        async getCodesPannes() {
            this.codePannes = {
                reparations: [],
                sections: []
            }
            let sections = await this.$request.send('reparateur.get-list-codes', {
                type_code: 3,
                categ_id: this.selectedCategLine.id
            })
            this.codePannes.sections = sections?.selected

            let reparations = await this.$request.send('reparateur.get-list-codes', {
                type_code: 5,
                categ_id: this.selectedCategLine.id
            })
            this.codePannes.reparations = reparations?.selected
        },

        async saveEditAddCateg() {
            let endData = {}
            if (this.station.id_fournisseur_sta == 0) {
                if (!this.$validation.check([
                    {value: this.categLibelle, rules: 'required', id: 'new-categ-libelle'},
                    {value: this.categGarantieId, rules: 'required', id: 'new-categ-garantie'},
                    {value: this.selectedEicta, rules: 'required', id: 'new-categ-eicta'},
                    {value: this.groupCategDistri, rules: 'required', id: 'id-distri-group-categ'},
                    {value: this.categDistri, rules: 'required', id: 'id-distri-categ'},
                ])) {return}
                endData = {
                    id_distri_categ: this.categDistri,
                    libelle: this.categLibelle,
                    code_group_id: this.selectedEicta.id,
                    thermique: this.categThermique ? 1 : 0,
                    id_garantie: this.categGarantieId,
                    id_fournisseur: this.data.grossiste.id
                }
            } else {
                if (!this.$validation.check([
                    {value: this.categGarantieId, rules: 'required', id: 'new-categ-garantie'},
                ])) {return}
                endData = {
                    id_garantie: this.categGarantieId,
                    id_fournisseur: this.data.grossiste.id
                }
            }

            this.loadingEditAddCateg = true
            if (this.selectedCategLine.id) {
                endData.line_id = this.selectedCategLine.id
                delete endData.id
            }
            let response = await this.$request.post('reparateur.edit-add-list-categ', endData)
            if (response.data.posts.post == 1) {
                await this.getFournisseur()
                this.$toast.success(this.$tt('Catégorie ajoutée'))
                this.$modal.hide('modal-edit-categ')
                this.categDistri = null
                this.categLibelle = null
                this.selectedEicta = null
                this.categThermique = null
                this.categGarantieId = null
                this.groupCategDistri = null
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.loadingEditAddCateg = false
        },

        displayModalTarif(categ) {
            this.formTarif = categ.tarifs && categ.tarifs.length > 0 ? categ.tarifs[0] : {
                deplacement_base: '0.00',
                km_ref: 0,
                km_supp: '0.00',
                mo_ext: '0.00',
                mo_sans_piece: '0.00',
                supp_enc: '0.00',
                mo_atelier: '0.00',
                mo_sans_piece_iad: '0.00',
                port_aller: '0.00',
                port_retour: '0.00',
            }
            this.selectedCategLine = categ;
            this.tarifMoExceptions = categ.mo_codes
            this.getCodesPannes()
            this.$modal.show('modal-edit-categ-tarif')
        },

        displayModalCateg(categ) {
            this.categDistri = null
            this.categLibelle = null
            this.selectedEicta = null
            this.categThermique = null
            this.categGarantieId = null
            this.groupCategDistri = null

            this.$nextTick(() => {
                this.getEicta()
                this.selectedCategLine = categ ? categ : {}
                if (categ) {
                    this.categLibelle = categ.libelle
                    this.categGarantieId = categ.id_garantie
                }
                this.$modal.show('modal-edit-categ')
            })
        },

        async editAddTarifCateg() {
            let endData = this.formTarif
            endData.id_categ = this.selectedCategLine.id
            endData.type_client = this.data.grossiste.id
            if (endData.id) {
                endData.line_id = endData.id
                delete endData.id
            }

            if (this.tarifMoExceptions.length > 0) {
                let error = null
                this.tarifMoExceptions.forEach((item) => {
                    if (!item.reparation || !item.section || !item.amount) {
                        error = this.$tt('Codes pannes: Une ou plusieurs règles sont incomplètes')
                    } else {
                        let sameLines = this.tarifMoExceptions.filter((exception) => exception.reparation == item.reparation && exception.section == item.section)
                        let lineDuplicate = sameLines && sameLines.length > 1 ? true : false
                        if (lineDuplicate) {
                            error = this.$tt('Codes pannes') + ': ' + this.$tt('duplication') + ' ' + this.codePannes.reparations.find((rep) => rep.code == item.reparation).fr + ' / ' + this.codePannes.sections.find((section) => section.code == item.section).fr
                        }
                    }
                })
                if (error) {
                    this.$toast.error(error)
                    return
                }
            }

            // Add leading 0
            let leadingKeys = ["deplacement_base", "km_ref", "km_supp", "mo_ext", "mo_sans_piece_iad", "supp_enc", "mo_atelier", "mo_sans_piece", "port_retour"]
            leadingKeys.forEach((leadingKey) => {
                endData[leadingKey] = parseFloat(endData[leadingKey]).toFixed(2)
            })

            if (this.tarifMoExceptions.length > 0) {
                endData.code_pannes = JSON.stringify(this.tarifMoExceptions)
            }
            endData.particulier = 0

            this.loadingEditAddTarifCateg = true
            let response = await this.$request.post('reparateur.edit-add-tarif', endData)
            if (response.data.posts.post == 1) {
                this.$toast.success('Tarif ajouté')
                this.$modal.hide('modal-edit-categ-tarif')
                this.getFournisseur()
                this.loadingEditAddTarifCateg = false
            } else {
                this.loadingEditAddTarifCateg = false
            }
        },

        async editCommande() {
            this.$request.post('reparateur.fournisseur-edit-commande', {
                line_id: this.data.id,
                commande_sg: this.data.grossiste.cmd_oblig_sg,
                commande_hg: this.data.cmd_centrale
            })
        }
    },
    mounted () {
        this.getFournisseurs()
    },
}
</script>

<style>
.custom-inp input, .custom-inp select {
    background: white !important;
    border-radius: 3px;
    font-size: 14px;
    padding: 4px;
}
</style>