<template>
    <div>
        <div class="grid grid-cols-6">
            <div class="pr-20 col-span-2">
                <h2 class="font-bold mb-3">{{ $tt('Entités') }}</h2>
                <div class="border-t rounded">
                    <div @click="selectedEntite = 'particulier'" class="border-b border-x p-2 duration-200 cursor-pointer text-sm" :class="selectedEntite && selectedEntite == 'particulier' ? 'bg-blue-800 text-white font-bold' : 'hover:bg-slate-100'">
                        {{ $tt('Particulier') }}
                    </div>
                    <div @click="selectedEntite = 'pro'" class="border-b border-x p-2 duration-200 cursor-pointer text-sm" :class="selectedEntite && selectedEntite == 'pro' ? 'bg-blue-800 text-white font-bold' : 'hover:bg-slate-100'">
                        {{ $tt('Professionnel') }}
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div v-if="selectedEntite">
                    <h2 class="font-bold mb-3">{{ $tt('Coefficients re-facturation pièces') }}</h2>
                    <table class="text-sm w-full">
                        <thead class="bg-slate-100 font-bold">
                            <tr>
                                <td class="px-2 py-1 border">{{$tt('Montant min')}}</td>
                                <td class="px-2 py-1 border">{{$tt('Montant max')}}</td>
                                <td class="px-2 py-1 border">{{$tt('Coeff')}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-slate-200">
                                <td class="px-2 py-1 border"><input-rule v-model="formNewMontant.montant_min" type="price" :label-inline="false" :placeholder="$tt('Montant min')" id="montant-min" /></td>
                                <td class="px-2 py-1 border"><input-rule v-model="formNewMontant.montant_max" type="price" :label-inline="false" :placeholder="$tt('Montant max')" id="montant-max" /></td>
                                <td class="px-2 py-1 border">
                                    <div class="grid grid-cols-3 items-center gap-2">
                                        <div class="col-span-2">
                                            <input-rule v-model="formNewMontant.coeff" type="number" :label-inline="false" :placeholder="$tt('Coeff')" id="coeff" />
                                        </div>
                                        <div class="text-right pt-0.5">
                                            <button :disabled="loadingEditAdd" @click="addNewMontant" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded text-xs">
                                                <spinner v-if="loadingEditAdd" :size="10" />
                                                <template v-if="!loadingEditAdd">
                                                    <i class="fas fa-check mr-1"></i> {{ $tt('Ajouter') }}
                                                </template>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-for="(coeff, coeffIndex) in coeffs" :key="'coeff-table-' + coeffIndex">
                                <td class="px-2 py-1 border"><input-rule type="price" :label-inline="false" v-model="coeff.montant_min" @focusout="editLine(coeff)" /></td>
                                <td class="px-2 py-1 border"><input-rule type="price" :label-inline="false" v-model="coeff.montant_max" @focusout="editLine(coeff)" /></td>
                                
                                <td class="px-2 py-1 border">
                                    <div class="grid grid-cols-3 items-center gap-2">
                                        <div class="col-span-2">
                                            <input-rule type="number" :label-inline="false" v-model="coeff.coeff" @focusout="editLine(coeff)" />
                                        </div>
                                        <div class="text-right pt-0.5">
                                            <button @click="deleteLine(coeff)" :disabled="coeff.loadingDelete" class="px-3 py-1.5 bg-red-200 hover:bg-red-600 duration-200 text-red-600 hover:text-white rounded text-xs">
                                                <spinner :size="10" v-if="coeff.loadingDelete" />
                                                <template v-if="!coeff.loadingDelete">
                                                    <i class="fas fa-trash mr-1"></i> {{ $tt('Retirer') }}
                                                </template>
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="loadingGetCoeffs">
                                <td class="px-2 py-4"><spinner :size="10" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    watch: {
        selectedEntite() {
            this.getCoeffs()
        }
    },
    data() {
        return {
            // entites: [],
            selectedEntite: 'particulier',

            coeffs: [],
            formNewMontant: {},

            loadingGetCoeffs: false,
            loadingEditAdd: false
        }
    },
    methods: {
        // async getEntites() {
        //     let response = await this.$request.post('reparateur.get-centrales-by-activites')
        //     this.entites = response.data.posts.post
        //     this.entites.unshift({
        //         centrale_ha: 'Particulier',
        //         id: -1
        //     })
        // },

        async getCoeffs(loader = true) {
            if (loader) {
                this.loadingGetCoeffs = true
                this.coeffs = []
            }
            let response = await this.$request.post('reparateur.get-coeff-entite', {
                particulier: this.selectedEntite == 'particulier' ? 1 : 0
            })
            this.coeffs = response.data.posts.post.map((item) => ({...item, loadingDelete: false}))
            this.loadingGetCoeffs = false
        },

        async addNewMontant() {
            if (!this.$validation.check([
                {value: this.formNewMontant.montant_min, rules: 'required', id: 'montant-min'},
                {value: this.formNewMontant.montant_max, rules: 'required', id: 'montant-max'},
                {value: this.formNewMontant.coeff, rules: 'required', id: 'coeff'},
            ])) {return}

            this.loadingEditAdd = true
            await this.$request.post('reparateur.edit-add-coeff', {
                montant_min: this.formNewMontant.montant_min,
                montant_max: this.formNewMontant.montant_max,
                coeff: this.formNewMontant.coeff,
                // entite: this.selectedEntite.id,
                particulier: this.selectedEntite == 'particulier' ? 1 : 0

            })
            this.loadingEditAdd = false
            this.formNewMontant = {}
            this.$forceUpdate()
            this.getCoeffs(false)
        },

        async editLine(coeff) {
            await this.$request.post('reparateur.edit-add-coeff', {
                line_id: coeff.id,
                montant_min: coeff.montant_min,
                montant_max: coeff.montant_max,
                coeff: coeff.coeff,
            })
        },

        async deleteLine(coeff) {
            coeff.loadingDelete = true
            await this.$request.post('reparateur.delete-coeff', {
                line_id: coeff.id
            })
            this.getCoeffs(false)
            coeff.loadingDelete = false
        }
    },
    mounted () {
        // this.getEntites()
        this.getCoeffs()
    },
}
</script>

<style lang="scss" scoped>

</style>