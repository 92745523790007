<template>
    <div>
        <!-- Modal edit add codepannes -->
        <modal :name="'modal-edit-add-codepannes' + _uid" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{ categ.libelle }}</h2>
                        <h3 class="text-sm font-light">{{ $tt('Gestion des codes pannes - Hors garantie') }}</h3>
                    </div>
                    <button @click="$modal.hide('modal-edit-add-codepannes' + _uid)" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <!-- <div class="bg-slate-100 p-4 rounded my-6 flex items-center gap-3 text-slate-500">
                        <i class="fas fa-info-circle text-lg"></i>
                        <div class="text-sm">{{ $tt('Les codes sont obligatoires pour la gestion sous garantie, mais optionnels pour la gestion hors garantie') }}</div>
                    </div> -->
                    <div class="flex items-center">
                        <div @click="typeCode = 1" :class="typeCode == 1 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t border-l rounded-tl">{{ $tt(codeLabels[1]) }}</div>
                        <div @click="typeCode = 2" :class="typeCode == 2 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t">{{ $tt(codeLabels[2]) }}</div>
                        <div @click="typeCode = 4" :class="typeCode == 4 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t">{{ $tt(codeLabels[3]) }}</div>
                        <div @click="typeCode = 3" :class="typeCode == 3 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t">{{ $tt(codeLabels[4]) }}</div>
                        <div @click="typeCode = 5" :class="typeCode == 5 ? 'bg-slate-300 font-bold' : 'bg-slate-100 hover:bg-slate-200 duration-200'" class="cursor-pointer px-4 py-2 border-t border-r rounded-tr">{{ $tt(codeLabels[5]) }}</div>
                    </div>
                    <div class="border p-4 rounded rounded-tl-none">
                        <div class="grid grid-cols-2 gap-4">
                            <div>
                                <div class="mb-2 text-right">
                                    <button @click="formNewCode = {}; $modal.show('modal-add-code')" class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-xs">
                                        <i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter un nouveau code') }}
                                    </button>
                                </div>
                                <input-rule type="text" v-model="searchCodeInp" @input="searchCode" :placeholder="$tt('Rechercher un code')" :label-inline="false" class="mb-2" />
                                <div class="border max-h-[600px] overflow-y-auto">
                                    <div class="p-2" v-if="loadingGetCode">
                                        <spinner :size="10" />
                                    </div>
                                    <div v-for="(code, codeIndex) in unusedCodes" :key="'code-' + codeIndex" class="border-b p-2 cursor-copy hover:bg-green-100 duration-200 prevent-select" @click="addCode(code)">
                                        <div class="flex items-center justify-between gap-2">
                                            <div class="flex items-center gap-2">
                                                <div class="flex items-center gap-2">
                                                    <div class="bg-slate-200 px-4 py-1 rounded text-xs font-bold">{{ code.code }}</div>
                                                    <div class="font-semibold">{{ code.fr }}</div>
                                                    <div class="text-xs bg-orange-100 text-center w-[70px] p-0.5 rounded font-bold mt-0.5" v-if="code.id_sta">{{ $tt('Interne') }}</div>
                                                </div>
                                            </div>
                                            <button @click.stop="selectedCode = code; $modal.show('modal-confirm-delete-code')" v-if="code.id_sta"><i class="fas fa-trash text-red-500"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="max-h-[600px] overflow-y-auto">
                                <div v-for="(code, codeIndex) in selectedCodes" :key="'selected-code-' + codeIndex" class="prevent-select border-b p-2 cursor-w-resize bg-green-100 hover:bg-red-100 duration-200 focus:outline-none" @click="selectedCodes.splice(codeIndex, 1); saveSelectedCodes()">
                                    <div class="flex items-center gap-2">
                                        <div class="bg-slate-200 px-4 py-1 rounded text-xs font-bold">{{ code.code }}</div>
                                        <div>{{ code.fr }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-confirm-delete-code" height="auto" :scrollable="true">
            <div class="p-4" v-if="selectedCode">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                    </div>
                    <button @click="$modal.hide('modal-confirm-delete-code')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="bg-orange-100 text-orange-700 p-4 mb-4 rounded">
                        {{ $tt('Confirmez-vous la suppression du code ' + codeLabels[typeCode].split(' ')[1].toLowerCase()) }} <b>{{ selectedCode.fr }} ?</b>
                    </div>
                    <div class="flex items-center gap-2">
                        <button class="px-4 py-2 bg-slate-100 hover:bg-slate-200 duration-200 rounded text-sm font-bold" @click="$modal.hide('modal-confirm-delete-code')">
                            {{ $tt('Annuler') }}
                        </button>
                        <button :disabled="loadingDelete" class="px-4 py-2 bg-red-500 hover:bg-red-600 duration-200 rounded text-white text-sm font-bold" @click="deleteCode">
                            <spinner v-if="loadingDelete" :size="10" />
                            <template v-if="!loadingDelete">
                                <i class="fas fa-trash mr-1"></i> {{ $tt('Supprimer') }}
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-add-code" height="auto" :scrollable="true">
            <div class="p-4">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold">{{ $tt('Ajouter un nouveau code') }}</h2>
                    </div>
                    <button @click="$modal.hide('modal-add-code')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="mb-3">
                        <div class="text-xs mb-1 font-bold">Type de code</div>
                        <div class="bg-slate-50 text-sm rounded-0 grow w-full p-2 rounded text-xs border cursor-not-allowed">{{ $tt(codeLabels[typeCode]) }}</div>
                    </div>
                    <input-rule v-model="formNewCode.code" type="text" :label="$tt('Code')" :label-inline="false" class="mb-3" id="new-code" />
                    <input-rule v-model="formNewCode.design" type="text" :label="$tt('Désignation')" :label-inline="false" class="mb-3" id="new-design" />
                    <button @click="saveNewCode" :disabled="loadingNewCode" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white text-xs rounded">
                        <spinner v-if="loadingNewCode" :size="10" />
                        <template v-if="!loadingNewCode">
                            <i class="fas fa-check mr-1"></i> {{ $tt('Enregistrer') }}
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <button class="bg-blue-800 hover:bg-blue-900 duration-200 text-white rounded text-xs p-2" @click.stop="getCodes(); $modal.show('modal-edit-add-codepannes' + _uid)"><i class="fas fa-list"></i> {{ $tt('Code pannes') }}</button>
    </div>
</template>

<script>
export default {
    props: {
        categ: {
            required: true
        },
        initModal: {default: false}
    },
    watch: {
        typeCode() {
            this.getCodes()
        },
    },
    data() {
        return {
            defaultCodes: [],
            codes: [],
            typeCode: 1,

            codeLabels: {
                1: "Codes condition",
                2: "Codes symptome",
                3: "Codes défaut",
                4: "Codes section",
                5: "Codes réparation",
            },

            selectedCode: null,
            selectedCodes: [],
            searchCodeInp: null,

            formNewCode: {},

            loadingGetCode: false,
            loadingNewCode: false,
            loadingDelete: false,
        }
    },
    computed: {
        unusedCodes() {
            return this.codes.filter((item) => !this.selectedCodes.find((sCode) => sCode.code == item.code))
        }
    },
    methods: {
        // categorie_codes
        async getCodes() {
            this.codes = []
            this.defaultCodes = []
            this.selectedCodes = []
            this.searchCodeInp = null
            this.loadingGetCode = true
            let response = await this.$request.post('reparateur.get-list-codes', {
                type_code: this.typeCode,
                categ_id: this.categ.id
            })
            this.loadingGetCode = false
            this.defaultCodes = response.data.posts.post.not_selected
            this.codes = response.data.posts.post.not_selected
            this.selectedCodes = response.data.posts.post.selected
        },

        async saveSelectedCodes() {
            await this.$request.post('reparateur.save-list-codes', {
                type_code: this.typeCode,
                codes: JSON.stringify(this.selectedCodes.map((item) => item.code)),
                categ_id: this.categ.id
            })
        },

        searchCode() {
            if (!this.searchCodeInp) {
                this.codes = this.defaultCodes
            } else {
                this.codes = this.defaultCodes.filter((item) => item.fr.toLowerCase().includes(this.searchCodeInp.toLowerCase()) || item.code.toLowerCase().includes(this.searchCodeInp.toLowerCase()))
            }
        },

        addCode(code) {
            this.selectedCodes.push(code)
            if (this.unusedCodes.length <= 0) {
                this.searchCodeInp = null
            }
            this.saveSelectedCodes()
        },

        async saveNewCode() {
            if (!this.$validation.check([
                {value: this.formNewCode.code, rules: 'required', id: 'new-code'},
                {value: this.formNewCode.design, rules: 'required', id: 'new-design'},
            ])) {return}

            this.loadingNewCode = true
            this.formNewCode.type_code = this.typeCode
            let response = await this.$request.post('reparateur.add-new-code', this.formNewCode)
            if (response.data.posts.post == 1) {
                await this.getCodes()
                this.formNewCode = {}
                this.$modal.hide('modal-add-code')
                this.$toast.success(this.$tt('Code enregistré'))
            }
            this.loadingNewCode = false
        },

        async deleteCode() {
            this.loadingDelete = true
            let response = await this.$request.send('reparateur.delete-code', {
                code: this.selectedCode.code,
                type_code: this.typeCode,
                id_categorie: this.categ.id
            })
            if (response == 1) {
                await this.getCodes()
                this.selectedCode = null
                this.$toast.success(this.$tt('Code supprimé'))
                this.$modal.hide('modal-confirm-delete-code')
            }
            this.loadingDelete = false
        }
    },
}
</script>

<style>
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
</style>