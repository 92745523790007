<template>
    <div>
        <modal :name="'modal-overwrite-proc-' + _uid" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Appliquer la configuration')}}
                    </h2>
                    <button @click="$modal.hide('modal-overwrite-proc-' + _uid)">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg">{{$tt('Souhaitez-vous utiliser cette nouvelle configuration sur les anciennes procédures ?')}}</div>
                    <template v-if="!loadingSave">
                        <button @click="saveForm(1)" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 text-white rounded mt-2">{{$tt('Oui, je souhaite appliquer cette nouvelle procédure')}}</button>
                        <button @click="saveForm(0)" class="px-4 py-2 bg-orange-400 hover:bg-orange-500 duration-200 rounded mt-2">{{$tt('Non, garder l\'ancienne configuration')}}</button>
                    </template>
                    <div class="p-2" v-if="loadingSave">
                        <spinner :size="10" />
                    </div>
                    <!-- <div class="px-4">Souhaitez-vous réellement supprimer la procédure <b>{{ $parent.$parent.typeGarantieLabels.find((item) => item.key == procedure.type_garantie).label }}</b> ?</div> -->
                    <!-- <div class="border-t pt-4 flex mt-4 gap-2 items-center">
                        <button @click="$modal.hide('modal-confirm-delete-proc')" class="px-4 py-2 rounded text-sm bg-slate-100 hover:bg-slate-300 duration-200">Annuler</button>
                        <button @click="deleteProc" class="px-4 py-2 rounded text-sm hover:bg-red-500 duration-200 hover:text-white">Supprimer</button>
                    </div> -->
                </div>
            </div>
        </modal>
        <modal name="modal-confirm-delete-proc" height="auto" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Supprimer une procédure')}}
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete-proc')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <!-- <div class="px-4">Souhaitez-vous réellement supprimer la procédure <b>{{ $parent.$parent.typeGarantieLabels.find((item) => item.key == procedure.type_garantie).label }}</b> ?</div> -->
                    <div class="border-t pt-4 flex mt-4 gap-2 items-center">
                        <button @click="$modal.hide('modal-confirm-delete-proc')" class="px-4 py-2 rounded text-sm bg-slate-100 hover:bg-slate-300 duration-200">{{$tt('Annuler')}}</button>
                        <button @click="deleteProc" class="px-4 py-2 rounded text-sm hover:bg-red-500 duration-200 hover:text-white">{{$tt('Supprimer')}}</button>
                    </div>
                </div>
            </div>
        </modal>
        <modal name="modal-detail-workflow" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedDetailWorkflow">
                <div class="flex items-center justify-between gap-14 mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-light text-sm">
                        <i class="fas fa-info-circle mr-0.5"></i> {{ selectedDetailWorkflow.descriptif }}
                    </h2>
                    <button @click="$modal.hide('modal-detail-workflow')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="font-bold">{{ selectedDetailWorkflow.detail }}</div>
                </div>
            </div>
        </modal>
        <modal name="modal-select-workflow" height="auto" width="70%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-2xl">
                    <h2 class="font-bold">
                        {{$tt('Choisir un workflow')}}
                    </h2>
                    <button @click="$modal.hide('modal-select-workflow')">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="grid grid-cols-2 gap-2">
                        <div class="border-r max-h-[50vh] overflow-y-auto">
                            <div @click="selectedWorkflowModal = workflow" class="text-sm p-2 border-b hover:bg-slate-100 cursor-pointer duration-200" :class="selectedWorkflowModal && selectedWorkflowModal.id == workflow.id ? 'bg-slate-100' : ''" v-for="(workflow, workflowIndex) in workflows" :key="'workflow-item-' + workflowIndex">
                                {{ workflow.descriptif }}
                            </div>
                        </div>
                        <div>
                            <div v-if="selectedWorkflowModal">
                                <div class="bg-slate-100 rounded p-2">
                                    {{ selectedWorkflowModal.detail }}
                                </div>
                                <button @click="selectWorkflow" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-sm rounded text-white mt-2">{{$tt('Valider')}} <i class="fas fa-check ml-1"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <div v-if="form" class="grid grid-cols-2 gap-2">
            <div>
                <div class="bg-gray-100 p-4 rounded-lg">
                    <h2 class="text-lg font-bold mb-2 pb-2 border-b text-slate-600">{{$tt('Paramètres généraux')}}</h2>
                    <input-rule v-model="form.space" :disabled="readOnly" type="select" class="mb-4" :label="$tt('Espace')" :label-inline="false" :options="{values: [{key: 'magasin', label: 'Magasin'}, {key: 'selfcare', label: 'Selfcare', disabled: !hasSelfcare}], key: 'key', label: 'label'}" id="space" />
                    <input-rule v-model="form.pays" :disabled="readOnly" type="select" class="mb-4" :label="$tt('Pays')" :label-inline="false" :options="{values: [{key: 'FR', label: 'France'}], key: 'key', label: 'label'}" />
                    <input-rule v-model="form.type_garantie" :disabled="readOnly" type="select" id="type_garantie" class="mb-4" :label="$tt('Type de garantie')" :label-inline="false" :options="{values: typeGaranties, key: 'key', label: 'label'}" />
                    <input-rule v-model="form.duree" :disabled="readOnly" type="text" id="duree" class="mb-4" :label="form.type_garantie == 'garantie_pms' ? $tt('Durée de la garantie (jours)') : $tt('Durée de la garantie (mois)')" :label-inline="false" :placeholder="form.type_garantie == 'garantie_pms' ? $tt('Nombre de jours') : $tt('Nombre de mois')" />
                    <input-rule v-model="form.to_resend" :disabled="readOnly" type="text" class="mb-4" :label="$tt('Durée de rétention (jours)')" :label-inline="false" :placeholder="$tt('Nombre de jours')" />
                    <input-rule v-model="form.extension_garantie" :disabled="readOnly" type="text" class="mb-4" :label="$tt('Durée maximum de l\'extension de garantie (mois)')" :label-inline="false" :placeholder="$tt('Nombre de mois')" />
                    <input-rule v-model="form.date_fin" :disabled="readOnly" type="date" class="mb-4" :label="$tt('Date de fin de validité')" :label-inline="false" />
                    <template v-if="workflows.find((item) => item.id == form.id_workflow) && workflows.find((item) => item.id == form.id_workflow).sav == 1">
                        <input-rule v-if="stations && stations.length > 0" :disabled="readOnly" v-model="form.id_sta" type="select" class="mb-4" :label="$tt('Station technique de retour')" :label-inline="false" :options="{values: stations, key: 'id', label: 'nom'}" />
                        <div class="mb-4" v-if="!stations || stations.length <= 0">
                            <label class="mb-1 text-xs font-bold text-gray-900">{{$tt('Station technique de retour')}}</label>
                            <div class="mb-4 border p-2 bg-gray-50 rounded-lg text-sm cursor-not-allowed">{{$tt('Aucune station configurée')}}</div>
                        </div>
                    </template>
                    <div class="mb-4">
                        <label class="mb-1 text-xs font-bold text-gray-900">{{$tt('Document')}}</label>
                        <div v-if="!readOnly" class="flex items-center justify-between gap-2">
                            <input-rule type="file" @input="(data) => {if (data) { form.id_file_proc = data.url; $forceUpdate() }}" />
                            <div v-if="form.id_file_proc && form.id_file_proc != '' && form.id_file_proc != 0" class="flex gap-1 items-center">
                                <a :href="form.id_file_proc" target="_blank" class="px-2 py-1.5 text-sm bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer rounded"><i class="fas fa-eye"></i></a>
                                <button @click="form.id_file_proc = ''; $forceUpdate()" class="px-2 py-1.5 text-sm bg-red-200 hover:bg-red-300 duration-200 cursor-pointer rounded"><i class="fas fa-trash"></i></button>
                            </div>
                        </div>
                        <div v-if="readOnly">
                            <div class="mt-1" v-if="form.id_file_proc && form.id_file_proc != '' && form.id_file_proc != 0">
                                <a :href="form.id_file_proc" target="_blank" class="px-2 py-1.5 text-sm bg-slate-200 hover:bg-slate-300 duration-200 cursor-pointer rounded"><i class="fas fa-eye"></i></a>
                            </div>
                            <div class="text-xs mt-1 bg-slate-200 border p-2 rounded" v-else>{{$tt('Aucun document')}}</div>
                        </div>
                    </div>
                </div>
                <div class="bg-gray-100 p-4 rounded-lg mt-2"> 
                    <h2 class="text-lg font-bold mb-2 pb-2 border-b text-slate-600">{{$tt('Transport')}}</h2>
                    <div class="grid grid-cols-2">
                        <div class="pr-4">
                            <div class="font-bold mb-3 pb-3 border-b border-slate-300 text-sm"><i class="fas fa-truck-fast mr-1 text-green-500"></i> {{ $tt('Envoie du produit') }}</div>
                            <input-rule v-model="form.transport_aller" :disabled="readOnly" class="mb-4" type="pcheck" :label="$tt('Prise en charge')" />
                            <div v-if="form.transport_aller" class="flex gap-4 items-center mb-4">
                                <div class="text-xs font-bold text-gray-900">{{$tt('Transporteur')}}</div>
                                <div class="grow border-b border-dashed"></div>
                                <input-rule v-model="form.transporteur_aller" :disabled="readOnly" type="select" :options="{values: transporters, label: 'transporteur', key: 'id'}" custom="text-xs" />
                            </div>
                        </div>
                        <div class="pl-4">
                            <div class="font-bold mb-3 pb-3 border-b border-slate-300 text-sm"><i class="fas fa-person-walking-arrow-loop-left mr-1 text-orange-500"></i> {{$tt('Retour du produit')}}</div>
                            <input-rule v-model="form.transport_retour" :disabled="readOnly" class="mb-4" type="pcheck" label="Prise en charge" />
                            <div v-if="form.transport_retour" class="flex gap-4 items-center mb-4">
                                <div class="text-xs font-bold text-gray-900">{{$tt('Transporteur')}}</div>
                                <div class="grow border-b border-dashed"></div>
                                <input-rule v-model="form.transporteur_retour" :disabled="readOnly" type="select" :options="{values: transporters, label: 'transporteur', key: 'id'}" custom="text-xs" />
                            </div>
                        </div>
                    </div>
                    <!-- <input-rule class="mb-4" type="pcheck" label="Prise en charge - Retour du produit" /> -->
                </div>
            </div>
            <div>
                <div class="bg-gray-100 p-4 rounded-lg"> 
                    <h2 class="text-lg font-bold mb-2 pb-2 border-b text-slate-600">{{$tt('Message')}}</h2>
                    <div class="mb-4">
                        <input-rule v-if="!readOnly" v-model="form.message" :disabled="readOnly" type="richtext" :options="{height: 200}" :label="$tt('Message à afficher')" :label-inline="false" />
                        <template v-if="readOnly">
                            <label class="mb-1 text-xs font-bold text-gray-900">{{$tt('Message à afficher')}}</label>
                            <div class="p-2 bg-slate-200 rounded text-sm">
                                <p v-if="form.message" v-html="form.message"></p>
                                <div class="text-xs" v-else>Aucun</div>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="bg-gray-100 p-4 rounded-lg mt-2"> 
                    <h2 class="text-lg font-bold mb-2 pb-2 border-b text-slate-600">{{$tt('Procédure')}}</h2>
                    <div v-if="errorWorkflow" class="mt-2">
                        <ul class="font-light text-xs text-red-500 list-disc ml-4"><li>{{ errorWorkflow }}</li></ul>
                    </div>
                    <div>
                        <div class="max-h-[340px] overflow-y-auto">
                            <div @click="selectedWorkflowModal = workflow; form.id_workflow = workflow.id" class="text-sm border-b hover:bg-slate-100 cursor-pointer duration-200" :class="selectedWorkflowModal && selectedWorkflowModal.id == workflow.id ? 'bg-slate-100' : ''" v-for="(workflow, workflowIndex) in workflows" :key="'workflow-item-' + workflowIndex">
                                <div class="text-slate-800 p-2 flex items-center justify-between gap-4" :class="form.id_workflow == workflow.id ? 'font-bold bg-slate-300' : ''">
                                    <div>{{ workflow.descriptif }}</div>
                                    <button v-if="workflow.detail" @click.stop="selectedDetailWorkflow = workflow; $modal.show('modal-detail-workflow')" class="px-2.5 py-1.5 text-xs flex items-center gap-1 bg-slate-200 hover:bg-slate-300 duration-200 rounded-lg"><i class="fa-solid fa-circle-info"></i> {{$tt('Info')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!readOnly" class="flex items-center justify-end gap-2">
            <button :disabled="loadingSave" @click="form.id_proc ? $modal.show('modal-overwrite-proc-' + _uid) : saveForm(0)" class="mt-4 px-6 py-3 rounded-lg bg-green-600 hover:bg-green-700 duration-200 text-white font-bold text-sm">
                <spinner v-if="loadingSave" :size="10" />
                <template v-if="!loadingSave">
                    {{$tt('Enregistrer')}} <i class="fas fa-check ml-1"></i>
                </template>
            </button>
        </div>
    </div>
</template>

<script>
import ApiService from '@/services/api.service'
import moment from 'moment'

export default {
    props: {
        procedure: {},
        readOnly: {default: false},
        space: {}
    },
    watch: {
        procedure: {
            deep: true,
            handler() {
                this.initForm()
            }
        },
        space() {
            this.form.space = this.space
        }
    },
    data() {
        return {
            selectedTabForm: 'general',
            workflows: [],
            selectedWorkflowModal: null,
            errorWorkflow: null,
            form: {},
            formFields: [
                {label: 'N. de facture', key: 'facture_oblig'},
                {label: 'Client', key: 'client_oblig'},
                {label: 'Numéro de série', key: 'numserie_oblig'},
                {label: 'Date de fabrication', key: 'date_fab'},
                {label: 'Numéro accord', key: 'num_accord'},
                {label: 'Envoie plaque signalétique', key: 'plaque'},
                {label: 'Destruction', key: 'destruction'},
                {label: 'Justificatif d\'achat', key: 'justif_oblig'},
                {label: 'Code panne', key: 'codepanne_oblig'},
                {label: 'Photo de la panne', key: 'photo'},
                {label: 'IMEI', key: 'imei'},
                {label: 'Photo plaque signalétique', key: 'photo_plaque'},
                {label: 'Date de dépôt', key: 'date_demande'},
                {label: 'Gestion de l\'emballage', key: 'emballage'},
            ],
            transporters: [],
            stations: [],
            displayWorkflow: true,
            loadingSave: false,
            selectedDetailWorkflow: null,
            hasSelfcare: false,
        }
    },
    computed: {
        typeGaranties() {
            return [
                {key: 'garantie_marque', label: 'Garantie Marque'},
                {key: 'garantie_distributeur', label: 'Garantie Distributeur'},
                {key: 'garantie_pms', label: 'Garantie PMS'},
                {key: 'garantie_comp', label: 'Garantie Complémentaire'},
                {key: 'hors_garantie', label: 'Hors garantie'},
            ]
        },
    },
    methods: {
        async getWorkflows() {
            let response = await this.$request.post('backoffice', {
                operation: 'getworkflow',
                id_four: ApiService.user().account.id_entite,
            }, 'districloud_no_action')
            this.workflows = response.data.posts.post
        },

        async getTransporters() {
            let response = await this.$request.post('backoffice', {
                operation: 'get_transporteur',
            }, 'districloud_no_action')
            this.transporters = response.data.posts.post
        },

        async getStations() {
            let response = await this.$request.post('grossiste.get-stations')
            this.stations = response.data.posts.post
        },

        initForm() {
            this.form.id_proc = this.procedure ? this.procedure.id : null
            this.form.id_four = ApiService.user().account.id_entite
            this.form.id_workflow = this.procedure ? this.procedure.id_procedure_workflow : null
            this.form.duree = this.procedure ? this.procedure.garantie_mois : null
            this.form.type_garantie = this.procedure ? this.procedure.type_garantie : null
            this.form.date_fin = this.procedure && this.procedure.date_fin_prod ? moment(this.procedure.date_fin_prod).format('YYYY-MM-DD') : null
            this.form.message = this.procedure ? this.procedure.description_proc : null
            this.form.id_file_proc = this.procedure ? this.procedure.id_file_proc : null
            this.form.transport_aller = this.procedure ? this.procedure.pec_aller : null
            this.form.transporteur_aller = this.procedure ? this.procedure.transporteur : null
            this.form.transport_retour = this.procedure ? this.procedure.pec_retour : null
            this.form.transporteur_retour = this.procedure ? this.procedure.transport_retour : null
            this.form.extension_garantie = this.procedure ? this.procedure.extension_garantie : null
            this.form.id_sta = this.procedure ? this.procedure.id_sta : null
            this.form.pays = 'FR'
            this.form.to_resend = this.procedure ? this.procedure.to_resend : null
            this.form.space = this.space

            this.selectedWorkflowModal = this.workflows.find((item) => item.id == this.form.id_workflow)
            // this.form.facture_oblig = this.procedure ? this.procedure.num_fact_oblig : null
            // this.form.client_oblig = this.procedure ? this.procedure.saisie_client_oblig : null
            // this.form.numserie_oblig = this.procedure ? this.procedure.num_serie_oblig : null
            // this.form.date_fab = this.procedure ? this.procedure.date_fab_oblig : null
            // this.form.num_accord = this.procedure ? this.procedure.rca : null
            // this.form.plaque = this.procedure ? this.procedure.envoi_plaque : null
            // this.form.destruction = this.procedure ? this.procedure.destruction : null
            // this.form.justif_oblig = this.procedure ? this.procedure.justif_oblig : null
            // this.form.codepanne_oblig = this.procedure ? this.procedure.code_panne_oblig : null
            // this.form.photo = this.procedure ? this.procedure.photo_oblig : null
            // this.form.imei = this.procedure ? this.procedure.imei_oblig : null
            // this.form.photo_plaque = this.procedure ? this.procedure.photo_plaque : null
            // this.form.date_demande = this.procedure ? this.procedure.date_demande : null
            // this.form.emballage = this.procedure ? this.procedure.emballage : null
        },

        async saveForm(overwriteProc) {
            if (!this.form.id_workflow || this.form.id_workflow == 0) {
                this.$toast.error(this.$tt('Procédure obligatoire'))
                return
            }
            if (!this.form.duree && this.form.type_garantie != 'hors_garantie') {
                this.$toast.error(this.$tt('Durée de la garantie obligatoire'))
                return
            }
            if (!this.form.type_garantie) {
                this.$toast.error(this.$tt('Type de garantie obligatoire'))
                return
            }
            if (!this.form.space) {
                this.$toast.error(this.$tt("Merci de saisir un espace"))
                return
            }
            if (this.workflows.find((item) => item.id == this.form.id_workflow).sav != 1) {
                this.form.id_sta = null
            }

            this.loadingSave = true
            let form = Object.entries(this.form).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
            form.overwrite_proc = overwriteProc

            try {
                if (this.procedure && this.procedure.id) {
                    form.pattern_id = this.procedure.id
                }
                let response = await this.$request.post('procedures.edit-add-pattern', form)
                if (response.data.posts.post == 1) {
                    this.$emit('update')
                    if (this.procedure && this.procedure.id) {
                        this.$toast.success(this.$tt('Procédure modifiée'))
                    } else {
                        this.$toast.success(this.$tt('Procédure ajoutée'))
                    }
                }
            } catch (error) {
                console.log(error);
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.loadingSave = false
        },

        selectWorkflow() {
            this.form.id_workflow = this.selectedWorkflowModal.id
            this.$modal.hide('modal-select-workflow')
            this.displayWorkflow = false
            this.$nextTick(() => {
                this.displayWorkflow = true
                this.$forceUpdate()
            })
        },

        async deleteProc() {
            console.log('delete');
            // let response = await this.$request.post('backoffice', {
            //     operation: 'deletetypegar',
            //     id_proc: this.procedure.id
            // }, 'districloud_no_action')
            // if (response.data.posts.post == 1) {
            //     this.$toast.success('Type de garantie supprimé')
            //     this.$modal.hide('modal-confirm-delete-proc')
            //     this.$parent.$parent.refreshAfterDelete()
            // }
        },

        async checkHasSelfcare() {
            let response = await this.$request.post('module.check', {
                module: 'selfcare'
            })
            this.hasSelfcare = response.data.posts.post
        }
    },
    async mounted () {
        await this.getWorkflows()
        this.getTransporters()
        this.getStations()
        this.initForm()
        this.checkHasSelfcare()

        this.$eventHub.$on('validation-input-error', (error) => {
            if (error.id == 'workflow') {
                this.errorWorkflow = error.error
            }
        })
    },
}
</script>

<style lang="scss" scoped>

</style>