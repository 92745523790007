<template>
    <div>
        <div class="fixed top-0 left-0 right-0 py-4 bg-gray-800 text-white">
            <div class="container mx-auto flex items-center justify-between max-w-[900px]">
                <div class="flex items-center gap-8">
                    <img class="h-[40px]" src="https://disticloudfiles.net/upload/files/fd04f650272ed213928bbc748d72d9f1.png" alt="">
                    <div class="text-lg text-white/70 font-light">Espace documentation</div>
                </div>
                {{ $request.user()?.account?.email }}
            </div>
        </div>
        <div class="pt-[100px] h-screen overflow-y-auto">
            <div class="container mx-auto max-w-[900px]">
                <!-- <input type="text" class="w-full p-4 text-lg font-light rounded-full border focus:outline-none mt-10" placeholder="Rechercher un mot clé.."> -->
                <div class="mt-10">
                    <div class="text-4xl font-black my-10">{{ pages[Object.keys(pages)[0]][0].categorie }}</div>
                    <div v-for="(section, sectionIndex) in pages" :key="'section-' + sectionIndex" class="mb-10">
                        <div class="text-2xl font-semibold mb-4 text-gray-600">{{ sectionIndex }}</div>
                        <div v-for="(page, pageIndex) in section" :key="'page-' + pageIndex" class="pb-2 mb-2 border-b border-dashed">
                            <div @click="$router.push({name: 'aides.page', params: {categorie: $route.params.categorie, pageId: page.id}})" class="text-blue-500 hover:underline cursor-pointer"><i class="fas fa-link mr-0.5 text-xs"></i> {{ page.titre }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            pages: []
        }
    },
    methods: {
        async getPages() {
            this.pages = await this.$request.send('aides.get-categorie', {
                categorie_url: this.$route.params.categorie,
                no_user: 1
            })
        }
    },
    mounted() {
        this.getPages()
    }
}
</script>