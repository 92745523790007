<template>
    <div>
        <!-- Modal confirm delete -->
        <modal name="modal-confirm-delete" height="auto" :scrollable="true">
            <div class="p-10" v-if="selectedLine">
                <div class="flex items-center justify-between mb-4 pb-4 border-b text-black text-lg">
                    <h2 class="font-bold">
                        Confirmer la suppression
                    </h2>
                    <button @click="$modal.hide('modal-confirm-delete')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div>
                    <div class="text-lg">Souhaitez-vous supprimer ce tarif ?</div>
                    <div class="bg-slate-100 p-4 rounded mt-4">
                        <div class="border-b pb-1 mb-1">{{$tt('Entite')}}: <b>{{ selectedLine.entite_key }}</b></div>
                        <div class="border-b pb-1 mb-1">{{$tt('Catégorie')}}: <b>{{ selectedLine.categorie_key }}</b></div>
                        <div>{{$tt('Zone')}}: <b>{{ selectedLine.zone_intervention_key }}</b></div>
                    </div>
                    <div class="flex gap-2 items-center mt-4">
                        <button @click="$modal.hide('modal-confirm-delete')" class="px-4 py-2 rounded text-sm bg-slate-200 hover:bg-slate-300 duration-200">Annuler <i class="fas fa-times ml-1"></i></button>
                        <button :disabled="loadingDelete" @click="deleteTarif" class="px-4 py-2 rounded text-sm bg-red-500 hover:bg-red-600 duration-200 text-white">
                            <spinner :size="10" v-if="loadingDelete" />
                            <template v-if="!loadingDelete">
                                {{$tt('Supprimer')}} <i class="fas fa-trash ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>
        <!-- Modal add tarif -->
        <modal name="modal-add-tarif" height="auto" width="80%" :scrollable="true">
            <div class="p-10">
                <div class="flex items-center justify-between mb-4 pb-4 border-b">
                    <div>
                        <h2 class="text-xl font-semibold"><i class="fas fa-plus mr-1"></i> {{$tt('Nouveau tarif hors garantie')}}</h2>
                    </div>
                    <button @click="$modal.hide('modal-add-tarif')" class="text-2xl">
                        <i class="fas fa-times text-red-500"></i>
                    </button>
                </div>
                <div>
                    <div class="grid gap-4" :class="(form.zone && form.zone != 0 && form.zone != 'null') || (form.opti_tournee && form.opti_tournee != 0 && form.opti_tournee != 'null') ? 'grid-cols-2 xl:grid-cols-4' : 'grid-cols-2 xl:grid-cols-3'">
                        <div>
                            <div class="border bg-gray-50 rounded">
                                <div class="conf-cell-head px-4 py-2 rounded-t">
                                    <h2 class="text-sm font-bold"><i class="fas fa-cog mr-1"></i> {{ $tt('Configuration') }}</h2>
                                </div>
                                <div class="p-4">
                                    <!-- Entité -->
                                    <input-rule v-model="form.particulier" type="vselect" :options="{values: entites, label: 'label', key: 'particulier'}" :label-inline="false" :label="$tt('Entité')" class="mb-2" custom="bg-white" id="inp-type_client" />
                                    <!-- Catégorie -->
                                    <input-rule v-model="form.id_categ" type="vselect" :options="{values: categs, label: 'libelle', key: 'id'}" :label-inline="false" :label="$tt('Catégorie')" class="mb-2" custom="bg-white" id="inp-id_categ"/>
                                    <!-- Km/temps -->
                                    <input-rule v-if="iad" v-model="form.opti_tournee" type="pcheck" @input="((value) => {if (value) {form.zone = null}})" :label-inline="false" :tooltip="$tt('Option Payante - Optimisation de tournée intégrée dans la plateforme Districloud')" :label="$tt('IAD optimisation de tournée (Km/Temps)')" class="mb-2" />
                                    <!-- Zone -->
                                    <input-rule v-if="!form.opti_tournee && iad" v-model="form.zone" type="select" :options="{values: zones, label: 'nom', key: 'id'}" :label-inline="false" :tooltip="$tt('Séléctionner votre zone d\'action pour ce tarif en cas d\'intervention à domicile')" :label="$tt('Zone d\'intervention (Intervention à domicile)')" class="mb-2" custom="bg-white" />
                                </div>
                            </div>
                        </div>

                        <div v-if="(form.zone && form.zone != 0 && form.zone != 'null') || (form.opti_tournee && form.opti_tournee != 0 && form.opti_tournee != 'null')">
                            <div class="border bg-gray-50 rounded">
                                <div class="iad-cell-head px-4 py-2 rounded-t">
                                    <h2 class="text-sm font-bold"><i class="fas fa-truck mr-1"></i> {{ $tt('Intervention A Domicile (IAD)') }}</h2>
                                </div>
                                <div class="p-4">
                                    <!-- Forfait déplacement de base -->
                                    <input-rule v-model="form.deplacement_base" @input="calculAccompte" type="price" :label-inline="false" :tooltip="$tt('Tarif premier déplacement')" :label="$tt('Forfait déplacement de base') + ' (HT)'" class="mb-2" custom="bg-white" id="inp-deplacement_base" />
                                    <!-- Rayon kilométrique -->
                                    <input-rule v-model="form.km_ref" type="number" :label="$tt('Pour un rayon kilomètrique de') + ' ?'" :label-inline="false" :tooltip="$tt('Zone d\'intervention')" class="mb-2"/>
                                    <!-- Tarif km supp. -->
                                    <input-rule v-if="form.opti_tournee" v-model="form.km_supp" type="price" :label-inline="false" :tooltip="$tt('Se facture en supplément du forfait de déplacement de base')" :label="$tt('kilomètres supplémentaires') + ' (HT)'" class="mb-2" custom="bg-white"/>
                                    <div class="border-t-2 mt-4 pt-4">
                                        <!-- Tarif MO IAD -->
                                        <div class="mb-2">
                                            <input-rule v-model="form.mo_ext" type="price" :label-inline="false" class="mb-2" custom="bg-white" :tooltip="$tt('Votre coût de main d\'oeuvre, peut être un taux horaire ou un forfait')" :label="$tt('Tarif MO IAD') + ' (HT)'" />
                                            <div class="text-sm font-light flex gap-4">
                                                <div>
                                                    <input type="radio" v-model="form.mo_horaire_ext" value="horaire" id="horaire-ext" /> <label for="horaire-ext">{{$tt('Taux horaire')}}</label>
                                                </div>
                                                <div>
                                                    <input type="radio" v-model="form.mo_horaire_ext" value="forfait" id="forfait-ext" /> <label for="forfait-ext">{{$tt('Forfait')}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Forfait MO devis IAD -->
                                        <input-rule v-model="form.mo_devis_iad" type="price" :label-inline="false" :tooltip="$tt('Votre forfait main d\'oeuvre en cas de devis refusé')" :label="$tt('Forfait MO devis refusé') + ' (HT)'" class="mb-2" custom="bg-white" id="inp-mo_devis_iad" />
                                    </div>
                                    <div class="border-t-2 mt-4 pt-4">
                                        <!-- relivraison -->
                                        <input-rule v-model="form.relivraison" type="price" :label-inline="false" :tooltip="$tt('Si relivraison du produit rentré en atelier ou deuxième IAD pour pose d une nouvelle piece')" :label="$tt('Pose pièces ou relivraison') + ' (HT)'" class="mb-2" custom="bg-white"/>
                                        <!-- Supp encastré -->
                                        <input-rule v-model="form.supp_enc" type="price" :label-inline="false" :tooltip="$tt('Coût supplémentaire dans le cas où le produit nécessite un démontage')" :label="$tt('Supplément encastré') + ' (HT)'" class="mb-2" custom="bg-white"/>
                                    </div>
                                    <div class="border-t-2 mt-4 pt-4">
                                        <!-- Accompte -->
                                        <div class="mb-2">
                                            <input-rule v-model="form.accompte" type="price" :label-inline="false" class="mb-2" custom="bg-white" :tooltip="$tt('Mandat d\'acompte: (forfait deplacement de base + forfait MO devis refuse) * 1.20 (TVA)')" :label="$tt('Acompte') + ' (TTC)'" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="border bg-gray-50 rounded">
                                <div class="atelier-cell-head px-4 py-2 rounded-t">
                                    <h2 class="text-sm font-bold"><i class="fas fa-hammer mr-1"></i> {{ $tt("Atelier (Main d'oeuvre: MO)") }}</h2>
                                </div>
                                <div class="p-4">
                                    <!-- MO horaire atelier (ou forfait) -->
                                    <div class="mb-2">
                                        <input-rule v-model="form.mo_atelier" type="price" :label-inline="false" class="mb-2" custom="bg-white" :tooltip="$tt('Votre coût de main d\'oeuvre, peut être un forfait ou un taux horaire')" :label="$tt('Tarif MO atelier') + ' (HT)'" id="inp-mo_atelier"/>
                                        <div class="text-sm font-light flex gap-4">
                                            <div>
                                                <input type="radio" v-model="form.mo_horaire_atelier" value="horaire" id="horaire-atelier" /> <label for="horaire-atelier">{{$tt('Tarif horaire')}}</label>
                                            </div>
                                            <div>
                                                <input type="radio" v-model="form.mo_horaire_atelier" value="forfait" id="forfait-atelier" /> <label for="forfait-atelier">{{$tt('Tarif forfait')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Forfait MO devis atelier -->
                                    <input-rule v-model="form.devis_ref_base" @input="calculAccompte" type="price" :label-inline="false" :tooltip="$tt('Votre tarif en cas d\'un refus de devis')" :label="$tt('Forfait MO devis refusé') + ' (HT)'" class="mb-2" custom="bg-white" id="inp-devis_ref_base" />
                                    <!-- MO sans pièce -->
                                    <input-rule v-model="form.mo_sans_piece" type="price" :label-inline="false" :tooltip="$tt('Forfait si aucune piece changée (ne pas renseigné si non utilisé)')" :label="$tt('Forfait MO sans pièce') + ' (HT)'" class="mb-2" custom="bg-white"/>
                                    <!-- MO Acompte -->
                                    <input-rule v-model="form.mo_acompte" type="price" :label-inline="false" :tooltip="$tt('Acompte main d\'oeuvre TTC')" :label="$tt('Acompte MO') + ' (TTC)'" class="mb-2" custom="bg-white"/>

                                    <!-- MO PP/LIV -->
                                    <!-- <input-rule v-model="form.mo_pause_piece" type="price" :label-inline="false" :tooltip="$tt('Information')" :label="$tt('MO pause pièces')" class="mb-2" custom="bg-white"/> -->
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="border bg-gray-50 rounded">
                                <div class="complement-cell-head px-4 py-2 rounded-t">
                                    <h2 class="text-sm font-bold"><i class="fas fa-plus mr-1"></i> {{ $tt('Complément') }}</h2>
                                </div>
                                <div class="p-4">
                                    <!-- Frais de gestion -->
                                    <input-rule v-model="form.frais_gestion" type="price" :label-inline="false" :tooltip="$tt('Vos frais administratifs')" :label="$tt('Frais de gestion') + ' (HT)'" class="mb-2" custom="bg-white"/>
                                    <!-- Pret d'appareil -->
                                    <input-rule v-model="form.pret" type="price" :label-inline="false" :tooltip="$tt('Tarif à définir en cas de prêt d\'appareil')" :label="$tt('Prêt d\'appareil') + ' (HT)'" class="mb-2" custom="bg-white"/>
                                    <!-- Montant autorisé Atelier -->
                                    <input-rule v-model="form.seuil_devis_atelier" type="price" :label-inline="false" :tooltip="$tt('Montant de déclenchement d\'un devis en atelier')" :label="$tt('Montant autorisé avant devis (Atelier) (TTC)')" class="mb-2" custom="bg-white" id="inp-seuil_devis_atelier" />
                                    <!-- Montant autorisé Atelier -->
                                    <input-rule v-model="form.seuil_devis_atelier_do" type="price" :label-inline="false" :tooltip="$tt('Montant de déclenchement d\'un devis en atelier pour le donneur d\'ordre')" :label="$tt('Montant autorisé avant devis DO (Atelier) (TTC)')" class="mb-2" custom="bg-white" id="inp-seuil_devis_atelier_do" />
                                    <!-- Montant autorisé IAD -->
                                    <input-rule v-model="form.seuil_devis_iad" v-if="(form.zone && form.zone != 0 && form.zone != 'null') || (form.opti_tournee && form.opti_tournee != 0 && form.opti_tournee != 'null')" type="price" :label-inline="false" :tooltip="$tt('Montant de déclenchement d\'un devis en intervention à domicile')" :label="$tt('Montant autorisé avant devis (IAD) (TTC)')" class="mb-2" custom="bg-white" id="inp-seuil_devis_iad" />
                                    <!-- Port aller -->
                                    <input-rule v-model="form.port_aller" type="price" :label-inline="false" :tooltip="$tt('Montant du frais de port aller')" :label="$tt('Frais de port aller (HT)')" class="mb-2" custom="bg-white" id="inp-seuil_devis_iad" />
                                    <!-- Port retour -->
                                    <input-rule v-model="form.port_retour" type="price" :label-inline="false" :tooltip="$tt('Montant du frais de port retour')" :label="$tt('Frais de port retour(HT)')" class="mb-2" custom="bg-white" id="inp-seuil_devis_iad" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex items-center justify-end gap-4 border-t-2 pt-4 mt-8">
                        <input-rule v-model="addNewTarif" type="checkbox" :label="$tt('Ne pas fermer le formulaire')" class="mt-2" />
                        <!-- :disabled="loadingEditAdd" -->
                        <button @click="saveForm" class="button px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded">
                            <spinner :size="10" v-if="loadingEditAdd" />
                            <template v-if="!loadingEditAdd">
                                {{ $tt('Enregistrer') }} <i class="fas fa-check ml-1"></i>
                            </template>
                        </button>
                    </div>
                </div>
            </div>
        </modal>

        <div class="relative overflow-x-auto">
            <div v-if="loadingGetTarifs" class="p-2">
                <spinner :size="10" />
            </div>
            <div v-else>
                <div class="text-right">
                    <button @click="initForm(); $modal.show('modal-add-tarif')" class="px-4 py-2 bg-green-500 hover:bg-green-600 text-white rounded duration-200 mb-8 text-sm"><i class="fas fa-plus mr-1"></i> {{ $tt('Nouveau tarif hors garantie') }}</button>
                </div>
                <template v-if="tarifs && tarifs.length <= 0 && !tableHasLoading">
                    <div class="p-4 text-slate-300 text-xl text-right font-semibold -mt-4">{{ $tt("Vous pouvez créer votre premier tarif en cliquant sur le bouton ci-dessus") }} <i class="fas fa-arrow-up ml-4"></i></div>
                </template>
                <template v-if="(tarifs && tarifs.length > 0) || tableHasLoading">
                    <ve-table
                    :cell-style-option="{
                        headerCellClass: ({ column, rowIndex }) => {
                            if (column.key && column.key.split('-')[0] == 'conf') {
                                return 'conf-cell-head';
                            }
                            if (column.key && column.key.split('-')[0] == 'iad') {
                                return 'iad-cell-head';
                            }
                            if (column.key && column.key.split('-')[0] == 'atelier') {
                                return 'atelier-cell-head';
                            }
                            if (column.key && column.key.split('-')[0] == 'complement') {
                                return 'complement-cell-head';
                            }
                            if (column.key && column.key.split('-')[0] == 'action') {
                                return 'action-cell-head';
                            }
                        },
                        bodyCellClass: ({ column, rowIndex }) => {
                            if (column.key && column.key.split('-')[0] == 'action') {
                                return 'action-cell-body';
                            }
                        },
                    }"
                    :columns="columns"
                    :table-data="tarifs"
                    ></ve-table>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        iad: {
            required: true
        },
    },
    data() {
        return {
            form: {},
            entites: [
                {label: "Professionnel", particulier: 0},
                {label: "Particulier", particulier: 1},
            ],
            categs: [],
            zones: [],
            defaultListTarifs: [],
            tarifs: [],
            selectedLine: null,
            addNewTarif: false,
            tableHasLoading: false,
            
            columns: [],

            loadingDelete: false,
            loadingGetTarifs: false,
            loadingEditAdd: false,
        }
    },
    methods: {
        initColumns() {
            this.columns.push({
                title: '',
                fixed: 'left', 
                children: [
                    {
                        field: 'action',
                        key: 'action',
                        title: this.$tt('Actions'),
                        align: 'left',
                        // eslint-disable-next-line
                        renderBodyCell: ({row, column, rowIndex}, h) => {
                            return (
                                <div class="flex items-center gap-1">
                                    <button on-click={() => this.editLine(rowIndex)} class="py-1 px-1.5 bg-blue-500 hover:bg-blue-600 text-white rounded text-xs font-bold"><i class="fas fa-pen"></i></button>
                                    <button on-click={() => this.deleteLine(rowIndex)} class="py-1 px-1.5 bg-red-500 hover:bg-red-600 text-white rounded text-xs font-bold"><i class="fas fa-trash"></i></button>
                                    <button on-click={() => this.cloneLine(rowIndex)} class="py-1 px-1.5 bg-green-500 hover:bg-green-600 text-white rounded text-xs font-bold"><i class="fas fa-clone"></i></button>
                                </div>
                            );
                        }
                    },
                ],
            })
            
            if (this.iad) {
                this.columns.push({
                    title: this.$tt('Configuration'),
                    children: [
                        {
                            field: 'entite_key',
                            key: 'conf-entite_key',
                            title: this.$tt('Entite'),
                            align: 'left',
                            filter: {
                                filterList: [],
                                // filter confirm hook
                                filterConfirm: (filterList) => {
                                    const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                                    this.searchEntite(labels);
                                },
                                // filter reset hook
                                filterReset: () => {
                                    this.searchEntite([]);
                                },
                            },
                        },
                        {
                            field: 'categorie_key',
                            key: 'conf-categorie_key',
                            title: this.$tt('Catégorie'),
                            align: 'left',
                            width: '150px',
                            filter: {
                                filterList: [],
                                // filter confirm hook
                                filterConfirm: (filterList) => {
                                    const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                                    this.searchCateg(labels);
                                },
                                // filter reset hook
                                filterReset: () => {
                                    this.searchCateg([]);
                                },
                            },
                        },
                        {field: 'opti_tournee_key', key: 'conf-opti_tournee_key', title: this.$tt('Opti tournée'), align: 'left',},
                        {
                            field: 'zone_intervention_key',
                            key: 'conf-zone_intervention_key',
                            title: this.$tt('Type d\'intervention'),
                            align: 'left',
                            width: '150px',
                            filter: {
                                filterList: [],
                                // filter confirm hook
                                filterConfirm: (filterList) => {
                                    const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                                    this.searchZone(labels);
                                },
                                // filter reset hook
                                filterReset: () => {
                                    this.searchZone([]);
                                },
                            },
                        },
                    ],
                })
            } else {
                this.columns.push({
                    title: this.$tt('Configuration'),
                    children: [
                        {
                            field: 'entite_key',
                            key: 'conf-entite_key',
                            title: this.$tt('Entite'),
                            align: 'left',
                            filter: {
                                filterList: [],
                                // filter confirm hook
                                filterConfirm: (filterList) => {
                                    const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                                    this.searchEntite(labels);
                                },
                                // filter reset hook
                                filterReset: () => {
                                    this.searchEntite([]);
                                },
                            },
                        },
                        {
                            field: 'categorie_key',
                            key: 'conf-categorie_key',
                            title: this.$tt('Catégorie'),
                            align: 'left',
                            width: '150px',
                            filter: {
                                filterList: [],
                                // filter confirm hook
                                filterConfirm: (filterList) => {
                                    const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                                    this.searchCateg(labels);
                                },
                                // filter reset hook
                                filterReset: () => {
                                    this.searchCateg([]);
                                },
                            },
                        },
                        {
                            field: 'zone_intervention_key',
                            key: 'conf-zone_intervention_key',
                            title: this.$tt('Type d\'intervention'),
                            align: 'left',
                            width: '150px',
                            filter: {
                                filterList: [],
                                // filter confirm hook
                                filterConfirm: (filterList) => {
                                    const labels = filterList.filter((x) => x.selected).map((x) => x.value);
                                    this.searchZone(labels);
                                },
                                // filter reset hook
                                filterReset: () => {
                                    this.searchZone([]);
                                },
                            },
                        },
                    ],
                })
            }

            if (this.iad) {
                this.columns.push({
                    title: this.$tt('IAD'),
                    children: [
                        {field: 'deplacement_base_key', key: 'iad-deplacement_base', title: this.$tt('Forfait'), align: 'left',},
                        {field: 'km_ref_key', key: 'iad-km_ref', title: this.$tt('Rayon kilométrique'), align: 'left',},
                        {field: 'km_supp_key', key: 'iad-km_supp', title: this.$tt('kilomètres supplémentaires'), align: 'left',},
                        {field: 'mo_ext_key', key: 'iad-mo_ext', title: this.$tt('Tarif MO'), align: 'left',},
                        {field: 'mo_devis_iad_key', key: 'iad-mo_devis_iad', title: this.$tt('Forfait MO devis'), align: 'left',},
                        {field: 'relivraison_key', key: 'iad-relivraison', title: this.$tt('Re-livraison produit'), align: 'left',},
                        {field: 'supp_enc_key', key: 'iad-supp_enc', title: this.$tt('Supplément encastré'), align: 'left',},
                        {field: 'acompte_key', key: 'iad-acompte', title: this.$tt('Acompte IAD'), align: 'left',},
                    ],
                })
            }

            this.columns.push({
                title: this.$tt('Atelier'),
                children: [
                    {field: 'mo_horaire_atelier_key', key: 'atelier-mo_horaire_atelier', title: this.$tt('Facturation'), align: 'left',},
                    {field: 'mo_atelier_key', key: 'atelier-mo_atelier', title: this.$tt('MO tarif'), align: 'left',},
                    {field: 'devis_ref_base_key', key: 'atelier-devis_ref_base', title: this.$tt('MO forfait devis'), align: 'left',},
                    {field: 'mo_sans_piece_key', key: 'atelier-mo_sans_piece', title: this.$tt('MO sans pièce'), align: 'left',},
                    {field: 'mo_acompte_key', key: 'atelier-mo_acompte', title: this.$tt('MO acompte'), align: 'left',},
                    // {field: 'mo_pause_piece_key', key: 'atelier-mo_pause_piece', title: this.$tt('MO pause pièces'), align: 'left',},
                ],
            })

            this.columns.push({
                title: this.$tt('Complément'),
                children: [
                    {field: 'seuil_devis_atelier_key', key: 'complement-seuil_devis_atelier', title: this.$tt('Montant autorisé avant devis (Atelier) (TTC)'), align: 'left',},
                    {field: 'seuil_devis_atelier_do_key', key: 'complement-seuil_devis_do_atelier', title: this.$tt('Montant autorisé avant devis DO (Atelier) (TTC)'), align: 'left',},
                    {field: 'seuil_devis_iad_key', key: 'complement-seuil_devis_iad', title: this.$tt('Montant autorisé avant devis (IAD) (TTC)'), align: 'left',},
                    {field: 'frais_gestion_key', key: 'complement-frais_gestion', title: this.$tt('Frais de gestion'), align: 'left',},
                    {field: 'pret_key', key: 'complement-pret', title: this.$tt('Prêt d\'appareil'), align: 'left',},
                    {field: 'frais_port_aller', key: 'complement-port_aller', title: this.$tt('Port aller'), align: 'left',},
                    {field: 'frais_port_retour', key: 'complement-port_retour', title: this.$tt('Port retour'), align: 'left',},
                ],
            })
        },
        // async getEntites() {
        //     let response = await this.$request.post('reparateur.get-centrales-by-activites')
        //     this.entites = response.data.posts.post
            
        //     if (!this.entites) {
        //         this.entites = []
        //     }
        //     this.entites.unshift({id: -1, centrale_ha: 'Votre tarif aux particuliers'})

        //     let filters = []
        //     this.entites.forEach((item) => {
        //         filters.push({value: item.id, label: item.centrale_ha, selected: false},)
        //     })
        //     this.columns[1].children[0].filter.filterList = filters
        // },

        async getCategs() {
            let response = await this.$request.post('reparateur.get-available-categories', {
                hg: 1
            })
            this.categs = response.data.posts.post.map((item) => ({
                ...item,
                label: item.libelle,
                selected: false,
                value: item.id
            }))
            let filters = []
            this.categs.forEach((item) => {
                filters.push({value: item.id, label: item.label, selected: false},)
            })
            this.columns[1].children[1].filter.filterList = filters
        },

        async getZones() {
            let response = await this.$request.post('reparateur.get-zones')
            this.zones = response.data.posts.post
            this.zones.unshift({
                id: 0,
                nom: this.$tt('Aucune zone')
            })

            let filters = []
            this.zones.forEach((item) => {
                filters.push({value: item.id, label: item.nom, selected: false},)
            })

            this.columns[1].children[3].filter.filterList = filters
        },

        async getListTarifs() {
            this.loadingGetTarifs = true
            let response = await this.$request.post('reparateur.get-tarifs')
            this.tarifs = response.data.posts.post.map((item) => ({
                ...item,
                entite_key: item.particulier == 1 ? this.$tt('Particulier') : this.$tt('Professionnel'),
                categorie_key: item.categorie?.libelle || '-',
                zone_intervention_key: item.zone_intervention?.nom ? 'IAD: ' + item.zone_intervention?.nom : 'Atelier',
                mo_horaire_atelier_key: item.mo_horaire_atelier == 1 ? this.$tt('Horaire') : this.$tt('Forfait'),
                opti_tournee_key: item.opti_tournee == 1 ? this.$tt('Oui') : this.$tt('Non'),
                deplacement_base_key: parseFloat(item.deplacement_base).toFixed(2) + '€',
                km_ref_key: parseFloat(item.km_ref).toFixed(2) + '€',
                km_supp_key: parseFloat(item.km_supp).toFixed(2) + '€',
                mo_ext_key: parseFloat(item.mo_ext).toFixed(2) + '€',
                devis_ref_base_key: parseFloat(item.devis_ref_base).toFixed(2) + '€',
                relivraison_key: parseFloat(item.relivraison).toFixed(2) + '€',
                supp_enc_key: parseFloat(item.supp_enc).toFixed(2) + '€',
                mo_atelier_key: parseFloat(item.mo_atelier).toFixed(2) + '€',
                mo_devis_iad_key: parseFloat(item.mo_devis_iad).toFixed(2) + '€',
                mo_sans_piece_key: parseFloat(item.mo_sans_piece).toFixed(2) + '€',
                mo_pause_piece_key: parseFloat(item.mo_pause_piece).toFixed(2) + '€',
                seuil_devis_atelier_key: parseFloat(item.seuil_devis_atelier).toFixed(2) + '€',
                seuil_devis_atelier_do_key: parseFloat(item.seuil_devis_atelier_do).toFixed(2) + '€',
                seuil_devis_iad_key: parseFloat(item.seuil_devis_iad).toFixed(2) + '€',
                frais_gestion_key: parseFloat(item.frais_gestion).toFixed(2) + '€',
                frais_port_aller: parseFloat(item.port_aller).toFixed(2) + '€',
                frais_port_retour: parseFloat(item.port_retour).toFixed(2) + '€',
                pret_key: parseFloat(item.pret).toFixed(2) + '€',
                mo_acompte_key: parseFloat(item.mo_acompte).toFixed(2) + '€',
                acompte_key: parseFloat(item.accompte).toFixed(2) + '€',
            }))
            
            this.defaultListTarifs = [...this.tarifs]
            this.loadingGetTarifs = false
            this.tableHasLoading = false
        },

        initForm() {
            this.form = {
                type_client: null,
                id_categ: null,
                zone: 0,
                deplacement_base: '0.00',
                km_ref: '0',
                deplacement: '0.00',
                km_supp: '0.00',
                supp_enc: '0.00',
                mo_atelier: '0.00',
                mo_devis_iad: '0.00',
                mo_sans_piece: '0.00',
                devis_ref_base: '0.00',
                mo_horaire_atelier: 'horaire',
                mo_horaire_ext: 'horaire',
                mo_ext: '0.00',
                frais_gestion: '0.00',
                pret: '0.00',
                seuil_devis_atelier: '150.00',
                seuil_devis_atelier_do: '150.00',
                seuil_devis_iad: '150.00',
                mo_pause_piece: '0.00',
                relivraison: '0.00',
                acompte: '0.00',
                mo_acompte: '0.00',
                port_aller: '0.00',
                port_retour: '0.00',
                opti_tournee: 0,
                particulier: 0,
            }
        },

        async saveForm() {
            // If type_client is Vos tarifs aux particuliers
            if (this.form.particulier == 0) {
                let validationRules = []                

                if (this.form.zone != 0 || this.form.opti_tournee) {                    
                    validationRules = [
                        // {value: this.form.particulier, rules: 'required', id: 'inp-type_client'},
                        {value: this.form.id_categ, rules: 'required', id: 'inp-id_categ'},
                        {value: this.form.mo_atelier, rules: 'required|min:0.01', id: 'inp-mo_atelier'},
                        {value: this.form.mo_devis_iad, rules: 'required|min:0.01', id: 'inp-mo_devis_iad'},
                        {value: this.form.seuil_devis_atelier, rules: 'required|min:0.01', id: 'inp-seuil_devis_atelier'},
                        {value: this.form.seuil_devis_atelier_do, rules: 'required|min:0.01', id: 'inp-seuil_devis_atelier_do'},
                        {value: this.form.deplacement_base, rules: 'required|min:0.01', id: 'inp-deplacement_base'},
                        {value: this.form.seuil_devis_iad, rules: 'required|min:0.01', id: 'inp-seuil_devis_iad'},
                    ]
                } else {
                    validationRules = [
                        // {value: this.form.particulier, rules: 'required', id: 'inp-type_client'},
                        {value: this.form.id_categ, rules: 'required', id: 'inp-id_categ'},
                        {value: this.form.mo_atelier, rules: 'required|min:0.01', id: 'inp-mo_atelier'},
                        {value: this.form.seuil_devis_atelier, rules: 'required|min:0.01', id: 'inp-seuil_devis_atelier'},
                        {value: this.form.seuil_devis_atelier_do, rules: 'required|min:0.01', id: 'inp-seuil_devis_atelier_do'},
                        {value: this.form.devis_ref_base, rules: 'required|min:0.01', id: 'inp-devis_ref_base'},
                    ]
                }
                if (!this.$validation.check(validationRules)) {
                    this.$toast.error(this.$tt('Formulaire incomplet'))
                    return
                }
            } else {
                if (!this.$validation.check([
                    {value: this.form.particulier, rules: 'required', id: 'inp-type_client'},
                    {value: this.form.id_categ, rules: 'required', id: 'inp-id_categ'},
                ])) {
                    this.$toast.error(this.$tt('Formulaire incomplet'))
                    return
                }
            }

            // Reset amount on IAD
            if ((!this.form.zone || this.form.zone == 0) && !this.form.opti_tournee) {
                this.form.deplacement_base = '0.00'
                this.form.km_supp = '0.00'
                this.form.mo_ext = '0.00'
                this.form.mo_horaire_ext = 0
                this.form.mo_horaire_ext = '0.00'
                this.form.mo_devis_iad = '0.00'
                this.form.relivraison = '0.00'
                this.form.supp_enc = '0.00'
            }

            // Save data
            let endData = this.form
            if (endData.id) {
                endData.line_id = endData.id
                delete endData.id
            }

            // Add leading 0
            let leadingKeys = ["deplacement_base", "deplacement", "km_supp", "supp_enc", "mo_atelier", "mo_devis_iad", "mo_sans_piece", "devis_ref_base", "mo_ext", "frais_gestion", "pret", "seuil_devis_atelier", "seuil_devis_atelier_do", "seuil_devis_iad", "mo_pause_piece", "relivraison", "port_aller", "port_retour"]
            leadingKeys.forEach((leadingKey) => {
                endData[leadingKey] = parseFloat(endData[leadingKey]).toFixed(2)
            })

            this.loadingEditAdd = true
            let response = await this.$request.post('reparateur.edit-add-tarif', endData)
            if (response.data.posts.post == 1) {
                this.$toast.success('Tarif ajouté')
                await this.getListTarifs()
                if (!this.addNewTarif) {
                    this.$modal.hide('modal-add-tarif')
                    this.initForm()
                } else {
                    this.form.id_categ = null
                }
                this.loadingEditAdd = false
            } else {
                this.loadingEditAdd = false
            }
        },

        async deleteTarif() {
            this.loadingDelete = true
            let response = await this.$request.post('reparateur.delete-tarif', {
                line_id: this.selectedLine.id
            })
            if (response.data.posts.post == 1) {
                await this.getListTarifs()
                this.$modal.hide('modal-confirm-delete')
            } else {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.loadingDelete = false
        },

        editLine(row) {
            this.form = this.tarifs[row]
            this.form.mo_horaire_atelier = this.form.mo_horaire_atelier == 1 ? 'horaire' : 'forfait'
            this.form.mo_horaire_ext = this.form.mo_horaire_ext == 1 ? 'horaire' : 'forfait'
            this.$modal.show('modal-add-tarif')
        },

        deleteLine(row) {
            this.selectedLine = this.tarifs[row];
            this.$modal.show('modal-confirm-delete')
        },

        cloneLine(row) {
            this.form = this.tarifs[row]
            delete this.form.id
            this.$modal.show('modal-add-tarif')
        },

        searchEntite(typeEntiteId) {
            if (typeEntiteId.length == 0) {
                this.tarifs = this.defaultListTarifs
            } else {
                this.tarifs = this.defaultListTarifs.filter((item) => item.type_client == typeEntiteId)
            }
        },

        searchCateg(categId) {
            if (categId.length == 0) {
                this.tarifs = this.defaultListTarifs
            } else {
                this.tarifs = this.defaultListTarifs.filter((item) => item.id_categ == categId)
            }
        },

        searchZone(zoneId) {
            if (zoneId.length == 0) {
                this.tarifs = this.defaultListTarifs
            } else {
                this.tarifs = this.defaultListTarifs.filter((item) => item.zone == zoneId)
            }
        },

        calculAccompte() {
            let acompte = ((parseFloat(this.form.deplacement_base) + parseFloat(this.form.mo_devis_iad)) * 1.20) || '0.00'
            if (acompte && acompte > 0) {
                this.form.accompte = acompte
            } else {
                this.form.accompte = '0.00'
            }
        }

        // setSearchCateg(e) {
        //     this.searchCategInp = e.target.value
        // },

        // removeSearchCateg() {
        //     this.searchCategInp = null
        //     this.tarifs = this.defaultListTarifs
        // },

        // searchCateg() {
        //     if (this.searchCategInp) {
        //         this.tarifs = this.defaultListTarifs.filter((item) => item.categorie_key.toLowerCase().includes(this.searchCategInp.toLowerCase()))
        //     }
        // }
    },
    mounted () {
        // this.getEntites()
        this.getCategs()
        this.getZones()
        this.getListTarifs()
        this.initForm()
        this.initColumns()
    },
}
</script>

<style>
.ve-table-header-th {
    padding: 5px 10px 5px 10px !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.complement-cell-head {
    font-weight: light !important;
}

.ve-table-body-td {
    padding: 5px 10px 5px 10px !important;
    font-size: 13px !important;
    border-left: 1px solid #E4E4E4 !important;
    border-bottom: 3px solid #E4E4E4 !important;
}

.ve-table-filter-icon {
    color: white !important;
}


.conf-cell-head {
    background: #6681d7 !important;
    color: #fff !important;
}
.iad-cell-head {
    background: #87a474 !important;
    color: #fff !important;
}
.atelier-cell-head {
    background: #a48a74 !important;
    color: #fff !important;
}
.complement-cell-head {
    background: #b8ac4b !important;
    color: #fff !important;
}
.action-cell-head {
    background: #444 !important;
    color: #fff !important;
}

.action-cell-body {
    background: #F4F4F4 !important;
}
</style>