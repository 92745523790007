<template>
    <div>
        <template v-if="displayValue">
            <display-value :type="type" :label="label" :data="inpValue" :options="options" />
        </template>
        <template v-else>
            <div :class="labelInline ? 'flex items-center justify-between gap-3' : ''">
                <!-- LABEL -->
                <div class="flex gap-1.5 items-center mb-1">
                    <tooltip v-if="tooltip" :msg="tooltip" />
                    <label v-if="label && (!options || !options.reverse)" :for="uniqueId" class="text-xs font-bold" :class="displayErrors && errors.length > 0 ? 'text-red-500' : 'text-gray-900'" v-html="label"></label>
                </div>
                <!-- TYPES -->
                <!-- Text -->
                <input
                v-if="type == 'text'"
                :placeholder="placeholder"
                v-model="inpValue"
                @focusout="$emit('focusout')"
                type="text"
                :id="uniqueId"
                :disabled="disabled"
                class="bg-slate-50 text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400"
                :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg')"
                @focusin="$emit('focusin')"
                />

                <!-- Text -->
                <input
                v-if="type == 'password'"
                :placeholder="placeholder"
                v-model="inpValue"
                @focusout="$emit('focusout')"
                type="password"
                :id="uniqueId"
                :disabled="disabled"
                class="bg-slate-50 text-sm rounded-0 grow w-full p-1.5 focus:outline-none focus:border-gray-400"
                :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg')"
                @focusin="$emit('focusin')"
                />
        
                <!-- Textarea -->
                <textarea v-if="type == 'textarea'" :rows="options && options.rows ? options.rows : 5" v-model="inpValue" @focusout="$emit('focusout')" :id="uniqueId" :disabled="disabled" class="bg-gray-50 text-sm rounded-lg block w-full p-1.5 focus:outline-none focus:border-gray-400" :class="inputStyle" :placeholder="placeholder"></textarea>

                <!-- Number -->
                <input v-if="type == 'number'" v-model="inpValue" @focusout="$emit('focusout')" type="number" :id="uniqueId" :disabled="disabled" class="text-sm rounded-0 block w-full p-1.5 focus:outline-none focus:border-gray-400" :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg bg-gray-50')" :min="options && options.min != null ? options.min : null" :max="options && options.max != null ? options.max : null" :placeholder="placeholder" />
        
                <!-- Date -->
                <input v-if="type == 'date'" v-model="inpValue" type="date" :min="options ? options.min : null" :max="options ? options.max : null" :id="uniqueId" :disabled="disabled" class="bg-gray-50 w-full text-sm rounded-lg grow p-1.5 focus:outline-none" :class="inputStyle" />
                <input v-if="type == 'datetime'" v-model="inpValue" type="datetime-local" :min="options ? options.min : null" :max="options ? options.max : null" :id="uniqueId" :disabled="disabled" class="bg-gray-50 w-full text-sm rounded-lg grow p-1.5 focus:outline-none" :class="inputStyle" />
                <input v-if="type == 'time'" v-model="inpValue" type="time" :min="options ? options.min : null" :max="options ? options.max : null" :id="uniqueId" :disabled="disabled" class="bg-gray-50 w-full text-sm rounded-lg grow p-1.5 focus:outline-none" :class="inputStyle" />

                <!-- Date range -->
                <date-range-picker
                v-if="type == 'date-range' && inpValue"
                v-model="inpValue"
                :locale-data="datePickerOptions"
                :opens="options && options.direction ? options.direction : null"
                :single-date-picker="options && options.single ? 'range' : null"
                control-container-class="text-sm block w-full p-1.5 focus:outline-none rounded-lg border border-gray-300 bg-gray-50"
                >
                    <template v-slot:input="picker" style="min-width: 350px; border: 1px solid #E4E4E4" class="border rounded-xl">
                        <div class="flex items-center justify-between" v-if="picker.startDate">
                            {{ picker.startDate | momentDate}} - {{ picker.endDate | momentDate }}
                            <button v-if="inpValue.startDate || inpValue.endDate" @click="removeDateRangePicker" class="text-sm text-red-500 ml-2 mr-1">
                                <i class="fas fa-times"></i>
                            </button>
                        </div>
                    </template>
                </date-range-picker>
        
                <!-- Select -->
                <template v-if="type == 'select'">
                    <select v-if="options && options.values" v-model="inpValue" :id="uniqueId"  :disabled="disabled" class="bg-gray-50 text-sm rounded-lg block w-full p-1.5 focus:outline-none" :class="inputStyle" :placeholder="placeholder">
                        <option v-for="(selectItem, selectItemIndex) in options.values" :key="uniqueId + '-select-' + selectItemIndex" :value="options.key ? selectItem[options.key] : selectItem" :disabled="selectItem.disabled">
                            {{ options.label ? selectItem[options.label] : selectItem }}
                        </option>
                    </select>
                </template>

                <!-- Multiselect -->
                <template v-if="type == 'multiselect'">
                    <multiselect
                    v-model="inpValue"
                    :options="options.values"
                    :multiple="options.multiple ? options.multiple : true"
                    :label="options.label"
                    :track-by="options.keyTrack"
                    :placeholder="placeholder"
                    class="w-full"
                    >
                        <template v-slot:noOptions>
                            Aucun résultat
                        </template>
                        <template v-slot:noResult>
                            Aucun résultat
                        </template>
                    </multiselect>
                </template>
                
                <!-- Smart select -->
                <template v-if="type == 'smart-select'">
                    <!-- :default-items="[{name: 'test'}, {name: 'test2'}]" -->
                    <smart-select
                    class="grow"
                    :api="options.api"
                    :label="options.label"
                    :key-track="options.key"
                    :multiple="options.multiple"
                    />
                </template>

                <template v-if="type == 'api-select'">
                    <!-- :default-items="[{name: 'test'}, {name: 'test2'}]" -->
                    <api-select
                    :label-inline="labelInline"
                    v-model="inpValue"
                    class="grow"
                    :placeholder="placeholder"
                    :api="options.api"
                    :label="options.label"
                    :label2="options.label2"
                    :key-track="options.keyTrack"
                    :init="options.init"
                    :min="options.min"
                    :uppercase="options.uppercase"
                    :default-value="options.defaultValue"
                    :disabled="disabled"
                    :id="id"
                    @inp="(value) => {$emit('inp', value)}"
                    :has-error="this.errors.length > 0 && this.displayErrors"
                    />
                </template>

                <template v-if="type == 'vselect'">
                    <v-select
                    v-if="options.key"
                    v-model="inpValue"
                    :options="options && options.values ? options.values : []"
                    :reduce="item => item[options.key]"
                    class="grow"
                    :class="errors && errors.length > 0 ? 'border-2 border-red-500 rounded' : ''"
                    :label="options ? options.label : null"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    >
                        <span slot="no-options" @click="$refs.select.open = false">
                            Aucun résultat
                        </span>
                    </v-select>
                    <v-select
                    v-if="!options.key"
                    v-model="inpValue"
                    :options="options && options.values ? options.values : []"
                    class="grow"
                    :label="options ? options.label : null"
                    >
                        <span slot="no-options" @click="$refs.select.open = false">
                            Aucun résultat
                        </span>
                    </v-select>
                </template>
        
                <!-- Address -->
                <address-input v-if="type == 'address'" :default-value="options && options.defaultValue ? options.defaultValue : null" :all-ways="options && options.allWays ? options.allWays : null" class="grow text-sm rounded-0 block w-full focus:outline-none focus:border-gray-400" :country="(options && options.country ? options.country : 'FR')" :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg bg-gray-50')" :custom="custom" @get="emitAddress" />

                <!-- Address2 -->
                <address-input2 v-if="type == 'address2'" :default="options && options.defaultValue ? options.defaultValue : null" class="grow text-sm rounded-0 block w-full focus:outline-none focus:border-gray-400 bg-gray-100" :country="(options && options.country ? options.country : 'FR')" :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg bg-gray-50')" :custom="custom + (labelInline ? ' bg-slate-50' : '')" :placeholder="placeholder" v-model="inpValue" />
        
                <!-- File -->
                <input-file v-if="type == 'file'" :class="this.errors.length > 0 && this.displayErrors ? 'border-2 border-red-500 text-red-500 rounded-xl' : 'text-gray-900'" :id="id ? id : uniqueId" v-model="inpValue" :default-data="options && options.defaultData ? options.defaultData : (typeof inpValue == 'string' ? {url: inpValue} : (typeof inpValue == 'object' ? inpValue : null))" :preview="options && options.preview !== null ? options.preview : null" />
        
                <!-- Pcheck -->
                <template v-if="type == 'pcheck'">
                    <div v-if="labelInline" class="border-b grow border-dotted border-gray-300"></div>
                    <p-check v-model="inpValue" color="success" class="p-switch p-fill" :disabled="disabled"></p-check>
                </template>

                <!-- Checkbox -->
                 <template v-if="type == 'checkbox'">
                    <div :class="label && (options && options.reverse) ? 'flex gap-0.5 items-center' : ''">
                        <input v-model="inpValue" @focusout="$emit('focusout')" type="checkbox" :id="uniqueId"  :disabled="disabled" class="bg-gray-50 text-sm rounded-lg p-2.5 focus:outline-none -mt-2 mr-2" :class="inputStyle" />
                        <label v-if="label && (options && options.reverse)" :for="uniqueId" class="block mb-1 text-xs font-bold" :class="displayErrors && errors.length > 0 ? 'text-red-500' : 'text-gray-900'" v-html="label"></label>
                    </div>
                 </template>

                <!-- Prix -->
                <currency-input v-if="type == 'price'" v-model="inpValue" :label-inline="labelInline" :disabled="disabled" :display-currency="options?.displayCurrency" :custom="inputStyle" :placeholder="placeholder" class="grow" @focusout="$emit('focusout')" />

                <!-- Tel -->
                <tel-input v-if="type == 'tel'" :options="options" v-model="inpValue" :has-error="errors && errors.length > 0" class="w-full" />

                <!-- Color -->
                <input v-if="type == 'color'" v-model="inpValue" type="color" :id="uniqueId" :disabled="disabled" class="text-sm rounded-0 block w-full p-1.5 focus:outline-none focus:border-gray-400" :class="inputStyle + ' ' + (labelInline ? 'border-0 border-b' : 'rounded-lg bg-gray-50')" />

                <!-- Richtextbox -->
                <quill-editor
                :style="options && options.height ? ('min-height:' + options.height + 'px;') : ''"
                v-if="type == 'richtext'"
                :disabled="disabled"
                ref="quillEditor"
                class="editor bg-white w-full"
                v-model="inpValue"
                :options="{
                    placeholder: '',
                    modules: {
                        toolbar: [['bold', 'italic', 'underline', 'strike'], [{ 'align': [] }], ['clean'], [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }]]
                    },
                }"
                />
            </div>
        </template> 

        <!-- ERRORS -->
        <ul v-if="displayErrors && errors.length > 0" class="text-xs list-disc ml-3 mt-2 text-red-500 font-light">
            <li v-for="(error, errorIndex) in errors" :key="uniqueId + '-error-' + errorIndex">
                {{ error }}
            </li>
        </ul>
    </div>
</template>

<script>
import moment from 'moment'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import CurrencyInput from './InputCurrency.vue'

import { quillEditor } from "quill-vuejs";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
    components: {
        DateRangePicker,
        CurrencyInput,
        quillEditor
    },
    props: {
        type: {required: true},
        label: {},
        value: {},
        rules: {},
        options: {},
        displayErrors: {default: true},
        labelInline: {default: true},
        disabled: {default: false},
        custom: {},
        displayValue: {default: false},
        placeholder: {},
        id: {},
        tooltip: {}
    },

    watch: {
        value: {
            deep: true,
            handler: function() {
                this.inpValue = this.value
            }
        },
        inpValue: {
            deep: true,
            handler: function() {
                this.emitValue()
            }
        }
    },

    data() {
        return {
            inpValue: null,
            errors: [],

            datePickerOptions: {
                direction: 'ltr',
                format: 'dd/mm/yyyy',
                separator: ' - ',
                applyLabel: 'Appliquer',
                cancelLabel: 'Annuler',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
                monthNames: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
                firstDay: 1
            },
        }
    },

    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        }
    },

    computed: {
        uniqueId() {
            return 'sav-input-' + this._uid
        },

        inputStyle() {
            return (this.errors.length > 0 && this.displayErrors ? 'border-2 border-red-500 text-red-500' : 'border border-gray-300 text-gray-900') + ' ' + (this.disabled ? 'cursor-not-allowed' : '') + ' ' + this.custom
        }
    },

    methods:{
        rulesValidated() {
            this.errors = []
            if (this.rules) {
                const rules = this.rules.split('|')
                const rulesTools = {
                    required: () => {
                        if (!this.inpValue || this.inpValue === null || this.inpValue === '') {
                            this.errors.push(this.$tt('Champ obligatoire'))
                        }
                    },

                    tel: () => {
                        var regex = new RegExp(/^(01|02|03|04|05|06|07|08|09)[0-9]{8}/gi);
                        if (this.inpValue && !regex.test(this.inpValue)) {
                            this.errors.push(this.$tt('Numéro invalide'))
                        }
                    },

                    num: () => {
                        if (this.inpValue && isNaN(this.inpValue)) {
                            this.errors.push(this.$tt('Le champ doit être un chiffre'))
                        }
                    },

                    min: (nb) => {
                        if (this.inpValue && Number(this.inpValue) < nb) {
                            this.errors.push(this.$tt('Minimum: ') + nb)
                        }
                    },

                    email: () => {
                        if (this.inpValue && !String(this.inpValue).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                            this.errors.push(this.$tt('Email invalide'))
                        }
                    },

                    tvaintra: () => {
                        var regex = new RegExp(/^(ATU[0-9]{8}|BE[01][0-9]{9}|BG[0-9]{9,10}|HR[0-9]{11}|CY[A-Z0-9]{9}|CZ[0-9]{8,10}|DK[0-9]{8}|EE[0-9]{9}|FI[0-9]{8}|FR[0-9A-Z]{2}[0-9]{9}|DE[0-9]{9}|EL[0-9]{9}|HU[0-9]{8}|IE([0-9]{7}[A-Z]{1,2}|[0-9][A-Z][0-9]{5}[A-Z])|IT[0-9]{11}|LV[0-9]{11}|LT([0-9]{9}|[0-9]{12})|LU[0-9]{8}|MT[0-9]{8}|NL[0-9]{9}B[0-9]{2}|PL[0-9]{10}|PT[0-9]{9}|RO[0-9]{2,10}|SK[0-9]{10}|SI[0-9]{8}|ES[A-Z]([0-9]{8}|[0-9]{7}[A-Z])|SE[0-9]{12}|GB([0-9]{9}|[0-9]{12}|GD[0-4][0-9]{2}|HA[5-9][0-9]{2}))/);
                        if (this.inpValue && !regex.test(this.inpValue)) {
                            this.errors.push(this.$tt('Numéro TVA incorrect'))
                        }
                    }
                }

                rules.forEach(rule => {
                    if (rule.split(':')[0] == 'min') {
                        rulesTools[rule.split(':')[0]](rule.split(':')[1])
                    } else {
                        if (rulesTools[rule]) {
                            rulesTools[rule]()
                        }
                    }
                });
            }
            return (this.errors.length > 0) ? false : true
        },

        emitAddress(address) {
            this.inpValue = address
            this.$emit('get', address)
        },

        emitValue() {
            const isValid = this.rulesValidated()
            if (this.type == 'pcheck') {
                this.inpValue = (this.inpValue == true ? 1 : 0)
            }
            if (isValid) {
                this.$emit('input', this.inpValue)
            } else {
                this.$emit('input', null)
            }
        },
        removeDateRangePicker() {
            this.inpValue = {
                startDate: null,
                endDate: null,
            }
        }
    },

    mounted() {
        if (this.value && this.value.value) {
            this.inpValue = this.value.value
        } else {
            this.inpValue = this.value
        }
        this.emitValue()
        this.rulesValidated()

        if (this.type == 'date-range' && !this.value) {
            this.inpValue = {
                minDate: null,
                maxDate: null,
            }
        }

        this.$eventHub.$on('validation-input-error', (error) => {
            if (error.id && this.id == error.id) {
                this.errors.push(error.error)
            }
        })
        this.$eventHub.$on('clear-input-error', () => {
            this.errors = []
        })
    }
}
</script>

<style>
/* @import "~vue-wysiwyg/dist/vueWysiwyg.css";*/

.multiselect__tags {
    min-height: 32px;
    display: block;
    padding: 0.375rem;
    border-radius: 5px;
    border: 1px solid rgb(229 231 235);
    background-color: rgb(249 250 251);
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.5rem;
}

.multiselect__tags .multiselect__input {
    background-color: rgb(249 250 251);
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 0px;
}

.multiselect__select {
    height: 34px !important;
}

.multiselect__content-wrapper {
    font-size: 0.875rem !important;
}

.ql-container{
  min-height: inherit;
}

.vs__dropdown-toggle {
    background: #F9FAFB;
}
.vs__search {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.quill-editor svg {
    height: 15px !important;
}

.ql-toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.ql-container {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ql-toolbar.ql-snow {
    padding: 1px !important;
    background: #F4F4F4;
}
</style>
