<template>
    <div>
        <modal name="modal-confirm-delete-piece" height="auto" :scrollable="true">
            <div class="flex items-center justify-end px-4 py-4 border-b">
                <button @click="$modal.hide('modal-confirm-delete-piece')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div v-if="selectedPiece" class="p-4">
                <div class="text-lg bg-red-100 p-4 rounded text-red-800 text-center">
                    <div>Souhaitez-vous réellement supprimer cette pièce ?</div>
                    <div class="flex gap-8 mt-3 pt-3 justify-center border-t border-red-800">
                        <div class="text-sm">Désignation: <b>{{ selectedPiece.designation }}</b></div>
                        <div class="text-sm">Référence: <b>{{ selectedPiece.ref }}</b></div>
                    </div>
                </div>
                <div class="flex items-center justify-center gap-4 mt-4">
                    <button @click="$modal.hide('modal-confirm-delete-piece')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 rounded">Annuler</button>
                    <button :disabled="loadingDelete" @click="deletePiece" class="px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded">
                        <spinner v-if="loadingDelete" :size="10" />
                        <template v-if="!loadingDelete">
                            <i class="fas fa-trash mr-1"></i> Confirmer la suppression
                        </template>
                    </button>
                </div>
            </div>
        </modal>

        <modal name="modal-display-file-errors-import" height="auto" width="80%" :scrollable="true">
            <div class="flex items-center justify-between px-4 py-4">
                <div class="text-2xl font-bold text-red-500 mt-4"><i class="fas fa-circle-exclamation mr-1"></i> {{ $tt('Votre fichier n\'est pas conforme') }}</div>
                <button @click="$modal.hide('modal-display-file-errors-import')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-4 pt-0 overflow-x-auto min-h-[500px]" v-if="errorImportFiles">
                <div class="bg-red-100 p-4 rounded mb-4">
                    <div>{{ $tt('Votre fichier n\'a pas été importé') }}</div>
                    <div>{{ $tt('Merci de corriger votre fichier et de le renvoyer') }}</div>
                </div>
                <div class="max-h-[70vh] overflow-y-auto">
                    <table class="w-full text-left">
                        <thead>
                            <tr>
                                <th class="px-4 py-2 border bg-slate-100 w-[200px]">{{ $tt('Ligne dans le fichier') }}</th>
                                <th class="px-4 py-2 border bg-slate-100">{{ $tt('Erreur détectée') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(errorImportFile, errorImportFileIndex) in errorImportFiles" :key="'error-table-line-' + errorImportFileIndex">
                                <td class="px-4 py-2 border">{{ $tt('Ligne') }} {{ errorImportFile.index + 1 }}</td>
                                <td class="px-4 py-2 border">{{ errorImportFile.message }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal>
        <modal @before-close="closeModalEdit" name="modal-view-piece" height="auto" width="80%" :scrollable="true">
            <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                <div class="text-xs font-light"><i class="fas fa-cog mr-1"></i> {{ selectedPiece && selectedPiece.id ? $tt('Configurer une pièce') : $tt('Ajouter une pièce') }}</div>
                <button @click="$modal.hide('modal-view-piece')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-4 overflow-x-auto min-h-[500px]" v-if="selectedPiece">
                <div class="grid gap-4 grid-cols-2">
                    <div>
                        <div :class="selectedPiece.id ? 'bg-slate-100 p-4 rounded border shadow-sm' : ''">
                            <h2 v-if="selectedPiece.id" class="text-sm border-b border-slate-600 font-bold pb-3 mb-3"><i class="fas fa-cog mr-1"></i> {{ $tt('Configuration de la pièce') }}</h2>
                            <div class="grid grid-cols-3 gap-2 mb-2">
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.ref" type="text" :label-inline="false" class="mb-1" :label="$tt('Référence')" :disabled="selectedPiece.id" id="inp-ref" />
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.designation" type="text" :label-inline="false" class="mb-1" :label="$tt('Désignation')" id="inp-designation" />
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.version" type="text" :label-inline="false" class="mb-1" :label="$tt('Version')" />
                            </div>
                            <div class="grid grid-cols-2 gap-2 mb-4">
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.id_fournisseur" type="vselect" :options="{values: fournisseurs, label: 'nom', key: 'id'}" :label-inline="false" class="mb-1" :label="$tt('Fournisseur')" id="inp-id_fournisseur" />
                                <input-rule v-model="selectedPiece.remise_fournisseur" type="number" :label-inline="false" class="mb-1" :label="$tt('Remise Fournisseur') + ' (%)'" @input="calcPrixHaNet(); editAddPiece()" />
                            </div>
                            <!-- <div class="mb-2">
                                <label class="font-bold text-xs mb-0.5">{{ $tt('Dernier achat') }}</label>
                                <div class="font-light text-xs">13/09/2026 - 15h57</div>
                            </div> -->
                            <div class="grid grid-cols-2 gap-2 mb-2 pt-4 border-t-2 border-slate-300">
                                <input-rule v-model="selectedPiece.prix_ha" type="price" :label-inline="false" class="mb-2" :label="$tt('Prix HA') + ' (HT)'" @input="calcPrixHaNet(); editAddPiece()" id="inp-prix_ha" />
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.prix_ha_net" :disabled="true" type="price" :label-inline="false" class="mb-2" :label="$tt('Prix HA Net') + ' (HT)'" />
                            </div>
                            <div class="grid grid-cols-2 gap-2 mb-4">
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.prix_base_ht" type="price" :label-inline="false" class="mb-2" :label="$tt('Prix vente Base') + ' (HT)'" id="inp-prix_base_ht" />
                                    <input-rule @input="editAddPiece()" v-model="selectedPiece.pv_force" type="price" :label-inline="false" class="mb-1" :label="$tt('Prix vente Forcé ') + ' (HT)'" />
                            </div>
                            <div class="grid grid-cols-2 gap-2 mt-4 pt-4 border-t-2 border-slate-300">
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.qtt" type="number" :label-inline="false" class="mb-1" :label="$tt('Qte stock')" />
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.qtt_min" type="number" :label-inline="false" class="mb-1" :label="$tt('Qte Minimum')" />
                                <input-rule v-if="selectedPiece.id" v-model="selectedPiece.qtt_reserve" type="text" :label-inline="false" class="mb-1" :label="$tt('Qte réservée')" :disabled="true" />
                                <input-rule v-if="selectedPiece.id" v-model="selectedPiece.qtt_commande" type="text" :label-inline="false" class="mb-1" :label="$tt('Qte en commande - stock')" :disabled="true" />
                            </div>
                            <div class="grid grid-cols-2 gap-2 mb-2 mt-4 pt-4 border-t-2 border-slate-300">
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.emplacement" type="text" :label-inline="false" class="mb-2" :label="$tt('Emplacement')" id="inp-emplacement" />
                                <input-rule @input="editAddPiece()" v-model="selectedPiece.occasion" type="pcheck" :label-inline="false" class="mb-1" :label="$tt('Occasion')" />
                            </div>
                            <div class="flex items-center justify-between gap-2 mt-4 pt-4 border-t-2 border-slate-300">
                                <button @click="$modal.show('modal-command-piece')" class="flex items-center gap-2 px-4 py-2 rounded text-white duration-200 text-xs font-semibold" :class="selectedPiece.qtt - selectedPiece.qtt_min < 0 ? 'bg-orange-600 hover:bg-orange-700' : 'bg-slate-600 hover:bg-slate-700'">
                                    <i class="fas fa-truck-fast mr-0.5"></i>
                                    <div class="text-left">
                                        <div>{{ $tt('Commander une pièce') }}</div>
                                        <div class="font-light -mt-0.5" v-if="selectedPiece.qtt - selectedPiece.qtt_min < 0">{{-(selectedPiece.qtt - selectedPiece.qtt_min)}} {{ $tt('pièces manquantes') }}</div>
                                    </div>
                                </button>
                                <button v-if="selectedPiece.new" @click="editAddPiece(true)" class="flex items-center gap-2 px-4 py-2 rounded text-white duration-200 text-xs font-semibold bg-green-600 hover:bg-green-700">
                                    <i class="fas fa-check mr-0.5"></i>
                                    <div class="text-left">
                                        <div>{{ $tt('Enregistrer la pièce') }}</div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!selectedPiece.new">
                        <div class="flex items-end text-sm mb-4">
                            <div class="border-b-2 cursor-pointer p-2 px-4 pl-0" :class="selectedMenuModal == 'similar' ? 'text-blue-500 border-blue-500 font-bold' : 'border-slate-200'" @click="selectedMenuModal = 'similar'">
                                <i class="fa-solid fa-arrows-left-right-to-line mr-1"></i> {{$tt('Pièces similaires')}}
                            </div>
                            <div class="border-b-2 cursor-pointer p-2 px-4" :class="selectedMenuModal == 'logs' ? 'text-blue-500 border-blue-500 font-bold' : 'border-slate-200'" @click="selectedMenuModal = 'logs'">
                                <i class="fa-solid fa-shuffle mr-1"></i> {{ $tt('Mouvement de stock') }}
                            </div>
                            <div class="grow border-b-2 border-slate-200"></div>
                        </div>
                        <div v-if="selectedMenuModal == 'similar' && selectedPiece.id">
                            <div class="mb-2">
                                <input-rule
                                id="inp-search-similar"
                                :label-inline="false"
                                placeholder="Ajouter une pièce"
                                type="api-select"
                                @input="(data) => {addSimilarPiece(data)}"
                                :options="{
                                    api: {action: 'reparateur.search-piece-ref', apiName: 'api2'},
                                    label: 'design_ref',
                                    label2: 'fournisseur_nom',
                                    init: false,
                                    min: 1,
                                    uppercase: true,
                                }"
                                />
                            </div>
                            <div>
                                <div v-if="selectedPiece.pieces_similaires" class="border relative overflow-x-auto">
                                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                                        <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" class="px-2 py-2 border-b">{{$tt('Référence')}}</th>
                                                <th scope="col" class="px-2 py-2 border-b">{{$tt('Désignation')}}</th>
                                                <th scope="col" class="px-2 py-2 border-b">{{$tt('Fournisseur')}}</th>
                                                <th scope="col" class="px-2 py-2 border-b">{{$tt('Actions')}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(piece, pieceIndex) in selectedPiece.pieces_similaires" :key="'piece-similaire-' + pieceIndex" class="hover:bg-slate-100 duration-200">
                                                <td class="px-2 py-1 text-xs border-b border-r">{{ piece.ref }}</td>
                                                <td class="px-2 py-1 text-xs border-b border-r">{{ piece.design || '-' }}</td>
                                                <td class="px-2 py-1 text-xs border-b border-r">{{ piece.fournisseur.nom || '-' }}</td>
                                                <td class="px-2 py-1 text-xs border-b border-r">
                                                    <button @click="removeSimilarPiece(piece)" class="bg-red-400 hover:bg-red-500 px-2 py-1 rounded text-xs text-white duration-200" style="font-size: 10px;"><i class="fas fa-trash mr-0.5"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedMenuModal == 'logs' && selectedPiece.id">
                            <div v-if="!selectedPiece.logs || selectedPiece.logs.length <= 0" class="bg-slate-100 p-2 rounded mt-2" style="font-size: 10px">
                                {{ $tt('Aucun') }}
                            </div>
                            <div class="overflow-y-auto max-h-[400px]">
                                <table v-if="selectedPiece.logs && selectedPiece.logs.length > 0" class="w-full text-sm text-left rtl:text-right text-gray-500 border mt-2">
                                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" class="px-2 py-1 border-b border-r">{{$tt('Date')}}</th>
                                            <th scope="col" class="px-2 py-1 border-b border-r">{{$tt('Mouvement')}}</th>
                                            <th scope="col" class="px-2 py-1 border-b border-r">{{$tt('Dossier')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(log, logIndex) in selectedPiece.logs" :key="'log-' + logIndex" class="hover:bg-slate-100 duration-200">
                                            <td class="px-2 py-1 text-xs border-b border-r">{{ log.date_log | momentDateTime }}</td>
                                            <td class="px-2 py-1 text-xs border-b border-r">{{ log.action }}</td>
                                            <td class="px-2 py-1 text-xs border-b border-r">{{ log.dossier.ref_dossier }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal>

        <modal name="modal-view-import-file" height="auto" width="95%" :scrollable="true">
            <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                <div class="text-xs font-light"><i class="fas fa-cog mr-1"></i> {{ $tt('Consulter le fichier') }}</div>
                <button @click="$modal.hide('modal-view-import-file')">
                    <i class="fas fa-times text-red-500 text-xl"></i>
                </button>
            </div>
            <div class="p-2">
                <div class="relative overflow-x-auto mt-4 border rounded max-h-[500px]">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                        <thead>
                            <tr class="text-xs bg-slate-100 font-bold">
                                <th class="px-1">{{ $tt('Référence') }}</th>
                                <th class="px-1">{{ $tt('Désignation') }}</th>
                                <th class="px-1">{{ $tt('Version') }}</th>
                                <th class="px-1">{{ $tt('Emplacement') }}</th>
                                <th class="px-1">{{ $tt('Quantité') }}</th>
                                <th class="px-1">{{ $tt('Date création') }}</th>
                                <th class="px-1">{{ $tt('Nom') }}</th>
                                <th class="px-1">{{ $tt('Prix Net') }}</th>
                                <th class="px-1">{{ $tt('PV Forcé') }}</th>
                                <th class="px-1">{{ $tt('PV Base') }}</th>
                                <th class="px-1">{{ $tt('Date dernier conso') }}</th>
                                <th class="px-1">{{ $tt('ID Fourn. Districloud') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(line, lineIndex) in fileLines" :key="'line-' + lineIndex" class="border-b hover:bg-slate-100 duration-200">
                                <td class="px-1">{{ line[0] }}</td>
                                <td class="px-1">{{ line[1] }}</td>
                                <td class="px-1">{{ line[2] }}</td>
                                <td class="px-1">{{ line[3] }}</td>
                                <td class="px-1">{{ line[4] }}</td>
                                <td class="px-1">{{ line[6] }}</td>
                                <td class="px-1">{{ line[7] }}</td>
                                <td class="px-1">{{ line[8] }}</td>
                                <td class="px-1">{{ line[9] }}</td>
                                <td class="px-1">{{ line[10] }}</td>
                                <td class="px-1">{{ line[11] }}</td>
                                <td class="px-1">{{ line[12] }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal>

        <modal name="modal-qtt-reservee-details" height="auto" :scrollable="true">
            <div>
                <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                    <div class="text-xs font-light">{{ $tt('Quantité reservée') }}</div>
                    <button @click="$modal.hide('modal-qtt-reservee-details')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="p-2">
                    <table class="border w-full text-left">
                        <thead class="font-bold bg-slate-100 text-sm">
                            <tr>
                                <th class="border px-4 py-2">{{ $tt('Dossier') }}</th>
                                <th class="border px-4 py-2">{{ $tt('Quantité') }}</th>
                            </tr>
                        </thead>
                        <tbody class="font-light text-xs">
                            <tr v-for="(qttReserveeDetail, qttReserveeDetailIndex) in qttReserveeDetails" :key="'reserve-detail-' + qttReserveeDetailIndex">
                                <th class="border px-4 py-2 font-light">{{qttReserveeDetail.dossier.ref_dossier}}</th>
                                <th class="border px-4 py-2 font-light">{{qttReserveeDetail.qty}}</th>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </modal>

        <modal name="modal-command-piece" height="auto" width="95%" :scrollable="true">
            <div>
                <div class="flex items-center justify-between bg-blue-100 px-4 py-1">
                    <div class="text-xs font-light">{{ $tt('Commande de pièce') }}</div>
                    <button @click="$modal.hide('modal-command-piece')">
                        <i class="fas fa-times text-red-500 text-xl"></i>
                    </button>
                </div>
                <div class="p-2" v-if="selectedPiece">
                    <stock-piece
                    @added="addedCommand"
                    class="bg-white h-screen"
                    :ref-default="selectedPiece.ref"
                    :fournisseur-default="{
                        id: this.selectedPiece.id_fournisseur,
                        nom: this.fournisseurs.find((item) => item.id == this.selectedPiece.id_fournisseur)?.nom,
                        prix_moyen: this.selectedPiece.prix_ha
                    }"
                    :qtt-commande-default="selectedPiece.qtt - selectedPiece.qtt_min > 0 ? 0 : -(selectedPiece.qtt - selectedPiece.qtt_min)"
                    :from-stock="true"
                    :stock-id="selectedPiece.id"
                    />
                </div>
            </div>
        </modal>


        <app-template>
            <div class="bg-white p-4 rounded border shadow-sm mb-16">
                <div class="flex items-center justify-between gap-2 border-b mb-3 pb-3">
                    <h2 class="text-xl font-bold"><i class="fas fa-boxes-stacked mr-1"></i> {{ $tt('Gestion du stock') }}</h2>
                </div>
                <div class="border-b mb-3 pb-3 flex items-center gap-2">
                    <button @click="openNewPiece()" class="px-4 py-2 rounded bg-blue-600 hover:bg-blue-700 text-white duration-200 text-xs font-semibold"><i class="fas fa-plus mr-1"></i> {{ $tt('Ajouter une pièce') }}</button>
                    <button v-if="sta && sta.id_fournisseur_sta != 0" @click="displayUploadFileForm = !displayUploadFileForm" class="px-4 py-2 rounded bg-slate-200 hover:bg-slate-300 duration-200 text-xs font-semibold">
                        <i class="fas fa-upload mr-1"></i>
                        <span v-if="!displayUploadFileForm">{{ $tt('Importer un fichier') }}</span>
                        <span v-if="displayUploadFileForm">{{ $tt('Annuler') }}</span>
                    </button>
                    <button :disabled="loadingExport" @click="exportStock" class="px-4 py-2 rounded bg-slate-200 hover:bg-slate-300 duration-200 text-xs font-semibold">
                        <spinner v-if="loadingExport" :size="8" />
                        <template v-if="!loadingExport">
                            <i class="fas fa-file-arrow-down mr-1"></i>
                            <span>{{ $tt('Exporter le stock') }}</span>
                        </template>
                    </button>
                </div>
                <div v-if="loadingGetPiece" class="mt-4 p-2 px-4 flex items-center gap-4 bg-slate-100 rounded">
                    <spinner :size="10" />
                    <div class="font-light text-sm">{{$tt('Chargement des données')}}</div>
                </div>

                <div v-if="sta">
                    <!-- Station is fournisseur -->
                    <div v-if="sta.id_fournisseur_sta != 0">
                        <div v-if="displayUploadFileForm || (!loadingGetPiece && nbPieces === 0 && fileLines.length <= 0)" class="overflow-x-auto" :class="displayUploadFileForm ? 'bg-slate-200 p-4 rounded' : 'p-4'">
                            <div class="text-2xl mb-3 font-bold" v-if="displayUploadFileForm">Importer un fichier</div>
                            <div class="p-4 rounded grid grid-cols-5 items-center" :class="displayUploadFileForm ? 'bg-white text-orange-700' : 'bg-orange-100 text-orange-800'">
                                <div class="col-span-3">
                                    <div class="font-bold text-sm"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Télécharger le fichier de référence') }}</div>
                                    <p class="text-xs mt-1">Merci d'importer un fichier CSV dans le même format que le fichier de référence</p>
                                </div>
                                <div class="col-span-2 text-right">
                                    <a href="https://disticloudfiles.net/upload/Exemple-Import-Stock-Districloud.csv" target="_blank" class="px-4 py-2 bg-orange-800 hover:bg-orange-900 duration-200 rounded text-white mt-2 text-xs">{{ $tt('Télécharger') }} <i class="fas fa-download ml-1"></i></a>
                                </div>
                            </div>
                            <div class="mt-4 rounded p-4 border" :class="displayUploadFileForm ? 'bg-white' : 'bg-slate-100'">
                                <input ref="fileuploadcsv" class="border text-xs p-2 bg-white w-full mt-2" type="file" @change="loadFileDefaultFour" accept=".csv">
                                <button @click="sendFileDefaultFour" :disabled="loadingImportFileDefaultFour" class="px-4 py-2 bg-green-500 hover:bg-green-600 duration-200 text-white rounded mt-4 text-sm">
                                    <spinner v-if="loadingImportFileDefaultFour" :size="10" />
                                    <template v-if="!loadingImportFileDefaultFour">
                                        <i class="fas fa-check mr-1"></i> Importer
                                    </template>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- Station is not fournisseur -->
                    <div v-if="sta.id_fournisseur_sta == 0">
                        <!-- Import file -->
                        <div v-if="!loadingGetPiece && nbPieces === 0 && fileLines.length <= 0" class="p-4 overflow-x-auto">
                            <div class="bg-orange-100 p-4 rounded text-orange-800 grid grid-cols-5 items-center">
                                <div class="col-span-3">
                                    <div class="font-bold text-sm"><i class="fas fa-exclamation-triangle mr-1"></i> {{ $tt('Télécharger le fichier de référence') }}</div>
                                    <p class="text-xs mt-1">Merci d'importer un fichier CSV dans le même format que le fichier de référence</p>
                                </div>
                                <div class="col-span-2 text-right">
                                    <a href="https://disticloudfiles.net/upload/Exemple-Import-Stock-Districloud.csv" target="_blank" class="px-4 py-2 bg-orange-800 hover:bg-orange-900 duration-200 rounded text-white mt-2 text-xs">{{ $tt('Télécharger') }} <i class="fas fa-download ml-1"></i></a>
                                </div>
                            </div>
                            <div class="mt-4 bg-slate-100 rounded p-4 border">
                                <input ref="fileuploadcsv" class="border text-xs p-2 bg-white w-full mt-2" type="file" @change="loadFile" accept=".csv">
                            </div>
                        </div>
    
                        <!-- Edit import file -->
                        <div class="mt-4" v-if="fileLines.length > 0">
                            <div class="bg-orange-100 p-4 rounded mb-2" v-if="fileFournisseurs && fileFournisseurs.length > 0">
                                <div class="font-bold"><i class="fas fa-exclamation-triangle mr-1"></i> Certains fournisseurs n'ont pas pu être trouvés automatiquement</div>
                                Merci de faire correspondre les fournisseurs entre votre fichier et notre donnée
                            </div>
                            <div class="max-h-[400px]">
                                <table class="w-full text-left">
                                    <thead>
                                        <tr>
                                            <th class="px-2 py-1 border bg-slate-100">Fournisseur du fichier</th>
                                            <th class="px-2 py-1 border bg-slate-100">Fournisseur Districloud</th>
                                        </tr>
                                    </thead>
                                    <tr v-for="(four, fourIndex) in fileFournisseurs" :key="'four-file-' + fourIndex">
                                        <td class="px-2 py-1 border">{{ four.nom }}</td>
                                        <td class="px-2 py-1 border">
                                            <div v-if="!four.id">
                                                <input-rule
                                                id="select-enseigne"
                                                :label-inline="false"
                                                placeholder="Fournisseur"
                                                @input="(data) => {four.id = data.id; updateColFourFile()}"
                                                type="api-select"
                                                :options="{
                                                    api: {action: 'inscription.search-grossiste', apiName: 'api2'},
                                                    label: 'nom',
                                                    init: false,
                                                    min: 1,
                                                    uppercase: true
                                                }"
                                                />
                                            </div>
                                            <div class="text-green-600 font-bold text-xs py-2" v-else>
                                                <i class="fas fa-circle-check mr-1"></i>{{ $tt('Enregistré') }}
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
    
                            <!-- Button import file -->
                            <div class="mt-4 flex items-center gap-2">
                                <template v-if="!loadingImportFile">
                                    <button @click="$modal.show('modal-view-import-file')" class="px-4 py-2 bg-slate-200 hover:bg-slate-300 duration-200 rounded text-sm">
                                        <i class="fas fa-eye mr-1"></i> {{ $tt('Consulter le fichier') }}
                                    </button>
                                    <button :disabled="loadingImportFile" @click="sendImportFile" class="px-4 py-2 bg-green-600 hover:bg-green-700 duration-200 rounded text-white text-sm">
                                        <template v-if="!loadingImportFile">
                                            <i class="fas fa-check mr-1"></i> {{ $tt('Importer le fichier') }}
                                        </template>
                                    </button>
                                </template>
                                <div v-if="loadingImportFile" class="flex gap-4 items-center">
                                    <spinner :size="10" />
                                    <div class="text-xs">{{$tt('Importation des données')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!loadingGetPiece && nbPieces > 0" class="mt-4">
                    <data-table
                    id="table-pieces"
                    :server="true"
                    :filters-inline="false"
                    :action-at-start="true"
                    :filters="[
                        {label: 'Référence', key: 'ref', type: 'text'},
                        {label: 'Désignation', key: 'designation', type: 'text'},
                        {label: 'Emplacement', key: 'emplacement', type: 'text'},
                    ]"
                    :columns="[
                        {label: 'Référence', key: 'ref', type: 'text'},
                        {label: 'Désign', key: 'designation', type: 'text'},
                        {label: 'Version', key: 'version', type: 'text'},
                        {label: 'Fournisseur', key: 'fournisseur_name', type: 'text'},
                        {label: 'Emplacement', key: 'emplacement', type: 'text'},
                        {label: 'Prix HA', key: 'prix_ha', type: 'euro'},
                        {label: 'Qte Disponible', key: 'qtt', type: 'text'},
                        {label: 'Qte Réservée', slot: 'qtt_reserve', type: 'text'},
                        {label: 'Qte Min', key: 'qtt_min', type: 'text'},
                        {label: 'Qte totale', key: 'qtt_totale', type: 'text'},
                        // {label: 'Etiquette', slot: 'etiquette', type: 'text'},
                    ]"
                    :request="{
                        name: 'reparateur.get-stock-pieces',
                        params: {
                            page: 0,
                            per_page: 50,
                        },
                        apiName: 'api2'
                    }"
                    >
                        <template v-slot:actions='{line}'>
                            <div class="flex items-center gap-1">
                                <button class="bg-blue-400 hover:bg-blue-500 text-white px-2 py-1 rounded text-xs duration-200" @click="selectedPiece = line; $modal.show('modal-view-piece')"><i class="fas fa-cog"></i></button>
                                <button class="text-red-400 hover:text-white hover:bg-red-600 text-white px-2 py-1 rounded text-xs duration-200" @click="selectedPiece = line; $modal.show('modal-confirm-delete-piece')"><i class="fas fa-trash"></i></button>
                            </div>
                        </template>
                        <template v-slot:qtt_reserve='{line}'>
                            {{ line.qtt_reserve }}
                            <button @click="displayQttReserveDetails(line)" class="underline ml-1 text-slate-600 hover:text-slate-700 duration-200 text-sm"><i class="fas fa-circle-info"></i></button>
                        </template>
                        <!-- <template v-slot:etiquette>
                            <button @click="$toast.warning($tt('Aucune imprimante configurée'))" class="font-bold bg-orange-600 hover:bg-orange-700 px-2 py-1 rounded text-xs text-white duration-200"><i class="fas fa-print"></i></button>
                        </template> -->
                    </data-table>
                </div>
            </div>
        </app-template>
    </div>
</template>

<script>
import apiUploadService from '@/services/apiUpload.service'
import moment from 'moment'
import StockPiece from './Components/StockPiece.vue';

export default {
    components: {
        StockPiece,
    },
    data() {
        return {
            nbPieces: null,
            pieces: [],

            formNewPiece: {},
            
            selectedPiece: null,
            
            fournisseurs: [],

            qttReserveeDetails: [],

            selectedMenuModal: 'similar',

            fileImport: null,
            fileLines: [],
            fileFournisseurs: [],
            importFileId: null,
            loadingImportFile: false,

            sta: null,

            displayUploadFileForm: false,
            uploadFileDefaultFourUrl: null,
            errorImportFiles: null,
            
            loadingGetPiece: false,
            loadingAddPiece: false,
            timerEditAdd: false,
            loadingImportFileDefaultFour: false,
            loadingDelete: false,

            loadingExport: false,
        }
    },
    filters: {
        momentDate(date) {
            if (date) {
                return moment(date).format('DD/MM/YYYY')
            }
            return '-'
        },
        momentDateTime(date) {
            if (date) {
                return moment(date).lang('fr').format('DD/MM/YYYY - HH:mm:ss')
            }
            return '-'
        },
    },
    methods: {
        async getFournisseurs() {
            let response = await this.$request.post('reparateur.get-fournisseurs')
            this.fournisseurs = response.data.posts.post
        },

        async getSta() {
            this.sta = await this.$request.send('reparateur.get-sta', {
                with_four: 1
            })
        },

        async getNbPieces() {
            let response = await this.$request.post('reparateur.nb-stock-pieces')
            this.nbPieces = response.data.posts.post
        },

        async getPieces(line_id = null) {
            let endData = {}
            if (line_id) {
                endData.line_id = line_id
            }
            this.loadingGetPiece = true
            let response = await this.$request.post('reparateur.get-stock-pieces', endData)
            this.loadingGetPiece = false

            if (!line_id) {
                this.pieces = response.data.posts.post
            } else {
                return response.data.posts.post.data[0]
            }
        },
        
        async editAddPiece(sendManually = false) {
            // Not save automatically on new piece
            if (this.selectedPiece.new && !sendManually) {
                return
            }
            if (sendManually) {
                if (!this.$validation.check([
                    {value: this.selectedPiece.ref, rules: 'required', id: 'inp-ref'},
                    {value: this.selectedPiece.designation, rules: 'required', id: 'inp-designation'},
                    {value: this.selectedPiece.id_fournisseur, rules: 'required', id: 'inp-id_fournisseur'},
                    {value: this.selectedPiece.prix_ha, rules: 'required', id: 'inp-prix_ha'},
                    {value: this.selectedPiece.prix_base_ht, rules: 'required', id: 'inp-prix_base_ht'},
                    {value: this.selectedPiece.emplacement, rules: 'required', id: 'inp-emplacement'},
                ])) {return}
            }

            clearTimeout(this.timerEditAdd)
            this.timerEditAdd = setTimeout(async () => {
                let endData = JSON.parse(JSON.stringify(this.selectedPiece))
                if (endData.id) {
                    endData.line_id = this.selectedPiece.id
                    delete endData.id
                }
                if (!endData.version) {
                    delete endData.version
                }
                if (!endData.remise_fournisseur) {
                    endData.remise_fournisseur = 0
                }
                this.loadingAddPiece = true
                await this.$request.post('reparateur.edit-add-piece-stock', endData)
                this.loadingAddPiece = false

                if (sendManually) {
                    this.$modal.hide('modal-view-piece')
                }
            }, 400)
        },

        async closeModalEdit() {
            this.$eventHub.$emit('clear-inp-search-similar')
            await this.getNbPieces()
            this.$eventHub.$emit('refresh-lines', 'table-pieces')
        },

        async addSimilarPiece(piece) {
            if (piece) {
                this.$eventHub.$emit('clear-inp-search-similar')
                let response = await this.$request.post('reparateur.add-similar-piece', {
                    id_current: this.selectedPiece.catalogue.id,
                    id_similar: piece.id
                })
                if (response.data.posts.post == 1) {
                    let newPiece = await this.getPieces(this.selectedPiece.id)
                    this.selectedPiece = newPiece
                }
            }
        },

        async removeSimilarPiece(piece) {
            await this.$request.post('reparateur.remove-similar-piece', {
                id_piece_ref: piece.pivot.id_piece_ref,
                id_piece_similaire: piece.pivot.id_piece_similaire,
            })
            let newPiece = await this.getPieces(this.selectedPiece.id)
            this.selectedPiece = newPiece
        },

        async loadFile(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            let response = await apiUploadService.post({
                files: files[0]
            })
            this.importFileId = response.data.files[0].id
            this.readFile(files[0]);
        },

        async loadFileDefaultFour(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) return;
            let response = await apiUploadService.post({
                files: files[0]
            })
            this.uploadFileDefaultFourUrl = response.data.files[0].url
        },

        async sendFileDefaultFour() {
            if (!this.uploadFileDefaultFourUrl) {
                this.$toast.error('Vous devez ajouter un fichier pour continuer')
                return
            }
            this.loadingImportFileDefaultFour = true
            try {
                let response = await this.$request.post('reparateur.stock-import-lines-default-four', {
                    file_url: this.uploadFileDefaultFourUrl
                })
                if (response.data.posts.post == 1) {
                    await this.getNbPieces()
                    this.$eventHub.$emit('refresh-lines', 'table-pieces')
                    this.displayUploadFileForm = false
                } else {
                    this.errorImportFiles = JSON.parse(response.data.posts.error)
                    this.$modal.show('modal-display-file-errors-import')
                }
            } catch (error) {
                this.$toast.error(this.$tt('Une erreur est survenue'))
            }
            this.loadingImportFileDefaultFour = false
        },
        async readFile(file) {
            let promise = new Promise((resolve) => {
                var reader = new FileReader();
                var vm = this;
                reader.onload = () => {
                    resolve((vm.fileinput = reader.result));
                };
                reader.readAsText(file);
            });

            promise.then(
                async () => {
                    var onlyUnique = (value, index, array) => {
                        return array.indexOf(value) === index;
                    }
                    var pluck = (array, key) => {
                        return array.map(o => o[key]);
                    }
                    /* handle a successful result */
                    this.fileinput = this.fileinput.replaceAll("\r\n", "\n")
                    this.fileinput.split("\n").forEach((line) => {
                        let splitted = line.split(';')
                        if (splitted[7]) {
                            splitted.push('0')
                            this.fileLines.push(splitted)
                        }
                    })

                    if (this.fileLines[0][0] == 'Reference') {
                        this.fileLines.shift()
                    }

                    // Search fournisseur name
                    let fournisseurs = pluck(this.fileLines, 7).filter(onlyUnique);                    
                    let response = await this.$request.post('reparateur.stock-import-check-four', {
                        fournisseurs: JSON.stringify(fournisseurs)
                    })
                    let fournisseurSearch = response.data.posts.post

                    // Default fournisseur
                    let idDefaultFournisseur = 0
                    if (this.sta.id_fournisseur_sta != 0) {
                        idDefaultFournisseur = this.sta.id_fournisseur_sta
                    }                    

                    fournisseurs.forEach((four) => {
                        // Manage default fournisseur
                        if (idDefaultFournisseur != 0) {
                            let fourFound = fournisseurSearch.find((item) => item.id == idDefaultFournisseur)
                            console.log(fourFound);
                            
                            this.fileFournisseurs.push({id: idDefaultFournisseur, nom: this.sta.default_fournisseur.nom})
                        } else {
                            let fourFound = fournisseurSearch.find((item) => item.nom == four)
                            if (fourFound) {
                                this.fileFournisseurs.push(fourFound)
                            } else {
                                this.fileFournisseurs.push({id: null, nom: four})
                            }
                        }
                    })

                    this.updateColFourFile()

                },
                error => {
                    /* handle an error */ 
                    console.log(error);
                }
            );
        },

        updateColFourFile() {
            let fourConf = this.fileFournisseurs.filter((item) => item.id)
            let fourDistriIndex = this.fileLines[0].length - 1
            this.fileLines.forEach((line, lineIndex) => {
                let findFour = fourConf.find((four) => four.nom == line[7])
                console.log(findFour);
                
                
                if (findFour) {
                    this.fileLines[lineIndex][fourDistriIndex] = findFour.id
                }
            })
        },

        async sendImportFile() {
            let lines = this.fileLines.filter((item) => item[5] != 0)

            this.loadingImportFile = true
            const chunkSize = 500;
            for (let i = 0; i < lines.length; i += chunkSize) {
                const chunk = lines.slice(i, i + chunkSize);
                try {
                    await this.$request.post('reparateur.stock-import-lines', {
                        lines: JSON.stringify(chunk),
                        import_file_id: this.importFileId
                    })
                    this.$toast.success(this.$tt('Fichier importé'))
                } catch (error) {
                    console.log(error);
                    this.$toast.error(this.$tt('Une erreur est survenue'))
                }
            }
            this.loadingImportFile = false
            this.fileLines = []
            await this.getNbPieces()
            this.$eventHub.$emit('refresh-lines', 'table-pieces')
            
        },

        async displayQttReserveDetails(line) {
            this.$modal.show('modal-qtt-reservee-details')

            this.qttReserveeDetails = []
            let response = await this.$request.post('reparateur.get-qtt-reservee-details', {
                id_piece: line.id
            })
            this.qttReserveeDetails = response.data.posts.post
        },

        calcPrixHaNet() {
            if (this.selectedPiece) {
                this.selectedPiece.prix_ha_net = this.selectedPiece.prix_ha - (this.selectedPiece.prix_ha * this.selectedPiece.remise_fournisseur / 100)
            }
        },

        openNewPiece() {
            this.selectedPiece = {
                ref: null,
                designation: null,
                version: null,
                id_fournisseur: null,
                remise_fournisseur: null,
                prix_ha: null,
                prix_ha_net: null,
                pv_force: null,
                qtt: 0,
                qtt_min: 0,
                qtt_reserve: null,
                emplacement: null,
                occasion: 0,
                new: 1,
            }
            this.$modal.show('modal-view-piece')
        },

        async addedCommand() {
            this.$modal.hide('modal-command-piece')
            let response = await this.$request.post('reparateur.get-stock-pieces', {
                line_id: this.selectedPiece.id
            })
            this.selectedPiece = response.data.posts.post.data[0]
            this.$toast.success(this.$tt('Pièce commandée'))
        },

        async deletePiece() {
            this.loadingDelete = true
            let response = await this.$request.send('reparateur.stock-delete-piece', {
                line_id: this.selectedPiece.id
            })
            this.loadingDelete = false
            if (response == 1) {
                this.$eventHub.$emit('refresh-lines', 'table-pieces')
                this.$toast.success(this.$tt('Pièce supprimée'))
                this.$modal.hide('modal-confirm-delete-piece')
            }
        },

        async exportStock() {
            this.loadingExport = true

            let url = await this.$request.send('reparateur.export-stock')

            const link = document.createElement("a");
            link.href = url;
            link.download = "export_stock_districloud.csv";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            this.loadingExport = false
        }
    },
    async mounted () {
        let permissions = await this.$request.send('technicien.get-permissions')
        if (permissions && permissions.stock == 0) {
            this.$router.push({name: 'bad-permissions'})
            return
        }
        
        this.getNbPieces()
        this.getFournisseurs()
        this.getSta()

        // Tracker
        if (location.hostname != "localhost" && location.hostname != "127.0.0.1") {
            window.LogRocket && window.LogRocket.init('omg4ub/districloud-1qc5x');
        }
    },
}
</script>

<style lang="scss" scoped>

</style>